<template>
  <div class="expert-page">

    <div class="container-md py-3 text-left">
        <div class="row px-2">
          <div class="col-3">
            <img :src="detail.avatar_url" class="rounded img-fluid">
          </div>
          <div class="col-9">
            <div class="name fs-4">
              {{ i18n(detail, 'name') }}
            </div>
            <div class="detail p-2">
              <pre style="white-space: pre-wrap;line-height: 1.5;">{{ i18n(detail, 'description') }}</pre>
              <div class="mt-4">
                <!-- <img v-if="detail.work_photo" :src="detail.work_photo" class="rounded img-fluid"> -->
                <img :src="'https://qun.qq.com/qrcode/index?data='+ url" width='130' height='130'/>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'expert-detail',
  components: {
  },
  data() {
    return {
      doctor_id: this.$route.query.id,
      detail: {},
      url: document.URL,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api.doctor.getDoctorInfo({ id: this.doctor_id }).then((res) => {
        if (res.data && res.status == 200) {
          this.detail = res.data.data.info;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .expert-page{

}
</style>
