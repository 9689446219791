import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue';
// import { createRouter, createWebHistory } from 'vue-router';
import Router from '@/router';
import App from './App.vue';
import VueI18n from './language';
import Store from './store';
import Api from './api';
import Request from '@/utils/request';
import Utils from '@/utils/utils';
import Loading from '@/components/loading';
import Toast from '@/components/toast';

// import 'bootstrap/dist/js/bootstrap.js';
function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

let clientid = getQueryVariable('clientid');
if (clientid) {
  localStorage.setItem('client-getui-id', clientid);
}
let locale = getQueryVariable('lang');
if (locale && (locale == 'en' || locale == 'zh_TW')) {
  VueI18n.global.locale = locale;
  localStorage.setItem('client-language', locale);
}

function rnd(min, max) {
  return min + ((((((new Date()).getTime()) * 9301 + 49297) % 233280) / (233280.0)) * (max - min));
}
let identity = localStorage.getItem('client-identity') || '';
let m = getQueryVariable('m') || '';
let mark = 'N00';
if (locale && !m) {
  mark = 'N01';
} else if (locale && m) {
  mark = `N${m}`;
}
if (!identity) {
  // eslint-disable-next-line
  identity = mark + (new Date().getTime()) + parseInt(rnd(100000, 999999));
  localStorage.setItem('client-identity', identity);
}

const app = createApp(App);

app.use(Router);
app.use(VueI18n);
app.use(Api);
app.use(Store);
app.use(Request);
app.use(Utils);
app.mount('#app');

app.use(Loading);
app.use(Toast);
