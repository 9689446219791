<template>
  <div class="success">
    <div class="container-md text-center">
        <div class="mt-5">
            <img src="@/assets/pages/success-2.png" alt="" width="120">
        </div>
        <div class="mb-2 user-select-none">
            <button
                type="button"
                class="btn btn-success mt-5"
                style='width: 200px'
                @click="success"
            >
                {{ $t('payment.106') }}
            </button>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'pay-success',
  components: {

  },
  computed: {

  },
  data() {
    return {
      booking_record_id: this.$route.query.booking_record_id || '',
      from: this.$route.query.from || '',
    };
  },
  mounted() {
    setTimeout(() => {
      this.success();
    }, 1000 * 10);
  },
  methods: {
    success() {
      if (this.booking_record_id) {
        if (typeof plus == 'object' && this.from == 'paypal') {
          // eslint-disable-next-line
          uni.webView.navigateBack();
        } else {
          this.$router.push(`/member/reserve/detail?booking_record_id=${this.booking_record_id}`);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.success{

}
</style>
