<template>
  <div
    class="mt-3 text-end user-select-none"
    v-if="pages > 1"
  >
    <nav
      aria-label="Page navigation example"
      class="d-inline-block"
    >
      <ul class="pagination">
        <li class="page-item" v-if="total/pagesize > 5">
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Previous"
            @click="changePage(1)"
          >
            <span aria-hidden="true">&lsaquo;</span>
          </a>
        </li>
        <li class="page-item"  v-if="total/pagesize > 5">
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Previous"
            @click="changePage(false)"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          v-for="(item,index) in list"
          :key="index"
          class="page-item"
        >
          <a
            :class="['page-link', item.active && 'active' ]"
            href="javascript:void(0)"
            @click="changePage(item.value)"
          >{{ item.value }}</a>
        </li>
        <li class="page-item"  v-if="total/pagesize > 5">
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Next"
            @click="changePage(true)"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
        <li class="page-item"  v-if="total/pagesize > 5">
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Next"
            @click="changePage(pages)"
          >
            <span aria-hidden="true">&rsaquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
// 页脚
export default {
  name: 'PageTpl',
  props: {
    // 数据总条数
    total: {
      type: Number,
      default: 0,
    },
    // 每页数据条数
    pagesize: {
      type: Number,
      default: 20,
    },
    // 默认初始页码
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: this.page || 1,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.total / this.pagesize);
    },
    list() {
      // 当父组件传递total的值发生变化时，计算属性会重新计算
      // pages = Math.ceil(props.total / props.pagesize)
      const result = [];
      // 总页码小于等于5；大于5
      if (this.pages <= 5) {
        // 总页码小于等于5的情况
        for (let i = 1; i <= this.pages; i++) {
          result.push({ value: i, active: this.currentPage == i });
        }
      } else if (this.currentPage <= 2) { // 总页码大于5
        // 左侧临界值
        for (let i = 1; i <= 5; i++) {
          result.push({ value: i, active: this.currentPage == i });
        }
      } else if (this.currentPage >= this.pages - 1) {
        // 右侧临界值
        for (let i = this.pages - 4; i <= this.pages; i++) {
          result.push({ value: i, active: this.currentPage == i });
        }
      } else {
        // 中间的状态
        for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
          result.push({ value: i, active: this.currentPage == i });
        }
      }

      return result;
    },
  },
  methods: {
    changePage(type) {
      if (type === false) {
        // 上一页
        // 页面是第一页时，禁止点击操作
        if (this.currentPage === 1) return;
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      } else if (type === true) {
        // 下一页
        // 页面是最后页时，禁止点击操作
        if (this.currentPage === this.pages) return;
        if (this.currentPage < this.pages) {
          this.currentPage += 1;
        }
      } else {
        // 点击页码
        this.currentPage = type;
      }
      this.$emit('changePage', this.currentPage);
    },

  },

};
</script>
<style lang="scss" scoped>
  .page-link{
    color: var( --bs-gray-600 );
  }
  .active{
    background-color: #798D62;
    color: #ffffff;
  }
  .page-link:focus {
    box-shadow: none;
  }
</style>
