<template>
  <div class="doctor-select">
    <div class="row g-2 text-start text-md-end">
      <label class="col-form-label col-md-2">
        {{ $t('booking.longtime') }}：
      </label>
      <div class="col-md-10 text-start">
        <div class="d-inline-block me-1 mt-1">
          <input id="recheck-1" v-model="recheck" type="radio" class="btn-check" name="recheck" :value="0"
            checked>
          <label class="btn btn-outline-secondary btn-sm" for="recheck-1">{{ $t('booking.30') }}</label>
        </div>
        <div class="d-inline-block me-1 mt-1">
          <input id="recheck-2" v-model="recheck" type="radio" class="btn-check" name="recheck" :value="1">
          <label class="btn btn-outline-secondary btn-sm" for="recheck-2">{{ $t('booking.15') }}</label>
        </div>
        <div class="d-inline-block me-1 mt-1 float-end">
          <select class="form-select form-select-sm" v-model="sort">
            <option value="0" selected>{{ $t('booking.sort.time') }}</option>
            <option value="1">{{ $t('booking.sort.seniority') }}</option>
            <option value="4">{{ $t('booking.sort.seniority2') }}</option>
            <option value="2">{{ $t('booking.sort.price') }}</option>
            <!-- <option value="3" selected>{{ $t('booking.sort.time2') }}</option> -->
            <option value="5">{{ $t('booking.sort.price2') }}</option>
          </select>
        </div>

      </div>
    </div>

    <hr>

    <!-- <div class="title flex-grow-1 fs-5">
      {{ $t('booking.title3') }}
    </div> -->

     <div class="row g-2 text-start text-md-end">
      <label
        class="col-form-label col-md-2">
        {{ $t('booking.table.support_language') }}：
      </label>
      <div class="col-md-4 sm-line-height text-start">
        <div class="form-check form-check-inline">
          <input id="lang-2" v-model="screen.support_language_id" type="checkbox" @change="getDoctorList" class="form-check-input" name="language" value="8bfd003df06f6c6cd28153e00edfc93a">
          <label class="form-check-label" for="lang-2">{{ $t('booking.lang2') }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="lang-3" v-model="screen.support_language_id" type="checkbox" @change="getDoctorList" class="form-check-input" name="language" value="l037kaimdu394aereoyt075jsee56e">
          <label class="form-check-label" for="lang-3">{{ $t('booking.lang3') }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="lang-1" v-model="screen.support_language_id" type="checkbox" @change="getDoctorList" class="form-check-input" name="language" value="l039ldkiemg93m39gma9dimeod95">
          <label class="form-check-label" for="lang-1">{{ $t('booking.lang1') }}</label>
        </div>
      </div>

      <!-- <label
        class="col-form-label col-md-2">
        {{ $t('booking.area') }}：
      </label>
      <div class="col-md-4 sm-line-height text-start">
        <div class="d-inline-block me-1 mt-1">
          <input id="region-1" v-model="form.time" type="radio" class="btn-check" name="pet" value="1">
          <label class="btn btn-outline-secondary btn-sm" for="region-1">{{ $t('booking.region1') }}</label>
        </div>
        <div class="d-inline-block me-1 mt-1">
          <input id="region-2" v-model="form.time" type="radio" class="btn-check" name="pet" value="1">
          <label class="btn btn-outline-secondary btn-sm" for="region-2">{{ $t('booking.region2') }}</label>
        </div>
      </div> -->

    </div>

    <div class="row g-2 text-start text-md-end mb-1">
      <label class="col-form-label col-md-2"> {{ $t('booking.table.pet_kind_name') }}： </label>
      <div class="col-md-4 sm-line-height text-start pt-2">
          <div class="form-check form-check-inline">
            <input id="pet_kind1" v-model="screen.support_pet_kind_id" class="form-check-input" type="checkbox" @change="getDoctorList" name="pet_kind" value="d077f244def8a70e5ea758bd8352fcd8">
            <label class="form-check-label" for="pet_kind1">{{ $t('pet.cat') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="pet_kind2" v-model="screen.support_pet_kind_id" class="form-check-input" type="checkbox" @change="getDoctorList" name="pet_kind" value="06d80eb0c50b49a509b49f2424e8c805">
            <label class="form-check-label" for="pet_kind2">{{ $t('pet.dog') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="pet_kind3" v-model="screen.support_pet_kind_id" class="form-check-input" type="checkbox" @change="getDoctorList" name="pet_kind" value="f1377ab757bbfef7392653dc2fbcd7cd">
            <label class="form-check-label" for="pet_kind3">{{ $t('pet.exotic') }}</label>
          </div>
      </div>
      <!-- <label class="col-form-label col-md-2"> {{ $t('booking.timeType') }}：</label>
      <div class="col-md-4 sm-line-height text-start pt-2">
          <div class="form-check form-check-inline">
            <input id="doctorTime1" v-model="form.doctorTime" class="form-check-input" type="radio" name="doctorTime" value="1">
            <label class="form-check-label" for="doctorTime1">{{ $t('booking.time1') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="doctorTime2" v-model="form.doctorTime" class="form-check-input" type="radio" name="doctorTime" value="2">
            <label class="form-check-label" for="doctorTime2">{{ $t('booking.time2') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="doctorTime3" v-model="form.doctorTime" class="form-check-input" type="radio" name="doctorTime" value="3">
            <label class="form-check-label" for="doctorTime3">{{ $t('booking.time3') }}</label>
          </div>
      </div> -->

    </div>
    <!--
    <div class="row g-2 text-start text-md-end">
      <label class="col-form-label col-md-2"> {{ $t('booking.price') }}：  </label>
      <div class="col-md-4 sm-line-height text-start pt-2">
          <div class="form-check form-check-inline">
            <input id="priceInterval1" v-model="form.priceInterval" class="form-check-input" type="radio" name="priceInterval" value="1">
            <label class="form-check-label" for="priceInterval1">{{ $t('booking.all') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="priceInterval2" v-model="form.priceInterval" class="form-check-input" type="radio" name="priceInterval" value="2">
            <label class="form-check-label" for="priceInterval2">{{ $t('booking.400') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="priceInterval3" v-model="form.priceInterval" class="form-check-input" type="radio" name="priceInterval" value="2">
            <label class="form-check-label" for="priceInterval3">{{ $t('booking.500') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="priceInterval4" v-model="form.priceInterval" class="form-check-input" type="radio" name="priceInterval" value="2">
            <label class="form-check-label" for="priceInterval4">{{ $t('booking.600') }}</label>
          </div>
      </div>
      <label class="col-form-label col-md-2"> {{ $t('booking.assign') }}：</label>
      <div class="col-md-4 sm-line-height text-start pt-1 position-relative">
        <input class="form-control form-control-sm" type="text" name="pet_kind_id" value="" >
        <b style="cursor: pointer;"
            class="position-absolute end-0 top-0 mt-2 me-3 text-black-50 user-select-none"
            @click="showCalendar" >{{$t('booking.search')}}</b>
      </div>

    </div> -->
    <div class="row g-2 text-start text-md-end pb-2" style="overflow-x: auto;" ref="tableContent">
      <div  class="col-md-1"></div>
      <div class="col-md-11">
        <table class="table table-Secondary table-hover text-start">
          <thead class="table-secondary table-thead">
            <tr>
              <th scope="col" width="30"></th>
              <th scope="col" width="60"></th>
              <th scope="col-md">{{$t('booking.table.name')}}</th>
              <th scope="col-md"   width="100">{{$t('booking.table.seniority')}}</th>
              <th scope="col-md">{{$t('booking.table.education')}}</th>
              <th scope="col-md"  width="250">{{$t('booking.table.interest')}}</th>
              <th scope="col-md">{{$t('booking.table.support_language')}}</th>
              <!-- <th scope="col-md">{{$t('booking.table.price')}}</th> -->
              <!-- <th scope="col-md">{{$t('booking.table.type')}}</th> -->
            </tr>
          </thead>
          <tbody>
              <tr v-for="(item, index) in doctorList" :key="index"  @click="onSelectDoctor(index)">
                <td scope="row" class="td-input-radio">
                  <input class="form-check-input" type="radio" name="doctor"
                  :checked="item.selected" @click="onSelectDoctor(index)" >
                </td>
                <td class="td-avatar">
                  <div class="rounded-circle overflow-hidden td-div-avatar">
                    <img :src="item.avatar_url">
                  </div>
                </td>
                <td>
                  <span class="th-attr">{{$t('booking.table.name')}}：</span>
                  {{ i18n(item, 'name')}}
                    <img src="@/assets/form/info.png" class="ms-1"  @click.stop="onClickDoctorDetail(item)"></td>
                <td>
                  <span class="th-attr">{{$t('booking.table.seniority')}}：</span>
                  {{ item.start_work_year }}{{$t('booking.table.year')}}</td>
                <td>
                  <span class="th-attr">{{$t('booking.table.education')}}：</span>
                  {{ i18n(item, 'education') }}</td>
                <td>
                  <span class="th-attr">{{$t('booking.table.interest')}}：</span>
                  <span v-for="(field, index) in item.field_info" :key="index">{{ i18n(field, 'name')}}
                    {{ item.field_info.length - 1 > index ? '/' : '' }}</span></td>
                <td>
                  <span class="th-attr">{{$t('booking.table.support_language')}}：</span>
                  <span v-for="(lang, index) in item.support_language" :key="index">{{ i18n(lang, 'name')}}
                    {{ item.support_language.length - 1 > index ? '/' : '' }}</span></td>
                <!-- <td>
                  <span class="th-attr">{{$t('booking.table.price')}}：</span>
                  {{ recheck ? item.recheck_fee : item.registered_fee }}</td> -->
                <!-- <td>
                  <span class="th-attr">{{$t('booking.table.type')}}：</span>
                  {{ $t('booking.comprehensive')}}</td> -->
              </tr>
              <tr style="padding-left: 8px">
                <td colspan="7" class="text-start">
                  <pages
                    :total="total"
                    :pagesize="pageSize"
                    :page="currentPage"
                    @changePage="changePage"
                  />
                  <!-- <span style="float: left">{{ $t('booking.total') + doctorList.length + $t('booking.doctorTotal') }}</span> -->
                </td>
              </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div class="row g-2 text-start text-md-end" ref="doctorTimeMark">
      <label
        class="col-form-label col-md-2">
        {{ $t('booking.doctor_time') }}：
      </label>
      <div class="col-md-10 sm-line-height text-start">
        <div v-for="(dateObject, index) in dateObjectList" :key="index">
          <div v-if="dateObject.book_available == 1">
            <div class="d-inline-block me-3 mt-1">{{dateObject.date}}</div>
            <div class="d-inline-block me-1 mt-1" v-for="(item, index) in dateObject.time_list" :key="index">
              <input :id="'doctorTime'+index+dateObject.date+item.time" v-model="form.time"
              type="radio" class="btn-check" name="doctorTime" :value="index+dateObject.date+item.time" @change="onChange(dateObject.date)"
              :disabled="!item.book_available"  v-if="item.book_available">
              <label class="btn btn-outline-secondary btn-sm" :for="'doctorTime'+index+dateObject.date+item.time"  v-if="item.book_available">{{ item.time }}</label>
            </div>
          </div>
        </div>
        <div v-if="!notNullSchedule">{{$t('booking.fully_booked')}}</div>
      </div>
    </div>
    <div class="row g-2 text-start text-md-end mt-2" v-if="notNullSchedule">
      <div class="col-md-2">
        <!-- {{ $t('booking.doctor_date') }}： -->
      </div>
      <div class="col-md-4 sm-line-height text-start  position-relative">
        <input class="form-control form-control-sm col-md-4"
        type="text" name="doctorDate" :value="$t('booking.other_date')" readonly
        :placeholder="$t('booking.moreo_ptions')"
          @click="showCalendar">
        <b style="cursor: pointer;"
            class="position-absolute end-0 top-0 mt-1 me-3 text-black-50 user-select-none"
            @click="showCalendar" >▼</b>
      </div>
    </div>
    <calendar ref="calendar" :formTime="form.time" :dateObjectList="dateObjectList" :schedule_list="schedule_list" @change="selectDate"></calendar>
    <doctor :title="$t('booking.doctorInfo')" :width="'700px'" ref="doctorModel">
      <div class="row px-2">
        <div class="col-3">
          <img :src="detail.avatar_url" class="rounded img-fluid">
        </div>
        <div class="col-9">
          <div class="name fs-4">
            {{ i18n(detail, 'name') }}
          </div>
          <div class="detail p-2">
            <pre style="white-space: pre-wrap;line-height: 1.5;">{{ i18n(detail, 'description') }}</pre>
            <div class="mt-4">
              <!-- <img v-if="detail.work_photo" :src="detail.work_photo" class="rounded img-fluid"> -->
            </div>
          </div>
        </div>
      </div>
    </doctor>
  </div>
</template>
<script>

import calendar from '@/components/calendar';
import doctor from '@/components/model';
import pages from '@/components/page';

export default {
  // eslint-disable-next-line
  name: 'doctor-select-tpl',
  components: {
    calendar,
    doctor,
    pages,
  },
  props: {
    // recheck: [String, Number],
    sid: {
      type: String,
      default() {
        return '';
      },
    },
    specialist_id: {
      type: String,
      default() {
        return '';
      },
    },

    topPage: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      doctor_id: '',
      doctor: {},
      detail: {},
      recheck: 1,
      sort: 0,
      form: {
        time: '',
      },
      screen: {
        support_language_id: ['8bfd003df06f6c6cd28153e00edfc93a', 'l037kaimdu394aereoyt075jsee56e', 'l039ldkiemg93m39gma9dimeod95'],
        support_pet_kind_id: ['06d80eb0c50b49a509b49f2424e8c805', 'd077f244def8a70e5ea758bd8352fcd8', 'f1377ab757bbfef7392653dc2fbcd7cd'],
      },
      doctorDate: '',
      doctorList: [],
      pageSize: 8,
      currentPage: 1,
      notNullSchedule: false,
      total: 0,
      doctor_appointment_schedule_list: [],
      schedule_list: {},
      dateObjectList: [{
        book_available: 0,
        time_list: [],
      }],
      todate: this.dateTimeFormat(new Date(), 'yyyy-MM-dd'),
    };
  },
  watch: {
    recheck: {
      handler(newData, oldData) {
        this.onRecheck(newData);
        this.getDoctorList();
      },
    },
    sort: {
      handler(newData, oldData) {
        this.getDoctorList();
      },
    },
  },
  mounted() {
    if (this.sid) {
      this.doctor_id = this.sid;
    }
    // console.log('sid', this.sid);
    // console.log('specialist_id', this.specialist_id);
    this.getDoctorList();
  },
  methods: {
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.getDoctorList();
    },
    onClickDoctorDetail(detail) {
      this.detail = detail;
      this.$refs.doctorModel.show();
    },
    selectDate(date) {
      this.doctorDate = date.fulldate;
      // this.$refs.calendar.hide();
      this.getTimeList();
    },
    showCalendar() {
      this.$refs.calendar.show();
    },
    onSelectDoctor(key) {
      this.doctorList.forEach((item, index) => {
        item.selected = false;
        if (index == key) {
          item.selected = true;
          // this.doctor_appointment_schedule_list = item.doctor_appointment_schedule_list;
          this.doctor_id = item.id;
          this.doctor = item;
          this.$api.doctor.getDoctorSchedule({ id: this.doctor_id }).then((list) => {
            if (list.data.code == 200) {
              this.doctor_appointment_schedule_list = list.data.data.list;
              this.setScheduleList(1);
              this.getTimeList();
            }
          });
        }
      });
    },
    setScheduleList(t) {
      this.notNullSchedule = false;
      this.doctor_appointment_schedule_list.forEach((schedule) => {
        if (schedule.book_available) {
          this.notNullSchedule = true;
        }
        this.schedule_list[schedule.date] = {};
        if (schedule.time_list && schedule.time_list.length) {
          let n = schedule.time_list.filter((time) => time.book_available == 1);
          this.schedule_list[schedule.date].quantity = n.length || 0;
        } else {
          this.schedule_list[schedule.date].quantity = 0;
        }
      });
      if (this.notNullSchedule == false) {
        (this.topPage || t) && this.$toast.show({ content: this.$t('booking.fully_booked'), delay: 2000 });
      } else {
        (this.topPage || t) && this.to(this.$refs.doctorTimeMark);
      }
    },
    onRecheck(index) {
      this.$emit('recheckSelect', index);
    },
    onChange(date) {
      this.$emit('changeSelect', {
        time: this.form.time.substr(this.form.time.length - 5) || '',
        date: date || this.todate,
        doctor_id: this.doctor_id,
        doctor: this.doctor,
        recheck: this.recheck,
      });
    },
    pulsDate(date) {
      let time = +new Date(date) + 86400000;
      return this.dateTimeFormat(new Date(time), 'yyyy-MM-dd');
    },
    getWeekTime(todate) {
      let date = ''; let time = '';
      this.dateObjectList = [];
      for (let i = 0; i < 7; i++) {
        // eslint-disable-next-line no-loop-func
        let dateObject = this.doctor_appointment_schedule_list.find((item) => item.date == todate) || { book_available: 0, time_list: [] };
        if (dateObject && dateObject.time_list && dateObject.time_list.length) {
          // eslint-disable-next-line no-loop-func
          dateObject.time_list.forEach((item) => {
            if (item.book_available) {
              date = dateObject.date;
              time = item.time;
            }
          });
        }
        this.dateObjectList.push(dateObject);
        todate = this.pulsDate(todate);
      }
      return { date, time };
    },
    getTimeList() {
      let todate = this.doctorDate || this.todate;
      let dateObject = this.getWeekTime(todate);
      this.form.time = dateObject.time ? `0${dateObject.date}${dateObject.time}` : '';
      this.onChange(dateObject.date);
    },
    getDoctorList() {
      let search = {};
      if (this.screen.support_pet_kind_id.length == 0) {
        this.screen.support_pet_kind_id.push('f1377ab757bbfef7392653dc2fbcd7cd');
      }
      if (this.screen.support_language_id.length == 0) {
        this.screen.support_language_id.push('8bfd003df06f6c6cd28153e00edfc93a');
      }
      search = this.screen;
      if (this.specialist_id) {
        this.screen.specialist_id = this.specialist_id;
        search.specialist_id;
      }
      // console.log(search, this.specialist_id);
      this.$api.doctor.getDoctorList({
        page_size: this.pageSize, current_page: this.currentPage, sort: this.sort, recheck: this.recheck, ...search,
      }).then((res) => {
        if (res.data && res.data.code == 200) {
          // let list = [];
          // console.log('doctor_id', this.sid);
          res.data.data.list.forEach((item, index) => {
            item.selected = false;
            // console.log('doctor_id', this.sid);
            if (this.sid && this.sid == item.id) {
              item.selected = true;
              this.getDoctorSchedule(item);
            } else if (index == 0) {
              item.selected = true;
              this.getDoctorSchedule(item);
              // console.log('doctor_id_error_error', this.sid);
            }
          });
          let tmpCurrentPage = this.currentPage;
          this.doctorList = res.data.data.list;
          this.pageSize = res.data.data.pages.page_size;
          this.currentPage = res.data.data.pages.current_page;
          this.total = res.data.data.pages.total;
          setTimeout(() => {
            if (tmpCurrentPage != 1) this.to(this.$refs.tableContent);
          }, 500);
        }
      });
    },
    getDoctorSchedule(item) {
      this.doctor_id = item.id;
      this.doctor = item;
      this.$api.doctor.getDoctorSchedule({ id: this.doctor_id }).then((list) => {
        if (list.data.code == 200) {
          this.doctor_appointment_schedule_list = list.data.data.list;
          this.setScheduleList(0);
          this.getTimeList();
        }
      });
    },
    to(toEl) {
      // toEl为指定跳转到该位置的DOM节点
      let bridge = toEl;
      let { body } = document;
      let height = 0;

      // 计算该 DOM 节点到 body 顶部距离
      do {
        height += bridge.offsetTop;
        bridge = bridge.offsetParent;
      } while (bridge !== body);

      // 滚动到指定位置
      window.scrollTo({
        top: height,
        behavior: 'smooth',
      });
    },

  },
};
</script>
<style lang="scss" scoped>
.table{
  border:1px solid rgba(0,0,0,0.1);
  .table-secondary{
        --bs-table-bg: #F3F2ED
  }
  tbody{
    border: 0px;
    tr{
      border-bottom-width: 0px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      .th-attr{
        display: none;
      }
    }
    .td-div-avatar{
      width: 28px;height: 28px;
      img{
        width: 28px;height: 28px;object-fit: cover;
      }
    }
  }
}
  @media (max-width: 767.98px) {
    .sm-line-height{
        // margin-top: -10px;
        margin-bottom: 10px;
        padding-left: 40px;
    }
    .table{
      border:0px solid rgba(0,0,0,0);
      overflow: hidden;
      .table-secondary{
            --bs-table-bg: #F3F2ED
      }
      .table-thead{
        display: none;
      }
      tbody{
        border: 0px;
        tr{
          border-bottom-width: 0px;
          border: 1px solid rgba(0,0,0,0.1);
          position: relative;
          margin-bottom: 10px;
          border-radius: 4px;
          display: block !important;
          width: 100%;
          padding: 4px 4px 4px 100px;
          td{
            padding: 0.5rem 0.5rem;
            border-bottom-width: 0;
            box-shadow: none;
            display: block !important;
            width: 100%;
            padding: 2px;
            .th-attr{
              display: inline-block;
            }
          }
          .td-input-radio{
            position: absolute;
            top: 20px;
            left: 10px;
          }
          .td-avatar{
            position: absolute;
            top: 4px;
            left: 30px;
            width: 58px;
            .td-div-avatar{
              width: 58px;height: 58px;
              border: 1px solid rgba(0,0,0,0.1);
              img{
                width: 58px;height: 58px;object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
</style>
