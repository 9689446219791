import { createStore } from 'vuex';

const Store = (function () {
  let instance;
  return (function () {
    if (!instance) {
      instance = createStore({
        state() {
          return {
            hasLogin: false,
            token: '',
            user: {},
            from: '',
          };
        },
        mutations: {
          SET_USER_INFO(state, info) {
            if (info && info.token) {
              state.token = info.token;
              state.hasLogin = true;
              state.user = info;
            } else {
              state.token = '';
              state.hasLogin = false;
              state.user = {};
            }
          },
        },
        actions: {
          setUserInfo({ commit, state }, info) {
            commit('SET_USER_INFO', info);
            info && localStorage.setItem('userInfo', JSON.stringify(info));
          },
          getUserInfo({ commit, state }) {
            let info = state.user;
            if (!state.token && !state.user.token) {
              info = JSON.parse(localStorage.getItem('userInfo') || '{}');
              commit('SET_USER_INFO', info);
            }
            return info;
          },
        },
      });
      instance.dispatch('getUserInfo');
    }
    return instance;
  }());
}());

Store.install = (app) => {
  app.config.globalProperties.$store = Store;
};
export default Store;
