<template>
  <div style="padding-bottom: 100px;">
    <div class="banner">
      <img
        src="@/assets/banner/5.png"
        class="img-fluid"
      >
      <div class="banner-content">
        <div class="banner-content-box">
          <div class="banner-content-box-title">
            {{ $t('home.banner.title') }}
          </div>
          <div class="banner-content-box-line">
            {{ $t('home.banner.body') }}
          </div>
        </div>
        <div class="banner-content-btns">
          <button
            type="button"
            class="btn btn-danger"
          >
            {{ $t('home.banner.btn1') }}
          </button>
        </div>
      </div>
    </div>

    <div class="industry-data">
      <div class="container-md">
        <div class="row">
          <div class="col">
            <div class="number">
              2800+
            </div>
            <div>{{ $t('home.industry.service') }}</div>
          </div>
          <div class="col">
            <div class="number">
              90+
            </div>
            <div>{{ $t('home.industry.veterinarian') }}</div>
          </div>
          <div class="col">
            <div class="number">
              100%
            </div>
            <div>{{ $t('home.industry.education') }}</div>
          </div>
          <div class="col">
            <div class="number">
              98%
            </div>
            <div>{{ $t('home.industry.satisfaction') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-md text-center p-md-5 why-choose">
      <h1 class="text-center py-5">
        {{ $t('home.why_choose.title') }}
      </h1>
      <div class="container-md d-md-flex text-start">
        <div>
          <img
            src="@/assets/pages/home1.png"
            class="d-inline-block align-text-center img-fluid"
          >
        </div>
        <div class="content" style="padding: 8vw 2.6vw;">
          <h4>{{ $t('home.why_choose.box1.title') }}</h4>
          <div>{{ $t('home.why_choose.box1.content1') }}</div>
          <div>{{ $t('home.why_choose.box1.content2') }}</div>
        </div>
      </div>
      <div class="container-md d-md-flex text-end">
        <div class="flex-grow-1"></div>
        <div class="content mt-auto" style="padding: 8vw 2.6vw;">
          <h4>{{ $t('home.why_choose.box2.title') }}</h4>
          <div>{{ $t('home.why_choose.box2.content1') }}</div>
          <div>{{ $t('home.why_choose.box2.content2') }}</div>
        </div>
        <div class="mt-auto">
          <img
            src="@/assets/pages/home2.png"
            class="d-inline-block align-text-center img-fluid"
          >
        </div>
      </div>
    </div>

    <div class="bg-light">
      <div class="container-md  text-center p-md-5 veterinary-team">
        <h1 class="text-center py-3">
          {{ $t('home.veterinary_team.title') }}
        </h1>
        <div>{{ $t('home.veterinary_team.directions1') }}</div>
        <div>{{ $t('home.veterinary_team.directions2') }}</div>
        <div class="veterinary-team-list">

        </div>
      </div>
    </div>

    <div class="divider text-center">
      <div class="divider-bg"></div>
      <a href="#reserve"><h2 class="text-center p-3 divider-content"><u>{{ $t('home.experience.title') }}</u></h2></a>
    </div>
    <div class="container-md p-md-5 when">
        <h1 class="text-center py-3">
          {{ $t('home.experience.when') }}
        </h1>
        <div class="row px-md-5" v-for="(line,key) in whenCart" v-bind:key="key">
          <div class="col-md p-2" v-for="(item,index) in line" v-bind:key="index">
            <div class="item-card">
              <div class="item-card-title"><h4>{{ $t('home.experience.when_cart.'+key+'.'+index+'.title') }}</h4></div>
              <div class="item-card-body">{{ $t('home.experience.when_cart.'+key+'.'+index+'.body') }}</div>
            </div>
          </div>
        </div>
        <h1 class="text-center py-5">
          {{ $t('home.process.title') }}
        </h1>
        <div class="row px-md-5 process">
          <div class="col-md p-2 text-center" v-for="(item,key) in process" v-bind:key="key">
            <div class="process-title">{{ $t('home.process.list.'+key+'.title') }}</div>
            <div class="process-head">{{ $t('home.process.list.'+key+'.head') }}</div>
            <div class="process-body">{{ $t('home.process.list.'+key+'.body') }}</div>
          </div>
        </div>
    </div>

    <div class="bg-light" id="reserve">
      <div class="container-md p-md-5 reserve text-center">
        <h2 class="text-center py-5">
          {{ $t('home.reserve.title') }}
        </h2>
        <div class="reserve-cart">
          <div class="reserve-cart-item d-inline-block g">
            <div class="text-end reserve-cart-title">{{ $t('home.reserve.cart.0.title') }}|{{ $t('home.reserve.cart.0.time') }}</div>
            <div class="text-start reserve-cart-icon">
              <img src="@/assets/pages/video.png" width="100" class="d-block align-text-top img-fluid">
            </div>
            <div class="reserve-cart-content">
              <div class="text-start"><h5>{{ $t('home.reserve.cart.0.content') }}</h5></div>
              <div class="text-start">{{ $t('home.reserve.cart.0.footer') }}</div>
            </div>
          </div>
          <div class="reserve-cart-item d-inline-block b">
            <div class="text-end reserve-cart-title">{{ $t('home.reserve.cart.1.title') }}|{{ $t('home.reserve.cart.1.time') }}</div>
            <div class="text-start reserve-cart-icon">
              <img src="@/assets/pages/reserve.png" width="70" class="d-block align-text-top img-fluid">
            </div>
            <div class="reserve-cart-content">
              <div class="text-start"><h5>{{ $t('home.reserve.cart.1.content') }}</h5></div>
              <div class="text-start">{{ $t('home.reserve.cart.1.footer') }}</div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="bg-info">
      <div class="container-md p-4 px-md-5 subscribe row">
        <div class="col">
          <h2>
            {{ $t('home.subscribe.title') }}
          </h2>
          <div>{{ $t('home.subscribe.content') }}</div>
        </div>
        <div class="col">
          <form class="row g-3">
            <div class="col-auto" style="flex: 1 0 auto;">
              <label for="staticEmail" class="visually-hidden">Email</label>
              <input type="text" class="form-control" id="staticEmail" placeholder="Email Address">
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-success mb-3">{{ $t('home.subscribe.btn') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="bg-dark py-3 text-center text-white">
      {{ $t('home.customer') }}  support@bnbvetonline.com
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'petstory-index',
  components: {

  },
  computed: {
    whenCart() {
      let list = [];
      for (let index = 0; index < 4; index++) {
        list.push([]);
        list[index].push({});
        list[index].push({});
      }
      return list;
    },
    process() {
      let list = [];
      for (let index = 0; index < 3; index++) {
        list.push({});
      }
      return list;
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
.bg-light{
  background-color: #F3F2ED !important;
}
.bg-info{
  background-color: #CFCEB1 !important;
}
.banner{
  position: relative;
  .img-fluid{
    width: 100%;
  }
  .banner-content{
    position: absolute;
    left: 20%;
    top: 29.5%;
    font-size: 2.8vw;
    color: hsl(0, 0%, 100%);
    font-weight: 300;
    line-height: 4.2vw;
    .banner-content-box-line{
      font-weight: 200;
      font-size: 2.6vw;
    }
    .btn-danger{
      background-color: #89503D;
      border-color: #89503D;
    }
  }
}

.industry-data{
  width: 100%;
  background: #6A7162;
  text-align: center;
  color: #ffffff;
  .col{
    text-align: center;
    padding: 20px;
    .number{
      font-size: 52px;
    }
  }
}

.why-choose{
  .content{
    font-size: 18px;
  }
}

.veterinary-team{
  .veterinary-team-list{
    .carousel-item{
      width: 25% !important;
      display: block !important;
    }
  }
}

.divider{
  background-color: #798D62;
  position: relative;
  overflow: hidden;
  height: 64px;
  .divider-bg{
    width: 40vw;
    height: 70vw;
    background-color: #514D48;
    z-index: 0;
    position: absolute;
    left: 50%;
    margin: -25vw auto auto -28vw ;
    transform: rotate(45deg);
  }
  .divider-content{
    z-index: 2;
    position: absolute;
    width: 100%;
    color: #ffffff;
  }
}
.when{
  .item-card{
    background-color: #F3F2ED;
    border-radius: 10px;
    padding: 18px;
  }
}
.process{
  .process-title{
    font-size: 72px;
    color: #515A7C;
    font-family: Bangla MN;
    line-height: 1.2;
  }
  .process-head{
    font-size: 22px;
  }
}
.reserve{
  &-cart-item{
    width: 245px;
    height: 252px;
    opacity: 1;
    border-radius: 12px;
    margin-right: 30px;
    padding: 20px;
    color: #ffffff;
  }
  .reserve-cart-item{
    position: relative;
    .reserve-cart-title{
      border-radius: 6px;
      display: inline;
      float: right;
      padding: 1px 8px;
      color: #ffffff;
      background: rgba(255, 255, 255, 0.18);
    }
    .reserve-cart-icon{
      margin-top: 70px;
      display: block;
      position: absolute;
    }
    .reserve-cart-content{
      position: absolute;
      bottom: 20px;

    }
  }
  .g{
    background: linear-gradient(45deg, #808879 0%, #546547 100%);
  }
  .b{
    background: linear-gradient(45deg, #646F8B 0%, #434B72 100%);
  }
}
.subscribe{
  .btn-success{
      background-color: #627056;
      border-color: #627056;
      border: 0;
  }
}
</style>
