<template>
  <div style="padding-bottom: 100px;">
    <div class="top-tools">
      <img
          :src="require('@/assets/navbar/navbar-light.png')"
          alt=""
          width="79"
          height="34"
          class="d-inline-block align-text-center"
          style="position: absolute;left:10px"
        >
      <span class="lang-btn" v-if="$i18n.locale == 'en'" @click="onChangeLang()">中文</span>
      <span class="lang-btn" v-else @click="onChangeLang()">ENG</span>
      <!-- <img
        :src="require('@/assets/navbar/lang-light.png')"
        alt=""
        width="18"
        height="18"
        class="d-inline-block align-text-center lang"
        @click="onChangeLang()"
      > -->
      <!-- <i class="icon icon-info" @click="onNavClick('/about')"></i> -->
      <i class="icon icon-notice" @click="onNavClick('/member/notice')"></i>
      <!-- <i class="icon icon-my" @click="onNavClick('/member')"></i> -->
    </div>
    <div class="container-md p-0">
      <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(item, index) in imgs[$i18n.locale]" :key="index" :class="['carousel-item', index === 0 && 'active']">
            <img :src="item" class="d-block w-100" alt="#">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <industry-data-tpl /> <!-- 行业数据 -->
   <!--  <why-choose-tpl /> --> <!-- 为什么选择 -->
   <!--  <team-tpl /> --> <!-- 兽医团队 -->
   <!-- <divider-tpl /> --> <!-- 分隔线 -->
   <!--  <when-tpl /> --> <!-- 什么时候需要 -->
   <!--  <process-tpl /> --> <!-- 流程 -->
   <!--  <reserve-tpl /> --> <!-- 预约 -->
  <!--   <subscribe-tpl /> --> <!-- 订阅 -->
    <div class="reserve-page">
      <easyTpl v-if="showPage == 'easy'" :topPage="false" @changePageEvent="changePageEvent"/>
      <bookingTpl v-if="showPage == 'booking'" :topPage="false" @changePageEvent="changePageEvent"/>
    </div>
    <footer-tpl /> <!-- 页脚 -->
  </div>
</template>
<script>
// import bannerTpl from '@/components/banner';
import industryDataTpl from '@/components/industry-data';
// import whyChooseTpl from '@/components/why-choose';
// import teamTpl from '@/components/team';
// import dividerTpl from '@/components/divider';
// import whenTpl from '@/components/when';
// import processTpl from '@/components/process';
// import reserveTpl from '@/components/reserve';
// import subscribeTpl from '@/components/subscribe';
import bookingTpl from '@/pages/booking/index';
import easyTpl from '@/pages/booking/easy';
import footerTpl from '@/components/footer';

export default {
  // eslint-disable-next-line
  name: 'home-index',
  components: {
    // bannerTpl,
    industryDataTpl,
    // whyChooseTpl,
    // teamTpl,
    // dividerTpl,
    // whenTpl,
    // processTpl,
    // reserveTpl,
    // subscribeTpl,
    bookingTpl,
    easyTpl,
    footerTpl,
  },
  data() {
    return {
      showPage: 'booking',
      imgs: {
        en: [
          require('@/assets/banner/1-en.jpg'),
          require('@/assets/banner/2-en.jpg'),
        ],
        zh_TW: [
          require('@/assets/banner/1-tw.jpg'),
          require('@/assets/banner/2-tw.jpg'),
        ],
      },
    };
  },
  methods: {
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
      localStorage.setItem('client-language', this.$i18n.locale);
    },
    onNavClick(path) {
      this.$router.push(path);
    },
    changePageEvent(page) {
      this.showPage = page;
    },
  },
};
</script>
<style lang="scss" scoped>
.top-tools{
  background-color: #EFEFEF;
  display: none;
}
@media (max-width: 767.98px) {
  .top-tools{
    background-color: #EFEFEF;
    display: block;
    width: 100%;
    height: 70px;
    text-align: right;
    padding: 10px 20px;
    .icon{
      font-size: 22px;
      margin-left: 10px;
    }
    .lang{
      vertical-align: top;
      margin-top: 4px;
    }

  }
}
</style>
