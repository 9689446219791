<template>
    <div class="bg-info text-center text-light">
      <div class="container-md py-4 px-1 px-md-5 subscribe mx-auto row">
        <div class="col-md">
          <h2 class="text-start">
            {{ $t('home.subscribe.title') }}
          </h2>
          <div class="text-start">{{ $t('home.subscribe.content') }}</div>
        </div>
        <div class="col-md pt-2">
          <form class="row g-3">
            <div class="col-auto" style="flex: 1 0 auto;">
              <label for="staticEmail" class="visually-hidden">Email</label>
              <input type="text" class="form-control" id="staticEmail" v-model="email" placeholder="Email Address">
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-success mb-3" @click="onClickSend">{{ $t('home.subscribe.btn') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
<script>
// 订阅
export default {
  name: 'subscribe-tpl',
  data() {
    return {
      email: '',
    };
  },
  mounted() {

  },
  methods: {
    subscribeMail() {
      if (this.email && this.checkEmail(this.email)) {
        this.$api.user.subscribeMail({ email: this.email }).then((res) => {
          if (res.data && res.data.code == 200) {
            this.email = '';
            this.$toast.show({ content: this.$t('subscribeSuccess') });
          } else {
            this.$toast.show({ content: res.data.msg });
          }
        });
      } else {
        this.$toast.show({
          title: this.$t('tips'),
          content: `${this.$t('home.subscribe.again')}`,
          confirm: this.$t('home.subscribe.confirm'),
          autohide: false,
        }).then((_) => {
        });
      }
    },
    onClickSend() {
      this.subscribeMail();
    },
  },
};
</script>
