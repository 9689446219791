<template>
  <div class="pet-edit">
    <div class="text-center">
      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          {{ $t('pet.header') }}：
        </label>
        <div class="col-md-5 sm-line-height">
          <div class="text-start">
            <div
              v-if="form.avatar"
              class="rounded-circle bg-light d-inline-block overflow-hidden"
              style="width: 120px;height: 120px"
            >
              <img
                :src="form.avatar"
                style="width: 120px;height: 120px;object-fit: cover;"
              >
            </div>
            <upload @success="uploadSuccess" />
          </div>
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.name') }}：
        </label>
        <div class="col-md-5 sm-line-height">
          <input
            v-model="form.name"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.birthday') }}：
        </label>
        <div class="col-md-5 sm-line-height">
          <input
            v-model="form.birthday"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.pettype') }}：
        </label>
        <div class="col-md-5 sm-line-height text-start">
          <div
            v-for="(item, index) in pet_kind"
            :key="index"
            class="form-check form-check-inline"
          >
            <label
              class="form-check-label"
            >
            <input
              v-model="form.pet_kind_id"
              class="form-check-input"
              type="radio"
              name="pet_kind_id"
              :value="item.id"
            >{{ i18n(item, 'name') }}</label>
          </div>
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.gender') }}：
        </label>
        <div class="col-md-5 sm-line-height text-start">
          <div class="form-check form-check-inline">
            <input
              id="gender1"
              v-model="form.gender"
              class="form-check-input"
              type="radio"
              name="gender"
              value="1"
            >
            <label
              class="form-check-label"
              for="gender1"
            >{{ $t('pet.male') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="gender2"
              v-model="form.gender"
              class="form-check-input"
              type="radio"
              name="gender"
              value="2"
            >
            <label
              class="form-check-label"
              for="gender2"
            >{{ $t('pet.female') }}</label>
          </div>
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.neutered') }}：
        </label>
        <div class="col-md-5 sm-line-height text-start">
          <div class="form-check form-check-inline">
            <input
              id="neutered1"
              v-model="form.is_neutered"
              class="form-check-input"
              type="radio"
              name="neutered"
              value="1"
            >
            <label
              class="form-check-label"
              for="neutered1"
            >{{ $t('pet.yes') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="neutered2"
              v-model="form.is_neutered"
              class="form-check-input"
              type="radio"
              name="neutered"
              value="2"
            >
            <label
              class="form-check-label"
              for="neutered2"
            >{{ $t('pet.no') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="neutered3"
              v-model="form.is_neutered"
              class="form-check-input"
              type="radio"
              name="neutered"
              value="3"
            >
            <label
              class="form-check-label"
              for="neutered3"
            >{{ $t('pet.unknown') }}</label>
          </div>
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          <span class="text-danger">*</span>{{ $t('pet.breed') }}：
        </label>
        <div class="col-md-5 sm-line-height">
          <input
            v-model="form.breeds"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
        <span class="text-danger">*</span>{{ $t('pet.weight') }} (kg)：
        </label>
        <div class="col-md-5 sm-line-height">
          <input
            v-model="form.weight"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="row g-2 mb-3 text-start text-md-end">
        <label
          class="col-form-label col-md-4"
        >
          {{ $t('pet.blood') }}：
        </label>
        <div class="col-md-5 sm-line-height">
          <input
            v-model="form.blood_type"
            type="text"
            class="form-control"
          >
        </div>
      </div>

      <div class="row g-2 mb-1 text-center justify-content-md-center mt-3">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-secondary btn-sm me-2 px-5"
            @click="cancel"
          >
            {{ $t('member.cancel') }}
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm px-5"
            @click="confirm"
          >
            {{ $t('member.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import upload from '@/components/upload2';

export default {
  // eslint-disable-next-line
  name: 'member-pet-form',
  components: {
    upload,
  },
  props: {
    pet_id: null,
  },
  data() {
    return {
      title: '',
      pet_kind: [],
      form: {
        avatar: '',
        avatar_thumbnail: '',
        name: '',
        birthday: '',
        birthday_text: '',
        microchip: '',
        pet_kind_id: '',
        gender: '',
        is_neutered: '',
        weight: '',
        blood_type: '',
        breeds: '',
      },
    };
  },
  mounted() {
    if (this.pet_id) {
      this.getPetDetail();
    }
    this.getPetKind();
  },
  methods: {
    getPetKind() {
      this.$api.pet.getPetKind().then((kind) => {
        if (kind.data.code == 200) {
          this.pet_kind = kind.data.data.list;
        }
      });
    },
    getPetDetail() {
      this.$api.pet.getPetInfo({ id: this.pet_id }).then((pet) => {
        if (pet.data.code == 200) {
          pet.data.data.info.vaccination_record_list
          && pet.data.data.info.vaccination_record_list.forEach((item, index) => {
            item.ts = +new Date() + index + 1;
          });
          this.form = pet.data.data.info;
        }
      });
    },
    uploadSuccess(file) {
      this.form.avatar = file.url;
      this.form.avatar_thumbnail = file.url_tiny;
    },
    verify() {
      // if (!this.form.avatar) { return true;}
      if (!this.form.name) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.name'), delay: 5000 });
        return true;
      }
      if (!this.form.birthday) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.birthday'), delay: 5000 });
        return true;
      }
      if (!this.form.pet_kind_id) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.pettype'), delay: 5000 });
        return true;
      }
      if (!this.form.gender) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.gender'), delay: 5000 });
        return true;
      }
      if (!this.form.is_neutered) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.neutered'), delay: 5000 });
        return true;
      }
      if (!this.form.breeds) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.breed'), delay: 5000 });
        return true;
      }
      if (!this.form.weight) {
        // eslint-disable-next-line
        this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.enter') + ' ' + this.$t('pet.weight'), delay: 5000 });
        return true;
      }
      return false;
    },
    confirm() {
      if (this.verify()) {
        return false;
      }

      // if (!this.pet_id) {
      //   this.$api.pet.addPet(this.form).then((res) => {
      //     if (res.data.code == 200) {
      //       this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.addSuccess') });
      //       this.$emit('success');
      //     } else {
      //       this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
      //     }
      //   }).catch((res) => {
      //     this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
      //   });
      // } else {
      this.$api.pet.updatePet(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$toast.show({ title: this.$t('tips'), content: this.$t('pet.saveSuccess') });
          this.$emit('success');
        } else {
          this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
        }
      }).catch((res) => {
        this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
      });
      // }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style lang="scss" scoped>
  .pet-edit{
    background-color: #ffffff;
    border-radius: 4px;
    padding: 10px 30px;
    width: 100%;
    hr{
      background-color: var( --bs-gray-600 ) ;
    }
  @media (max-width: 767.98px) {
    .sm-line-height{
        margin-top: -6px;
        padding-left: 40px;
    }
  }
  }
</style>
