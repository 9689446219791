import { createRouter, createWebHistory } from 'vue-router';

const Router = (function () {
  let instance;
  const pages = require.context('./pages', true, /.vue$/);
  const routerList = [];
  pages.keys().forEach((fileName) => {
    const componentConfig = pages(fileName);
    const comp = componentConfig.default || componentConfig;
    let path = '';
    comp.name.split('-').forEach((item) => {
      if (item != 'index') {
        path += `/${item}`;
      }
    });

    if (path == '/home') {
      routerList.push({
        path: '/',
        name: comp.name,
        component: comp,
        alias: path,
      });
    } else {
      routerList.push({
        path,
        name: comp.name,
        component: comp,
      });
    }
  });

  return (function () {
    if (!instance) {
      instance = createRouter({
        history: createWebHistory(),
        routes: routerList,
      });
    }
    return instance;
  }());
}());

export default Router;
