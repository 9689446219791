<template>
  <div
    ref="content-page"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('notice.title') }}
          </div>
          <div class="btns" />
        </div>
        <hr>
        <div class="content"  v-if="currentList.length">
          <div
            v-for="(item,index) in currentList"
            :key="index"
            class="list"
            @click="onClickDetail(item.id)"

          >
            <div class="content  lh-sm fs-5">
              {{ item.create_date }} <span v-html="i18n(item, 'content')" />
            </div>
            <div>{{item.created_at}}</div>
          </div>

          <pages
            :total="total"
            :pagesize="pageSize"
            :page="currentPage"
            @changePage="changePage"
          />
        </div>
        <div v-else class="content">
          <div style='margin-top:50px;font-size:42px; color: #eee'>{{$t('notice.nothing')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pages from '@/components/page';

export default {
  // eslint-disable-next-line
  name: 'member-notice',
  components: {
    pages,
  },
  data() {
    return {
      list: [],
      pageSize: 20,
      currentPage: 1,
    };
  },
  computed: {
    currentList() {
      return this.list;
      // return this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.user.getNotificationList({ page_size: this.pageSize, current_page: this.currentPage }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.list;
          this.pageSize = res.data.data.pages.page_size;
          this.currentPage = res.data.data.pages.current_page;
          this.total = res.data.data.pages.total;
        }
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.$refs['content-page'].scrollTop = 0;
    },
    onClickReserve() {
      this.$router.push('/reserve');
    },
    onClickDetail() {

    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
      .list{
        border-bottom: 1px solid #efefef;
        padding: 20px 0px 20px 100px;
        color: var( --bs-gray-600 );
      }
    }
  }
  @media (max-width: 767.98px) {
    .content-padding-left{
      .list{
        border-bottom: 1px solid #efefef;
        padding: 20px 0px 20px 20px;
        color: var( --bs-gray-600 );
      }
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
    }
  }
}
</style>
