<template>
  <div class="easy-booking">
    <div class="container-md">
      <div class="container pt-2 p-md-4 text-center">
        <div class="d-flex m-auto mb-5 switch-box">
          <div class="title flex-grow-1 fs-6">
            <span @click="goToBooking"  class="switch-btn">{{ $t('booking.title2') }}</span>
          </div>
          <div class="title flex-grow-1 fs-6">
            <span class="switch-btn active">{{ $t('booking.title') }}</span>
          </div>
        </div>
        <div class="content">
          <div class="row g-2 mb-md-3 text-start text-md-end">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.pet') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start">
              <div
                v-for="(item, index) in petList" :key="index"
                class="d-inline-block me-1 mt-1"
              >
                <input
                  :id="'radio-id-'+index"
                  v-model="form.pet_id"
                  type="radio"
                  class="btn-check"
                  name="pet"
                  :value="item.id"
                   @change="onPetSelect(item)"
                >
                <label
                  class="btn btn-outline-secondary btn-sm"
                  :for="'radio-id-'+index"
                >
                  <div style="width: 70px;height: 70px;overflow: hidden;border-radius: 50%;">
                    <img :src=" item.avatar ? (item.avatar + '?imageMogr2/crop/70x70') : (item.pet_kind_id == '06d80eb0c50b49a509b49f2424e8c805' ? require('@/assets/pages/802.jpg') : ( item.pet_kind_id == 'd077f244def8a70e5ea758bd8352fcd8' ? require('@/assets/pages/801.jpg') : require('@/assets/pages/803.jpg') ) )" />
                  </div>

                 {{ item.name }}
                </label>
              </div>

              <button
                type="button"
                class="btn btn-sm btn-outline-dark mt-1"
                @click="onShowModel"
                style="width: 88px;height: 101px;vertical-align: top;border: 1px solid #ddd;"
              >
                <img
                  src="@/assets/form/add.png"
                  width="16"
                >
                {{ $t('pet.add') }}
              </button>
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.lang') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start position-relative">
              <div class="form-check" v-for="(item, index) in languageList" :key="index">
                <input class="form-check-input" type="checkbox" v-model="form.support_language_id" :value="item.id" :id="item.id" checked>
                <label class="form-check-label" :for="item.id">
                  {{i18n(item, 'name')}}
                </label>
              </div>
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.confirmTime') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start position-relative">
              <div>
                <input
                  id="nowTime"
                  v-model="selectTime"
                  @change="onChenag"
                  class="form-check-input"
                  type="radio"
                  name="timeRadios"
                  value="0"
                  checked
                >
                <label
                  class="form-check-label ms-3"
                  for="nowTime"
                >
                  {{ $t('booking.within_30_mins') }}
                </label>
              </div>
              <div class="mt-2">
                  <input
                    id="laterTime"
                    v-model="selectTime"
                    @change="onChenag"
                    class="form-check-input"
                    type="radio"
                    name="timeRadios"
                    value="1"
                    style="margin-top: 14px;"
                  >
                  <label
                    class="form-check-label ms-3"
                    for="laterTime"
                  >
                    <VueCtkDateTimePicker v-model="dateTime"
                      format="YYYY-MM-DD HH:mm"
                      formatted="YYYY-MM-DD HH:mm" minute-interval="15"
                      :label="$t('booking.later_time')"
                      no-button-now="true"
                    />
                  </label>

              </div>
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.voucher') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start position-relative">
              <input
                v-model="voucher"
                type="text"
                class="form-control"
                @blur="onApplyVoucher"
              >
              <b
                style="cursor: pointer;"
                class="position-absolute end-0 top-0 mt-2 me-3 text-black-50 user-select-none"
                @click="onApplyVoucher"
              >{{ $t('booking.apply') }}</b>
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end" v-if="$store.state.hasLogin">
            <label
              class="col-form-label col-md-4 pt-2"
            >
              {{ $t('booking.consultingfee') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start pt-2">
              HK${{ totalPrice }}
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end"  v-if="$store.state.hasLogin">
            <label
              class="col-form-label col-md-4 pt-2">
              {{ $t('booking.discountAmount') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start pt-2">
              HK${{discount && discount || 0}}
            </div>
          </div>
          <!-- <div class="row g-2 mb-md-3 text-start text-md-end" v-if="$store.state.hasLogin">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.service') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start pt-2">
              $0
            </div>
          </div> -->
          <div class="row g-2 mb-md-3 text-start text-md-end" v-if="$store.state.hasLogin">
            <label
              class="col-form-label col-md-4 pt-2"
            >
              {{ $t('booking.subtotal') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start pt-2">
              HK${{ price }}
            </div>
          </div>
          <div class="row g-2 mb-md-3 text-start text-md-end" v-if="$store.state.hasLogin">
            <label
              class="col-form-label col-md-4"
            >
              {{ $t('booking.paytype') }}：
            </label>
            <div class="col-md-5 sm-line-height text-start pt-2 payment-method-list">
              <pay-btns @selected="paySelect" />
            </div>
          </div>
          <div class="row g-2 text-start text-md-end">
            <label
              class="col-form-label col-md-4  pt-2">
              {{ $t('booking.prompt') }}：
            </label>
            <div class="col-md-5 text-start pt-2">
              {{ $t('booking.promptText') }}
            </div>
          </div>
          <hr>

          <div class="row g-2 mb-1 text-center justify-content-md-center mt-3">
            <div class="col-md-12">
              <!-- <button
                type="button"
                class="btn btn-secondary btn-sm me-2 px-5"
                @click="cancelPay"
              >
                {{ $t('booking.cancel') }}
              </button> -->
              <button
                type="button"
                class="btn btn-success btn-sm px-5"
                @click="confirmPay"
              >
                {{ $t('booking.paynow') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <petModel :width="'700px'" ref="petModel" :title="$t('pet.title2')">
        <pet-form
          @success="successAdd"
          @cancel="cancelAdd"
        />
    </petModel>
  </div>
</template>
<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import petForm from '@/pages/member/pet/pet-form';
import payBtns from '@/components/pay-btns';
import petModel from '@/components/model';
import loading from '@/components/loading';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

function loadEftScript() {
  const src = 'https://library-checkout.spiralplatform.com/js/v2/spiralpg.min.js';
  const id = 'eft-sdk';
  const exist = document.getElementById(id);
  if (exist) {
    return Promise.resolve(true);
  }
  const { head } = document;
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = function () {
      resolve(true);
    };
  });
}

export default {
  // eslint-disable-next-line
  name: 'easy-booking',
  components: {
    petForm,
    payBtns,
    petModel,
    VueCtkDateTimePicker,
  },
  data() {
    return {
      petList: [],
      record: '',
      price: '',
      totalPrice: '',
      discount: '0',
      voucher: '',
      dateTime: '',
      selectTime: '0',
      payType: 'paypal',
      sessionId: '',
      form: {
        consult_method: 'web',
        pet_kind_id: '',
        pet_id: null,
        pet_name: '',
        recheck: '0',
        support_language_id: ['8bfd003df06f6c6cd28153e00edfc93a', 'l037kaimdu394aereoyt075jsee56e', 'l039ldkiemg93m39gma9dimeod95'],
      },
      languageList: [
        {
          id: '8bfd003df06f6c6cd28153e00edfc93a', name_en: 'Cantonese', name_zh_TW: '粵語', name_zh_CN: '粤语',
        },
        {
          id: 'l037kaimdu394aereoyt075jsee56e', name_en: 'English', name_zh_TW: '英語', name_zh_CN: '英语',
        },
        {
          id: 'l039ldkiemg93m39gma9dimeod95', name_en: 'Mandarin', name_zh_TW: '普通話', name_zh_CN: '普通话',
        },
      ],
    };
  },
  watch: {
    dateTime: {
      handler(newData, oldData) {
        if (newData) {
          this.selectTime = '1';
        }
      },
    },
    selectTime: {
      handler(newData, oldData) {
        if (newData === '0') {
          this.dateTime = '';
        }
      },
    },
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768 ? 1 : 0;
    },
  },
  async created() {
    await loadEftScript();
  },
  props: {
    topPage: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  mounted() {
    if (this.$store.state.hasLogin == false) {
      // this.$router.push('/user/login');
    } else {
      this.getPetList();
      this.getInstantConnectPrice();
    }
  },
  methods: {
    successAdd() {
      this.$refs.petModel.hide();
      this.getPetList();
    },
    cancelAdd() {
      this.$refs.petModel.hide();
    },
    onShowModel() {
      this.$refs.petModel.show();
    },
    onPetSelect(pet) {
      this.form.pet_kind_id = pet.pet_kind_id;
      this.form.pet_name = pet.name;
    },
    goToBooking() {
      if (this.topPage) {
        this.$router.push('/booking');
      } else {
        this.$emit('changePageEvent', 'booking');
      }
    },
    getPetList() {
      this.$api.pet.getPetList().then((res) => {
        if (res.data.code == 200) {
          this.petList = res.data.data.list.reverse();
          if (this.petList.length) {
            this.form.pet_kind_id = this.petList[0].pet_kind_id;
            this.form.pet_id = this.petList[0].pet_id;
            this.form.pet_name = this.petList[0].name;
          }
        }
      });
    },
    getInstantConnectPrice() {
      this.$api.booking.getInstantConnectPrice({
        instant_connect: 1,
        voucher_code: this.voucher,
      }).then((res) => {
        if (res.data.code == 200) {
          // console.log( res.data.data.total_price - 0 );
          this.price = (res.data.data.total_price - 0).toFixed(2);
          this.totalPrice = (res.data.data.vet_total_price - 0).toFixed(2);
          this.discount = (res.data.data.voucher_amount - 0).toFixed(2);
        } else {
          this.$toast.show(res.data.msg);
        }
      });
    },
    paySelect(type) {
      this.payType = type;
    },
    confirmPay() {
      if (this.payType == 'paypal') {
        this.onClickPaypal();
      } else if (
        this.payType == 'alipay_hk'
       || this.payType == 'alipay_cn'
       || this.payType == 'vm'
       || this.payType == 'unionpay'
       || this.payType == 'mppay'
      ) {
        this.onEftClick(this.payType);
      }
    },
    cancelPay() {
      this.$router.push('/reserve');
    },
    async book() {
      if (!this.$store.state.hasLogin) {
        this.$router.push('/user/login');
        return;
      }

      // 订单已创建过
      if (this.record) { return; }
      if (!this.price) { return; }
      if (!this.form.pet_kind_id) { this.$toast.show(this.$t('booking.select_pet')); return; }
      let pet = this.petList.find((item) => item.pet_id === this.form.pet_kind_id);
      // console.log(pet)
      if (!this.form.pet_id) { this.$toast.show(this.$t('booking.select_pet')); return; }
      this.form.voucher_code = this.voucher;
      if (this.dateTime) {
        let [date, time] = this.dateTime.split(' ');
        this.form.date = date;
        this.form.time = time;
      }
      let res = await this.$api.booking.addInstantConnect(this.form).catch(() => null);
      if (res) {
        this.record = res.data.data;
      }
    },
    onApplyVoucher() {
      // if (!this.voucher) { this.$toast.show(this.$t('booking.input_voucher')); return; }
      this.getInstantConnectPrice();
    },
    async onClickPaypal() {
      await this.book();
      // if (this.record && this.record.booking_record_id) {
      if (this.record) {
        localStorage.setItem(`payStatus-${this.record}`, '1');
        let res = await this.$api.payment.createBookingPaypal({ booking_record_id: this.record }).catch(() => null);
        if (res && res.data && res.data.data) {
          if (typeof plus == 'object') {
            // eslint-disable-next-line
            uni.webView.navigateTo({ url: `/pages/webview/index?url=${res.data.data.url}` });
          } else {
            window.location.href = res.data.data.url;
          }
        }
      }
    },
    async onEftClick(payType) {
      await this.book();
      if (this.record) {
        localStorage.setItem(`payStatus-${this.record}`, '1');
        let type = 3;
        if (payType == 'alipay_cn') {
          type = 3;
        } else if (payType == 'alipay_hk') {
          type = 4;
        } else if (payType == 'mppay') {
          type = 5;
        } else if (payType == 'vm') {
          type = 6;
        } else if (payType == 'unionpay') {
          type = 7;
        }
        let res = await this.$api.payment.createBookingEft({
          id: this.record, in_app: this.isMobile, order_payment: 1, pay_type: type,
        });
        // console.log(res.data);
        if (res && res.data && res.data.code == 200) {
          try {
            loading.loading.show();
            // eslint-disable-next-line
              SpiralPG.init(res.data.data);
          } catch (err) {
            null;
          }
          this.eftPay(1);
        } else {
          loading.loading.hide();
          this.$toast.show(this.$t('payment.105'));
        }
      }
    },
    eftPay(times) {
      if (times > 15) {
        loading.loading.hide();
        return this.$toast.show(this.$t('payment.105'));
      }
      let globalThis = this;
      try {
        loading.loading.show();
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 1500);
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
          // globalThis.$toast.show(globalThis.$t('payment.104'));
        }, 3000);
        setTimeout(() => {
          // eft有时会很久才跳，其实已经成功了的
          loading.loading.hide();
        }, 5000);
      } catch (err) {
        times++;
        globalThis.eftPay(times);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.easy-booking{
  padding-bottom: 100px;
  .container{
    background-color: #ffffff;
    .title{
      cursor: pointer;
    }
    .switch-box{
      width: 320px;
      background-color: #eee;
      border-radius: 10px;
      padding: 2px;
    }
    .switch-btn{
      position: relative;
      padding: 4px;
      border-radius: 6px;
      display: block;
      text-align: center;

    }
    .active {
      background-color: #fff;
      color: green;
    }
    .active::before{
      content: '';
      // display: block;
      // width: 100%;
      // height: 4px;
      // background-color: green;
      // position: absolute;
      // bottom: -10px;
      // right: 0px;
    }
    hr{
      background-color: var( --bs-gray-600 ) ;
    }
    .btn-outline-dark:hover {
      color: #212529;
      background-color: var( --bs-gray-200 );
    }
  }
  .model{
    width: 80%;
    max-width: 600px;
    max-height: 100%;
    overflow-y: auto;
    z-index: 9;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  @media (max-width: 767.98px) {
    .sm-line-height{
        margin-top: -4px;
        margin-bottom: 10px;
        padding-left: 80px;
    }
    .model{
      width: 100%;
    }
    .switch-box{
      width: 100% !important;
    }
  }
}
</style>

<style>
@media screen and (max-width: 415px){

  .datetimepicker:not(.inline) .pickers-container[data-v-e6a80f26] {
      height: calc(100% - 180px) !important;
  }
}
.date-time-picker .field-label{
  position: absolute;
  top: 0px !important;
  cursor: pointer;
  left: 0px !important;
  transform: translateY(0) !important;
  opacity: 0;
  transition: all .25s cubic-bezier(.645,.045,.355,1);
  font-size: 11px;
  color: rgba(0,0,0,.54);
  width: 100% !important;
  height: 100% !important;
  padding-left: 12px;
  padding-top: 2px;
}
</style>
