<template>
    <div class="bg-dark py-3 text-center text-white">
      {{ $t('home.customer') }}  admin@bnbvet.com
      <div  class="mt-3"><a href="https://beian.miit.gov.cn/" target="_blank" style="color: var( --bs-gray-700 )"></a></div>
    </div>
</template>
<script>
// 页脚
export default {
  name: 'footer-tpl',
};
</script>
