<template>
  <div
    ref="content-page"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('reserve.cart.title2') }}
          </div>
        </div>
        <hr>
        <div class="content">
          <div class="row">
            <div class="fs-5 col-md-3 text-end row-header mb-3">
              {{ $t('reserve.cart.address') }}
            </div>
            <div class="btns col-md-9 text-end" >
              <button type="button" class="btn btn-primary" @click="onClickAddress"> {{ $t('reserve.cart.addAddress') }} </button>
            </div>
          </div>
          <div class="row ">
              <div class="col-md-3"></div>
              <div class="col-md-9 text-start" >
                <div class="address-list">
                  <div class="address-item" v-for="(item, index) in addressList" :key="index">
                    <input class="form-check-input address-radio" v-model="item.selected" type="radio" name="address-item" :value="true"/>
                    <div>
                      <span class="me-3">{{item.name}}</span><span>{{item.phone}}</span>
                      <span class="icon icon-write ms-3" @click="editAddress(index)"></span>
                      <span class="icon icon icon-delete ms-3" @click="delAddress(index)"></span>
                    </div>
                    <div>{{item.address}}</div>
                  </div>

                </div>
              </div>
            </div>
        <hr>
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.cart.orderinfo') }}
              </div>
            </div>
            <div class="row ">
              <div class="col-md-3"></div>
              <div
                v-if="product_list && product_list.length"
                class="col-md-9 text-start images"
              >
                <div class="medicine-list" v-for="(item ,index) in product_list" :key="index">
                  <input class="form-check-input check-input" type="checkbox" v-model="item.selected" :value="true" @change="onCheckChange"/>
                  <div class="medicine-image image">
                    <img :src="item.image_urls" alt="">
                  </div>
                  <div>
                    <div class="medicine-title">{{i18n(item, 'title')}}</div>
                    <div class="medicine-spec">{{$t('reserve.detail.quantity')}}：
                      <div class="input-minus" @click="onClickMinus(index)">-</div>
                      <div class="input-quantity">{{item.quantity}}</div>
                      <div class="input-plus" @click="onClickPlus(index)">+</div>
                    </div>
                    <div class="medicine-price text-success">${{item.discount_price}}</div>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <!-- <div class="row mb-3">
              <div class="col-md-3"></div>
                <div class="col-md-9 text-start ps-4">
                  {{$t('booking.voucher')}}：
                  <div class="position-relative" style="width: 200px;display: inline-block;">
                    <input type="text" class="form-control" v-model="voucher_code" id="voucher" @blur="onApplyVoucher"/>
                    <b
                    style="cursor: pointer;"
                    class="position-absolute end-0 top-0 mt-2 me-2 text-black-50 user-select-none"
                    @click="onApplyVoucher"
                  >{{ $t('booking.apply') }}</b>
                  </div>

              </div>
            </div> -->
            <div class="row ">
              <div class="col-md-3"></div>
                <div class="col-md-9 text-start ps-4"
                >
                {{$t('reserve.cart.total')}}：
                <span class="text-success">${{total}}</span>
                <span class="float-end">{{$t('reserve.cart.freight')}}：0</span>
              </div>
            </div>

          <div class="row g-2 text-start text-md-end">
            <label
              class="col-form-label col-md-2">
              {{ $t('booking.paytype') }}：
            </label>
            <div class="col-md-10 pt-md-1 text-start">
              <pay-btns @selected="paySelect" />
            </div>
          </div>
          <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-secondary btn-sm me-2 px-5"
                @click="cancelPay"
              >
                {{ $t('booking.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-success btn-sm px-5"
                @click="confirmPay"
              >
                {{ $t('reserve.cart.buynow') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <model ref="addressModel" :title="modelTitle">
      <div class="px-5">
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="exampleInputName" class="form-label">Name</label>
            <input type="text" class="form-control" v-model="form.name" id="exampleInputName">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="exampleInputPhone" class="form-label">Phone</label>
            <input type="text" class="form-control" v-model="form.phone" id="exampleInputPhone">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="exampleInputEmail" class="form-label">Email</label>
            <input type="text" class="form-control" v-model="form.email" id="exampleInputEmail">
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="exampleInputAddress" class="form-label">Address</label>
            <input type="text" class="form-control" v-model="form.address" id="exampleInputAddress">
          </div>
        </div>
      </div>

      <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-secondary btn-sm me-2 px-5"
            @click="cancelModel"
          >
            {{ $t('reserve.cart.cancel') }}
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm px-5"
            @click="confirmModel"
          >
            {{ $t('reserve.cart.save') }}
          </button>
        </div>
      </div>
    </model>
  </div>
</template>
<script>
import model from '@/components/model';
import payBtns from '@/components/pay-btns';
import loading from '@/components/loading';

function loadEftScript() {
  const src = 'https://sandbox-library-checkout.spiralplatform.com/js/v2/spiralpg.min.js';
  const id = 'eft-sdk';
  const exist = document.getElementById(id);
  if (exist) {
    return Promise.resolve(true);
  }
  const { head } = document;
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = function () {
      resolve(true);
    };
  });
}

export default {
  // eslint-disable-next-line
  name: 'member-reserve-confirm',
  components: {
    model,
    payBtns,
  },
  data() {
    return {
      booking_record_id: this.$route.query.booking_record_id,
      consult_record_id: this.$route.query.consult_record_id,
      product_list: [],
      detail: {},
      modelTitle: this.$t('reserve.cart.addAddress'),
      payType: 'paypal',
      sessionId: '',
      form: {
        name: '',
        phone: '',
        email: '',
        address: '',
      },
      addressList: [],
      total: 0,
      order: {},
      voucher_code: '',
      isConfirm: false,
      payId: '',
    };
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768 ? 1 : 0;
    },
  },
  async created() {
    await loadEftScript();
  },
  mounted() {
    this.getAddressList();
    this.getDetail();
    setTimeout(() => {
      this.countTotal();
    }, 200);
  },
  activated() {
    // this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api.booking.getBookingDetail({ id: this.booking_record_id }).then((res) => {
        if (res.data.code == 200) {
          this.detail = res.data.data;
          let product_list = [];
          if (typeof res.data.data.medicine_product_list == 'string') {
            product_list = JSON.parse(this.detail.medicine_product_list || []);
          } else {
            product_list = this.detail.medicine_product_list || [];
          }
          product_list.forEach((element) => {
            element.selected = true;
            element.quantity -= 0;
            element.stock -= 0;
            if (element.image_urls == null || !element.image_urls) {
              element.image_urls = require('@/assets/pages/drug.png');
            } else if (typeof element.image_urls == 'object') {
              element.image_urls = element.image_urls[0] || require('@/assets/pages/drug.png');
            }
          });
          this.product_list = product_list;
          this.countTotal();
        }
      });
    },
    onCheckChange() {
      this.countTotal();
    },
    onClickMinus(index) {
      if (this.product_list[index].quantity > 0) {
        this.product_list[index].quantity -= 1;
      }
      if (this.product_list[index].quantity == 0) {
        this.product_list[index].selected = false;
      }
      this.countTotal();
    },
    onClickPlus(index) {
      if (this.product_list[index].quantity < this.product_list[index].stock) {
        this.product_list[index].quantity += 1;
      }
      if (this.product_list[index].quantity > 0) {
        this.product_list[index].selected = true;
      }
      this.countTotal();
    },
    cancelPay() {
      this.$router.push(`/member/reserve/detail?booking_record_id=${this.booking_record_id}`);
    },
    confirmPay() {
      // this.$router.push(`/member/reserve/confirm?booking_record_id=${this.booking_record_id}`);
      let address = this.addressList.find((res) => res.selected == true);
      if (!address) {
        this.$toast.show(this.$t('reserve.cart.addressTip'));
        return;
      }
      this.order.booking_record_id = this.booking_record_id;
      this.order.consult_record_id = this.consult_record_id;
      this.order.shipping_name = address.name;
      this.order.shipping_email = address.email;
      this.order.shipping_phone = address.phone;
      this.order.address = address.address;
      this.order.voucher_code = this.voucher_code;
      this.order.detail_list = [];
      this.product_list.forEach((item) => {
        if (item.quantity > 0 && item.selected) {
          this.order.detail_list.push({ product_id: item.id, quantity: item.quantity });
        }
      });
      if (!this.isConfirm || !this.payId) {
        this.isConfirm = true;
        this.$api.cart.saleOrderAdd(this.order).then((res) => {
          if (res.data.code == 200) {
            this.payId = res.data.data.id;
            if (this.payType == 'paypal') {
              this.onClickPaypal(res.data.data.id);
            } else {
              this.onEftClick(res.data.data.id, this.payType);
            }
          } else {
            this.$toast.show(res.data.msg);
          }
        });
      } else if (this.payType == 'paypal') {
        this.onClickPaypal(this.payId);
      } else {
        this.onEftClick(this.payId, this.payType);
      }
    },
    async onClickPaypal(id) {
      if (id) {
        let res = await this.$api.cart.createOrderPaypal({ id }).catch(() => null);
        if (res && res.data && res.data.data && res.data.code == 200) {
          // console.log(res.data.data);
          // if (typeof plus == 'object') {
          //   // eslint-disable-next-line
          //   uni.webView.navigateTo({ url: `/pages/webview/index?url=${res.data.data.url}` });
          // } else {
          window.location.href = res.data.data.url;
          // }
        } else {
          this.$toast.show(res.data.msg);
        }
      }
    },
    cancelModel() {
      this.$refs.addressModel.hide();
    },
    confirmModel() {
      this.$api.cart.addressStore(this.form).then((res) => {
        if (res.data.code == 200) {
          this.$toast.show({ title: this.$t('tips'), content: this.$t('reserve.cart.saveSuccess') });
          this.getAddressList();
        } else {
          this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
        }
        this.$refs.addressModel.hide();
      }).catch((res) => {
        this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
        this.$refs.addressModel.hide();
      });
    },
    onClickAddress() {
      this.modelTitle = this.$t('reserve.cart.addAddress');
      delete this.form.id;
      this.form.name = '';
      this.form.phone = '';
      this.form.email = '';
      this.form.address = '';
      this.$refs.addressModel.show();
    },
    editAddress(index) {
      this.modelTitle = this.$t('reserve.cart.editAddress');
      this.form = this.addressList[index];
      this.$refs.addressModel.show();
    },
    delAddress(index) {
      this.$toast.show({
        title: this.$t('tips'),
        content: `${this.$t('reserve.cart.confirmDel')}?`,
        confirm: this.$t('reserve.cart.confirm'),
        close: this.$t('reserve.cart.cancel'),
        autohide: false,
      }).then((_) => {
        let address = this.addressList[index];
        this.$api.cart.addressDelete({ id: address.id }).then((res) => {
          if (res.data.code == 200) {
            this.getAddressList();
          }
        });
      });
    },
    getAddressList() {
      this.$api.cart.addressList().then((res) => {
        if (res.data.code == 200) {
          res.data.data.list.forEach((element) => {
            element.selected = false;
          });
          this.addressList = res.data.data.list;
          this.addressList[this.addressList.length - 1].selected = true;
        }
      });
    },
    countTotal() {
      let total = 0;
      this.product_list.forEach((item) => {
        if (item.selected && item.quantity > 0) {
          total += (item.discount_price - 0) * item.quantity;
        }
      });
      this.total = total;
    },
    onApplyVoucher() {
      this.getSaleOrderPrice();
    },
    getSaleOrderPrice() {
      let list = [];
      this.product_list.forEach((item) => {
        list.push({ product_id: item.id, quantity: item.quantity });
      });
      this.$api.cart.getSaleOrderPrice({
        detail_list: list,
        voucher_code: this.voucher_code,
      }).then((res) => {
        if (res.data.code == 200) {
          // console.log( res.data.data.total_price - 0 );
          this.total = (res.data.data.total_price - 0).toFixed(2);
        } else {
          this.$toast.show(res.data.msg);
        }
      });
    },
    paySelect(type) {
      this.payType = type;
    },
    async onEftClick(id, payType) {
      if (id) {
        localStorage.setItem(`payStatus-${id}`, '1');
        let type = 3;
        if (payType == 'alipay_cn') {
          type = 3;
        } else if (payType == 'alipay_hk') {
          type = 4;
        } else if (payType == 'mppay') {
          type = 5;
        } else if (payType == 'vm') {
          type = 6;
        } else if (payType == 'unionpay') {
          type = 7;
        }
        let res = await this.$api.payment.createBookingEft({
          id, in_app: this.isMobile, order_payment: 2, pay_type: type,
        }).catch(() => null);
        // console.log(res.data);
        if (res && res.data && res.data.code == 200) {
          try {
            loading.loading.show();
            // eslint-disable-next-line
              SpiralPG.init(res.data.data);
          } catch (err) {
            null;
          }
          this.eftPay(1);
        } else {
          loading.loading.hide();
          this.$toast.show(this.$t('payment.105'));
        }
      }
    },
    eftPay(times) {
      if (times > 15) {
        loading.loading.hide();
        return this.$toast.show(this.$t('payment.105'));
      }
      let globalThis = this;
      try {
        loading.loading.show();
        // eslint-disable-next-line
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 1500);
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
          // globalThis.$toast.show(globalThis.$t('payment.104'));
        }, 3000);
        setTimeout(() => {
          // eft有时会很久才跳，其实已经成功了的
          loading.loading.hide();
        }, 5000);
      } catch (err) {
        setTimeout(() => {
          times++;
          globalThis.eftPay(times);
        }, 1500);
      }
    },
    // getDetail() {
    //   this.$api.booking.getBookingDetail({ id: this.booking_record_id }).then((res) => {
    //     if (res.data.code == 200) {
    //       this.detail = res.data.data;
    //       if (typeof res.data.data.medicine_product_list == 'string') {
    //         this.product_list = JSON.parse(this.detail.medicine_product_list || []);
    //       } else {
    //         this.product_list = this.detail.medicine_product_list || [];
    //       }
    //     }
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  color: var( --bs-gray-600 );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  @media (max-width: 767.98px) {

    .row-header{
      text-align: left !important;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
      .content{
        padding: 20px 10px;
        .images{
          img{
            width: 100%;
          }

        }
        .medicine-list{
          position: relative;
          padding-left: 100px;
          margin-bottom: 40px;
          .check-input{
            position: absolute;
            left: 0px;
            top: 40%;
          }
          .medicine-image{
            background-color: #efefef;
            width: 70px;
            height: 70px;
            position: absolute;
            left: 20px;
          }
          .medicine-title{
            font-size: 18px;
          }
          .input-quantity{
            width:50px;
            height: 26px;
            // border: 1px solid #999;
            font-size: 18px;
            line-height: 1.2;
            display:inline-block;
            text-align: center;
            user-select: none;
          }
          .input-minus, .input-plus{
            display:inline-block;
            width: 30px;
            height: 26px;
            text-align: center;
            border: 1px solid #999;
            vertical-align: top;
            border-radius: 4px;
            font-size: 18px;
            line-height: 1.2;
            cursor: pointer;
            user-select: none;
          }
        }
        .address-list{
          .address-item{
            position: relative;
            padding-left: 40px;
            .address-radio{
              position: absolute;
              left: 0px;
              top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
