<template>
  <div style="padding-bottom: 100px;">
    <div class="top-tools">
      <span class="lang-btn" v-if="$i18n.locale == 'en'" @click="onChangeLang()">中文</span>
      <span class="lang-btn" v-else @click="onChangeLang()">ENG</span>
      <!-- <img
        :src="require('@/assets/navbar/lang-light.png')"
        alt=""
        width="18"
        height="18"
        class="d-inline-block align-text-center lang"
        @click="onChangeLang()"
      > -->
      <!-- <i class="icon icon-info" @click="onNavClick('/about')"></i> -->
      <i class="icon icon-notice" @click="onNavClick('/member/notice')"></i>
      <!-- <i class="icon icon-my" @click="onNavClick('/member')"></i> -->
    </div>
    <banner-tpl
      :bg="require(smallScreen ? '@/assets/banner/2-m.png' : '@/assets/banner/2.png')"
      :title="$t('about.banner.title')"
      :content="$t('about.banner.content')"
      :btnText="$t('about.banner.btn1')"
    /> <!-- banner -->
    <div class="container-md py-3 text-left d-flex">
      <div class="text-center py-5 satisfaction">
        <div class="fs-1">98%</div>
        <div class="fs-6">{{ $t('about.content.satisfaction') }}</div>
      </div>
      <div class="text-left">
        <h1 class="text-left py-3 px-md-5">
          {{ $t('about.content.title') }}
        </h1>
        <div class="py-3 px-md-5">
          <div class="fs-6">{{ $t('about.content.content1') }}</div>
        </div>
        <div class="py-3 px-md-5">
          <div class="fs-6">{{ $t('about.content.content2') }}</div>
        </div>
      </div>
    </div>
    <!-- <divider-tpl /> --> <!-- 分隔线 -->
    <!-- <process-tpl /> --> <!-- 流程 -->

    <div class="container-md py-5  px-4 process">
        <div class="row px-md-5 process-item pc">
          <h1 class="text-left py-md-3">
            {{ $t('home.process.title') }}
          </h1>
          <div class="col-md p-4 text-center col-item process-name">
            <div class="process-name-box"><div>{{ $t('booking.title') }}</div></div>
            <div class="process-name-box"><div>{{ $t('booking.title2') }}</div></div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>01</div>
            </div>
            <div  class="process-box">
              <div class="process-head">{{ $t('home.process.list.1.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.3.body') }}</div>
            </div>
            <div  class="process-box">
              <div class="process-head">{{ $t('home.process.list.0.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.0.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>02</div>
            </div>
            <div  class="process-box">
              <div class="process-box-line">
                <div class="process-box-line-dot">&emsp;</div>
                <div class="process-box-line-line"></div>
                <div class="process-box-line-dot">›</div>
              </div>
              <div class="process-head">{{ $t('home.process.list.4.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.4.body') }}</div>
            </div>
            <div  class="process-box">
              <div class="process-box-line">
                <div class="process-box-line-dot">&emsp;</div>
                <div class="process-box-line-line"></div>
                <div class="process-box-line-dot">›</div>
              </div>
              <div class="process-head">{{ $t('home.process.list.1.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.1.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>03</div>
            </div>
            <div  class="process-box" style="margin-top: 80px;">
              <div class="process-box-line">
                <div class="process-box-line-dot">&emsp;</div>
                <div class="process-box-line-line"></div>
                <div class="process-box-line-dot">›</div>
              </div>
              <div class="process-head">{{ $t('home.process.list.2.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.2.body') }}</div>
            </div>
          </div>
        </div>

        <div class="row px-md-5 process-item mobile">
          <h1 class="text-left py-md-3">
            {{ $t('home.process.title') }}
          </h1>
          <div class="col-md text-center col-item process-name">
            <div class="process-name-box"><div>{{ $t('booking.title') }}</div></div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>01</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.1.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.3.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>02</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.4.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.4.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>03</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.2.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.2.body') }}</div>
            </div>
          </div>

          <div class="col-md text-center col-item process-name">
            <div class="process-name-box"><div>{{ $t('booking.title2') }}</div></div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>01</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.0.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.0.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>02</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.1.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.1.body') }}</div>
            </div>
          </div>
          <div class="col-md p-4 text-center col-item">
            <div class="process-title">
              <div>03</div>
            </div>
            <div>
              <div class="process-head">{{ $t('home.process.list.2.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.2.body') }}</div>
            </div>
          </div>

        </div>
        <div class="mt-5 banner-content-btns text-center">
          <button
            type="button"
            class="btn btn-choose"
            @click="onClick"
          >
            {{ $t('home.why_choose.btn') }}
          </button>
        </div>
    </div>
    <reserve-tpl /> <!-- 预约 -->
    <div class="container-md py-5  px-4 text-left" v-if="media_interview">
      <h1 class="text-left py-3 px-md-5">
        {{ $t('about.content.media_interview') }}
      </h1>
      <div class="py-3 px-md-5">
        <div v-html="media_interview"></div>
      </div>
    </div>
    <div class="container-md py-5  px-4 text-left" v-if="cooperation_list">
      <h1 class="text-left py-3 px-md-5">
        {{ $t('about.content.cooperation_list') }}
      </h1>
      <div class="py-3 px-md-5">
        <pre>{{ cooperation_list }}</pre>
      </div>
    </div>
    <subscribe-tpl /> <!-- 订阅 -->
    <footer-tpl /> <!-- 页脚 -->
  </div>
</template>
<script>
import bannerTpl from '@/components/banner';
import dividerTpl from '@/components/divider';

import processTpl from '@/components/process';
import reserveTpl from '@/components/reserve';
import subscribeTpl from '@/components/subscribe';
import footerTpl from '@/components/footer';

export default {
  // eslint-disable-next-line
  name: 'about-index',
  components: {
    bannerTpl,
    dividerTpl,
    processTpl,
    reserveTpl,
    subscribeTpl,
    footerTpl,
  },
  data() {
    return {
      media_interview: '',
      cooperation_list: '',
    };
  },
  computed: {
  },
  mounted() {
    this.getSite();
  },
  methods: {
    onClick() {
      this.$router.push('/reserve');
    },
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
    },
    onNavClick(path) {
      this.$router.push(path);
    },
    getSite() {
      this.$api.site.getSite({
        domain: process.env.NODE_ENV == 'production' ? process.env.VUE_APP_BASE_API.slice(8) : 'www.bnbvet.com',
      }).then((res) => {
        if (res && res.data && res.data.data && res.data.data.info) {
          // console.log(res);
          this.media_interview = res.data.data.info.media_interview;
          this.cooperation_list = res.data.data.info.cooperation_list;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.satisfaction{
  width: 280px;
}
.process{
  color: #fff;
  .pc{
    display: flex;
    .process-name{
      padding: 134px 0px 0px 0px !important;
    }
  }
  .mobile{
      display: none;
    }
  .process-item{
    background-color: #4EB29E;
    padding: 70px 70px 120px 70px;
    border-radius: 16px;
    .process-name{
      padding: 134px 0px 0px 0px ;
      flex: 0 0 6%;
      .process-name-box{
        height: 100px;
        width: 100%;
        margin-bottom: 20px;
        div{
          height: 100px;
          width: 100%;
          line-height: 1;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
    .process-title{
      div{
        font-size: 32px;
        color: #fff;
        font-family: Bangla MN;
        width: 90px;
        height: 90px;
        line-height: 104px;
        border-radius: 50%;
        margin: 0 auto 20px auto;
        background: rgba(247,247,247,0.0400);
        border: 1px solid #5bbeab;
      }
    }
    .process-box{
      border: 1px solid #5bbeab;
      border-radius: 10px;
      height: 96px;
      margin-bottom: 20px;
      padding: 10px;
      position: relative;
      .process-box-line{
        position: absolute;
        left: -56px;
        top: 36px;
        .process-box-line-dot{
          width: 15px;
          height: 15px;
          line-height: 14px;
          text-align: center;
          font-size: 12px;
          border-radius: 50%;
          background-color: #fff;
          border: 1px solid #5bbeab;
          display: inline-block;
          color: #333;
        }
        .process-box-line-line{
          display: inline-block;
          width: 34px;
          border-top: 2px solid #5bbeab;
          position: relative;
          top: -5px;
        }
      }
      .process-head{
        font-size: 22px;
      }
    }

  }
  .btn-choose{
    margin-top: 20px;
    border: 1px solid #333333;
    border-radius: 2rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    color: #333333;
  }

}

@media (max-width: 767.98px) {
  .satisfaction{
    width: 680px;
  }
  .process{
    .pc{
      display: none !important;
    }
    .mobile{
      display: block !important;
    }

    &-item{
      padding: 20px 20px 50px 20px  !important;
    .process-name{
      padding: 30px 0px 0px 0px !important;
      .process-name-box{
        height: 20px  !important;
        div{
          height: 20px  !important;
        }
      }
    }
      .p-4{
        padding: 4px !important;
      }
      .col-item{
        display: flex;
        .process-title{
          margin-right: 20px;
          div{
            font-size: 22px !important;
            width: 50px !important;
            height: 50px !important;
            line-height: 58px !important;
          }
        }
        .process-head,
        .process-body{
          text-align: left;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
  .top-tools{
    // background-color: #EFEFEF;
    display: none;
    position: absolute;
    z-index: 2;
  }
  @media (max-width: 767.98px) {
    .top-tools{
      // background-color: #EFEFEF;
      display: block;
      width: 100%;
      height: 70px;
      text-align: right;
      padding: 10px 20px;
      .icon{
        font-size: 22px;
        margin-left: 10px;
      }
      .lang{
        vertical-align: top;
        margin-top: 4px;
      }

    }
  }
  </style>
