<template>
  <div
    ref="content-page"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="content">
            <div class="row ">
              <div class="col-md-12 text-start">
                <div class="menu-list"  @click="onNavClick('/member/reserve')"><i class="icon icon-dingdan2 me-2"></i>{{$t('navbar.myreserve')}}</div>
                <div class="menu-list"  @click="onNavClick('/member/info')"><i class="icon icon-profilefill me-2"></i>{{$t('navbar.myinfo')}}</div>
                <div class="menu-list"  @click="onNavClick('/member/pet')"><i class="icon icon-atm me-2"></i>{{$t('navbar.mypet')}}</div>
                <div class="menu-list"  @click="onNavClick('/member/notice')"><i class="icon icon-notice me-2"></i>{{$t('navbar.notice')}}</div>
              </div>
            </div>
            <hr />
            <div class="row ">
              <div class="col-md-12 text-start">
                <!-- <div class="menu-list"  @click="onNavClick('/expert')"><i class="icon icon-heilongjiangtubiao11 me-2"></i>{{$t('navbar.expert')}}</div> -->
                <div class="menu-list"  @click="onNavClick('/service')"><i class="icon icon icon-zuanshi me-2"></i>{{$t('navbar.service')}}</div>
                <!-- <div class="menu-list"  @click="onNavClick('/contactus')"><i class="icon icon icon-servicefill me-2"></i>{{$t('navbar.contact')}}</div> -->
                <div class="menu-list"  @click="onNavClick('/about')"><i class="icon icon icon-info me-2"></i>{{$t('navbar.about')}}</div>
              </div>
            </div>
            <hr />
            <div class="row ">
              <div class="col-md-12 text-start">
                <div class="menu-list"  @click="onNavClick('/reserve')"><i class="icon icon-qiandao1 me-2"></i>{{$t('navbar.reserve')}}</div>
              </div>
            </div>
            <hr />
            <div class="row ">
              <div class="col-md-12 text-start">
                <img
                  :src="require('@/assets/navbar/lang-light.png')"
                  alt=""
                  width="17"
                  height="17"
                  class="d-inline-block align-text-center lang  me-2"
                  @click="onChangeLang()"
                  style="width: 17px;height:17px;padding:0px;margin:0px;cursor: pointer;"
                > <span style="cursor: pointer;" @click="onChangeLang()">{{ $t('tabbar.lang') }}</span>
              </div>
            </div>
            <hr />
            <div class="row ">
              <div class="col-md-12 text-start">
                <i style="cursor: pointer;" class="icon icon-fanhuishangcheng me-2" @click="onLogout()"></i>
                <span style="cursor: pointer;" @click="onLogout()">{{ $t('navbar.logout') }}</span>
              </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'member-index',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {

  },
  mounted() {
    if (this.$store.state.hasLogin == false) {
      this.$router.push('/user/login');
    } else if (this.$store.state.user.phone) {
      this.$store.state.user.mobile = this.$store.state.user.info.phone;
    }
  },
  methods: {
    onNavClick(path) {
      this.$router.push(path);
    },
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
      localStorage.setItem('client-language', this.$i18n.locale);
    },
    onLogout() {
      this.$toast.show({
        title: this.$t('tips'),
        content: `${this.$t('login.logout')}?`,
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then((_) => {
        this.$store.dispatch('setUserInfo', {});
        this.$router.push('/user/login');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  color: var( --bs-gray-600 );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  @media (max-width: 767.98px) {

    .row-header{
      text-align: left !important;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      .content{
        padding: 20px;
        .menu-list{
          padding: 6px 0px;
          cursor: pointer;
        }
      }
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
    }
  }
}
</style>
