<template>
    <div class="position-fixed end-0 top-50 me-3 tools" style="z-index: 999999;">
      <div class="box-2 p-0" style="background-color: #5cc758;overflow: hidden;" @click="onClickContact">
        <img src="@/assets/pages/service.png" style="width: 96%;padding:4px">
        <!-- <div class="popover bs-popover-auto fade show" role="tooltip" id="popover31336" data-popper-placement="left"
            style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(-60px, 0px);">
          <div class="popover-arrow" style="position: absolute; transform: translate(0px, 19px); top: 0px;"></div>
          <div class="popover-body" style="width: 100px;">{{$t('hotline')}}<br/></div>
        </div> -->
      </div>
      <div class="box-1" @click="onClickReserve">
        {{$t('navbar.reserve')}}
      </div>
      <div class="box-3" @click="onClickToTop">
        <img src="@/assets/pages/02.png" alt="">
      </div>

    </div>
</template>
<script>
export default {
  name: 'footer-tpl',
  methods: {
    onClickReserve() {
      this.$router.push('/reserve');
    },
    onClickToTop(target) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    onClickWhatsapp() {
      let url = 'https://wa.me/85259897756';
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          // console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    onClickContact() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$router.push('/contactus');
    },
  },
};
</script>
<style lang="scss" scoped>
  @media (max-width: 767.98px) {
    .tools{
      .box-1, .box-3{
        display: none;
      }
      .box-2{
        position: absolute;
        top: 120px;
        right: 0px;
      }
    }
  }
  .box-1, .box-2, .box-3{
    background-color: #225164;
    width: 50px;
    height: 48px;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    margin-bottom: 8px;
    user-select: none;
    z-index: 9;
    position: relative;
    font-size: 0.82rem;
  }
  .box-3{
    background-color: #5FA99A;
  }
  .popover{
    display: none;
  }
  .box-2:hover .popover{
    display: block;
  }
  .zh_TW, .zh_CN{
    .box-1, .box-2, .box-3{
      padding: 8px;
    }
  }
  .en{
    .box-1, .box-2, .box-3{
      padding: 8px 0;
    }
  }
</style>
