<template>
<div>
  <nav :class="['navbar', 'navbar-expand-md', theme]" v-if="showBar">
    <div class="container-md navbar-content">
      <a
        class="navbar-brand"
        href="javascript:void(0)"
        @click="onNavClick('/')"
      >
        <img
          :src="logo"
          alt=""
          width="79"
          height="34"
          class="d-inline-block align-text-center"
        >
        <!-- {{$t('navbar.index')}} -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarNav"
        class="collapse navbar-collapse"
        ref="navbarNav"
      >
        <ul class="navbar-nav flex-grow-1">
          <li
            v-for="(item, index) in nav"
            :key="index"
            class="nav-item"
          >
            <a
              :class="['nav-link',item.active && 'active', (item.path == '/reserve' && !smallScreen) && 'reserve']"
              href="javascript:void(0)"
              @click="onNavClick(item.path)"
            >{{ item.name }}</a>
          </li>
        </ul>

        <ul :class="['navbar-nav', 'member-nav', showNav]"  v-if="$store.state.hasLogin">
          <div class="member text-center">
            <div class="member-header m-auto overflow-hidden">
              <img
                :src="avatar"
                class="member-header-img img-fluid"
              >
            </div>
            <div class="member-name">
              {{ $store.state.user.name }}
            </div>
          </div>
          <li
            v-for="(item, index) in nav2"
            :key="index"
            class="nav-item"
          >
            <a
              :class="['nav-link',item.active && 'active']"
              href="javascript:void(0)"
              @click="onNavClick(item.path)"
            >
              <img
                :src="item.img"
                alt=""
                width="17"
                height="16"
                class="d-inline-block align-text-center me-2"
              >
              {{ item.name }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="onLogout()"
            ><img
              src="@/assets/navbar/logout.png"
              alt=""
              width="17"
              height="17"
              class="d-inline-block align-text-center me-2"
            >{{ $t('navbar.logout') }}</a>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="onChangeLang()"
            >
            <span class="lang-btn" v-if="$i18n.locale == 'en'">中文</span>
            <span class="lang-btn" v-else>ENG</span>
              <!-- <img
                :src="lang"
                alt=""
                width="17"
                height="17"
                class="d-inline-block align-text-center"
              > -->
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              href="#"
            >
              <img
                :src="store"
                alt=""
                width="17"
                height="17"
                class="d-inline-block align-text-center"
              >
            </a>
          </li> -->
          <li class="nav-item md-hide">
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="onNavClick('/member/notice')"
            >
              <img
                :src="remind"
                alt=""
                width="17"
                height="17"
                class="d-inline-block align-text-center"
              >
            </a>
          </li>
          <li
            v-if="!$store.state.hasLogin"
            class="nav-item"
          >
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="onNavClick('/user/login')"
            >{{ $t('navbar.login') }}</a>
          </li>
          <li
            v-if="!$store.state.hasLogin"
            class="nav-item"
          >
            <a
              class="nav-link"
              href="javascript:void(0)"
              @click="onNavClick('/user/signup')"
            >{{ $t('navbar.signup') }}</a>
          </li>
          <li
            v-if="$store.state.hasLogin"
            class="nav-item ms-2 md-hide"
            @click="onNavClick('/member/reserve')"
          >
            <div
              class="rounded-circle d-inline-block overflow-hidden"
              style="width: 38px;height: 38px;vertical-align: middle;background-color: rgba(0,0,0,0.1);"
            >
              <img
                :src="avatar"
                style="width: 38px;height: 38px;object-fit: cover;"
              >
            </div>
            <a
              class="nav-link d-inline-block"
              href="javascript:void(0)"
            >{{ $store.state.user.name }}</a>
          </li>
        </ul>
      </div>
    </div>

  </nav>
  <tools v-if="$route.path != '/meeting' && $route.path != '/expert/detail'"></tools>
  <div class="tabbar" v-if="showBar">
    <div class="tabbar-info">
      <div :class="['tabbar-item', $route.path ==  '/' && 'active']" @click="onNavClick('/')">
        <i class="icon icon-home1"></i>
        {{$t('tabbar.home')}}
      </div>
      <div :class="['tabbar-item', $route.path ==  '/expert' && 'active']" @click="onNavClick('/expert')">
        <i class="icon icon-fenxiao"></i>
        {{$t('tabbar.doctor')}}
      </div>
      <div :class="['tabbar-item', $route.path ==  '/member' && 'active']" @click="onNavClick('/member')">
        <div class="center-radius">
          <i class="icon icon-huiyuan"></i>
        </div>
        <i class="icon icon-huiyuan"></i>
        {{$t('tabbar.member')}}
      </div>
      <div :class="['tabbar-item', $route.path ==  '/member/pet' && 'active']" @click="onNavClick('/member/pet')">
        <i class="icon icon-atm"></i>
        {{$t('tabbar.pets')}}
      </div>
      <div :class="['tabbar-item', $route.path ==  '/member/reserve' && 'active']" @click="onNavClick('/member/reserve')">
        <i class="icon icon-calendar"></i>
        {{$t('tabbar.bookings')}}
      </div>
    </div>
  </div>
</div>
</template>
<script>
import tools from '@/components/tools';

export default {
  // eslint-disable-next-line
  name: 'NavBar',
  components: {
    tools,
  },
  data() {
    return {
      theme: 'navbar-dark',
      showNav: 'hide-nav',
      showBar: true,
    };
  },
  computed: {
    nav() {
      return [
        { path: '/', name: this.$t('navbar.home'), active: false },
        { path: '/expert', name: this.$t('navbar.expert'), active: false },
        { path: '/service', name: this.$t('navbar.service'), active: false },
        // { path: "/petstory", name: this.$t('navbar.petstory'), active: false },
        // { path: "/media", name: this.$t('navbar.media'), active: false },
        { path: '/about', name: this.$t('navbar.about'), active: false },
        // { path: '/contactus', name: this.$t('navbar.contact'), active: false },
        { path: '/reserve', name: this.$t('navbar.reserve'), active: false },
      ];
    },
    nav2() {
      return [
        {
          path: '/member/reserve', name: this.$t('navbar.myreserve'), img: require('@/assets/navbar/reserve.png'), active: false,
        },
        {
          path: '/member/info', name: this.$t('navbar.myinfo'), img: require('@/assets/navbar/info.png'), active: false,
        },
        {
          path: '/member/pet', name: this.$t('navbar.mypet'), img: require('@/assets/navbar/pet.png'), active: false,
        },
        {
          path: '/member/notice', name: this.$t('navbar.notice'), img: require('@/assets/navbar/notice.png'), active: false,
        },
      ];
    },
    logo() {
      return this.theme == 'navbar-light' ? require('@/assets/navbar/navbar-light.png') : require('@/assets/navbar/navbar-dark.png');
    },
    lang() {
      return this.theme == 'navbar-light' ? require('@/assets/navbar/lang-light.png') : require('@/assets/navbar/lang-dark.png');
    },
    store() {
      return this.theme == 'navbar-light' ? require('@/assets/navbar/store-light.png') : require('@/assets/navbar/store-dark.png');
    },
    remind() {
      return this.theme == 'navbar-light' ? require('@/assets/navbar/remind-light.png') : require('@/assets/navbar/remind-dark.png');
    },
    avatar() {
      return this.$store.state.user.avatar || require('@/assets/navbar/user-fill.png');
    },
  },
  watch: {
    '$route.path': {
      handler(newer, older) {
        this.setActive(newer);
      },
      deep: true,
    },
  },
  mounted() {

  },
  methods: {
    onNavClick(path) {
      this.$router.push(path);
      this.$refs.navbarNav.classList.remove('show');
      // console.log(this.$route.path);
    },
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
      localStorage.setItem('client-language', this.$i18n.locale);
    },
    onLogout() {
      this.$toast.show({
        title: this.$t('tips'),
        content: `${this.$t('login.logout')}?`,
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then((_) => {
        this.$refs.navbarNav.classList.remove('show');
        this.$store.dispatch('setUserInfo', {});
        this.$router.push('/user/login');
      });
    },
    setActive(path) {
      this.theme = 'navbar-dark';
      this.nav.map((item) => {
        if ((item.path != '/' && path.indexOf(item.path) == 0) || path == '/') {
          item.active = true;
          this.theme = 'navbar-light';
        } else {
          item.active = false;
        }
        // 不显示导航的页面
        if (path.indexOf('/meeting') == 0 || path.indexOf('/expert/detail') == 0) {
          this.showBar = false;
        } else {
          this.showBar = true;
        }
        return item;
      });
      this.showNav = 'hide-nav';
      this.nav2.map((item) => {
        if (item.path != '/' && path.indexOf(item.path) == 0 && path != '/') {
          item.active = true;
          this.showNav = 'show-nav';
          if (this.$store.state.hasLogin == false) {
            // this.$router.push('/user/login');
          }
        } else {
          item.active = false;
        }
        return item;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabbar{
  display: none;
}
.navbar{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.1rem;
}
.navbar-light{
  background-color: #EFEFEF;
  .navbar-content{
    position: relative;

    .nav-item{
      margin-right: 10px;
      text-align: center;
      .nav-link{
        padding: 6px 8px;
        display: inline-block;
      }
      .reserve{
        background-color: #225164;
        color:#ffffff;
        border-radius: 4px;
      }

    }
    @media (min-width: 767.98px) {
      .member-nav{
        display: none !important;
      }
    }
    @media (max-width: 767.98px) {
      .member-nav{
        img{
          display: none !important;
        }
        .member{
          display: none;
        }
      }
      .md-hide{
        display: none;
      }
    }
    .navbar-brand{
      position: relative;
      top: 6px;
    }
  }
}
.navbar-dark{
  background-color: #514D48;

  .navbar-content{
    position: relative;
    .nav-item{
      margin-right: 10px;
      text-align: center;
      .nav-link{
        padding: 6px 8px;
        display: block;
      }

    }
    @media (min-width: 767.98px) {
      .member-nav{
        display: block !important;
        height: calc( 100vh - 5.5rem );
        width: 160px;
        padding: 20px 0px;
        position: absolute;
        left: 0px;
        top: 70px;
        background-color: #514D48;
        border-radius: 4px;
        font-size: 0.82rem;
        text-align: left;
        .nav-link{
          padding-left: 16px;
        }
        .active{
          background: #5FA99A;
        }
        .member{
          display: block;
          .member-header{
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: rgba(0,0,0,0.1);
            img{
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
          }
          .member-name{
            color: #ffffff;
            line-height: 3;
          }
        }
      }
      .show-nav{
        display: block !important;
      }
      .hide-nav{
        display: none !important;
      }
    }

    @media (max-width: 767.98px) {
      .member-nav{
        font-size: 0.82rem;
        img{
          display: none !important;
        }
        .member{
          display: none;
        }
      }
      .md-hide{
        display: none;
      }
    }
    .navbar-brand{
      position: relative;
      top: 6px;
    }
  }
}
@media (max-width: 767.98px) {
  .tabbar{
    display: block;
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 70px;
    box-shadow: 0px 0px 4px 2px #00000020;
    background-color: #fff;
    z-index: 999999999999999999;
    .tabbar-info{
      display: flex;
      .tabbar-item{
        flex: 1;
        text-align: center;
        padding-top: 14px;
        position: relative;
        cursor: pointer;
        .icon{
          display: block;
          font-size: 20px;
        }
      }
      .active{
        color: #5FA99A !important;
        .center-radius{
          background-color: #5FA99A;
          .icon{
            color: #fff !important;
          }
        }
      }
    }
    .center-radius{
      width: 60px;
      height: 60px;
      background-color: #fff;
      // border: 1px solid #efefef;
      border-radius: 50%;
      position: absolute;
      top: -24px;
      left: 50%;
      margin-left: -30px;
      font-size: 40px;
      line-height: 60px;
      box-shadow: 0px -2px 4px 2px #00000020;
      .icon{
          display: block;
          font-size: 40px !important;
          color: #999;
        }
    }
  }
  .navbar{
    display: none;
  }
}
</style>
