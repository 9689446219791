<template>
  <div class="expert-page">
    <div class="top-tools">
      <span class="lang-btn" v-if="$i18n.locale == 'en'" @click="onChangeLang()">中文</span>
      <span class="lang-btn" v-else @click="onChangeLang()">ENG</span>
      <!-- <img
        :src="require('@/assets/navbar/lang-light.png')"
        alt=""
        width="18"
        height="18"
        class="d-inline-block align-text-center lang"
        @click="onChangeLang()"
      > -->
      <!-- <i class="icon icon-info" @click="onNavClick('/about')"></i> -->
      <i class="icon icon-notice" @click="onNavClick('/member/notice')"></i>
      <!-- <i class="icon icon-my" @click="onNavClick('/member')"></i> -->
    </div>
    <banner-tpl
      :bg="require(smallScreen ? '@/assets/banner/3-m.png' : '@/assets/banner/3.png')"
      :title="$t('expert.banner.title')"
      :content="$t('expert.banner.content')"
      :btnText="$t('expert.banner.btn1')"
      textColor="1"
    /> <!-- banner -->
    <div class="container-md py-3 text-left">
      <h1 class="text-left py-3">
        {{ $t('expert.title') }}
      </h1>
      <div class=" fs-5">
        <!-- <div>{{ $t('expert.content1') }}</div>
        <div>{{ $t('expert.content2') }}</div> -->
      </div>
      <div class="py-md-5" style="min-height: 700px;">
        <div class="row px-2 border-bottom mb-4 py-4 expert-team" v-for="detail in doctorList" :key="detail">
          <div class="col-md-3 col-avatar">
            <img :src="detail.avatar_url" class="rounded img-fluid avatar">
          </div>
          <div class="col-md-9 pt-2">
            <div class="name fs-4">
              <span>{{ i18n(detail, 'name') }}</span>
              <!-- <img :src="require('@/assets/pages/16px-QR_icon.svg.png')" class="img-fluid detail-qr" @click="onNavClick('/expert/detail?id='+ detail.id)"> -->
            <button type="button" class="btn btn-sm btn-outline-dark reserve-btn" @click="onClickReserve(detail.id)" >
              {{ $t('reserve.online') }}
            </button>
            </div>
            <div class="detail p-1" v-if="detail.field_info.length">
              {{$t('booking.table.interest')}}:
              <span v-for="(item, index) in detail.field_info" :key="index">
                {{ i18n(item, 'name') }}
                <span v-if=" index+1 < detail.field_info.length">/ </span>
              </span>
            </div>
            <!-- <div class="detail p-1">
               {{ i18n(detail.specialist_info, 'name') }}
            </div> -->
            <div class="detail p-1">
               {{$t('booking.table.seniority')}}: {{ detail.start_work_year}}
            </div>
            <div class="detail p-1" v-if="detail.pet_kind_name">
               {{$t('booking.table.pet_kind_name')}}:
               <span v-for="(region, key) in detail.pet_kind_name"
                    :key="key" style="margin-right: 10px;">
                    {{ i18n(region, 'name')}}
                </span>
            </div>
            <div class="detail p-1">
               {{$t('booking.table.support_language')}}:
               <span v-for="(lang, key) in detail.support_language"
                    :key="key" style="margin-right: 10px;">
                    {{ i18n(lang, 'name')}}
                    {{ detail.support_language.length - 1 > key ? '/' : '' }}
                </span>
            </div>
            <div class="detail p-1">
               {{$t('booking.table.education')}}:
               <span style="margin-right: 10px;">
                    {{ i18n(detail, 'education') }}
                </span>
            </div>
            <!-- <div class="detail p-1" v-if="detail.support_region">
               {{$t('booking.table.support_region')}}:
               <span v-for="(region, key) in detail.support_region"
                    :key="key" style="margin-right: 10px;">
                    {{ i18n(region, 'name')}}
                </span>
            </div> -->
            <div class="detail p-1">
              <pre style="white-space: pre-wrap;line-height: 1.5;">{{ i18n(detail, 'description') }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="industry-data">
      <div class="container-md">
        <div class="row box">
          <div class="col">
            <div class="number">
              2800+
            </div>
            <div>{{ $t('home.industry.service') }}</div>
          </div>
          <div class="col">
            <div class="number">
              30+
            </div>
            <div>{{ $t('home.industry.veterinarian') }}</div>
          </div>
          <div class="col">
            <div class="number">
              100%
            </div>
            <div>{{ $t('home.industry.education') }}</div>
          </div>
          <div class="col">
            <div class="number">
              98%
            </div>
            <div>{{ $t('home.industry.satisfaction') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <process-tpl /> --> <!-- 流程 -->
    <reserve-tpl /> <!-- 预约 -->
    <subscribe-tpl /> <!-- 订阅 -->
    <footer-tpl /> <!-- 页脚 -->
  </div>
</template>
<script>
import bannerTpl from '@/components/banner';
import industryDataTpl from '@/components/industry-data';
import processTpl from '@/components/process';
import reserveTpl from '@/components/reserve';
import subscribeTpl from '@/components/subscribe';
import footerTpl from '@/components/footer';

export default {
  // eslint-disable-next-line
  name: 'expert-index',
  components: {
    bannerTpl,
    industryDataTpl,
    processTpl,
    reserveTpl,
    subscribeTpl,
    footerTpl,
  },
  data() {
    return {
      doctorList: [],
    };
  },
  mounted() {
    this.getDoctorList();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  methods: {
    getDoctorList() {
      let seach = { current_page: 1, page_size: 200 };
      let s = localStorage.getItem('s');
      if (s) {
        seach.specialist_id = s;
      }
      this.$api.doctor.getDoctorList(seach).then((res) => {
        if (res.data && res.data.code == 200) {
          res.data.data.list.forEach((element, index) => {
            if (typeof element.field_info == 'string' && element.field_info.indexOf('[') == 0) {
              let tmp = element.field_info;
              element.field_info = [];
              tmp = JSON.parse(tmp);
              element.field_info.push(tmp);
            }
            if (element.id == 'dzff6ibql4euks66bvg466fd86ecbf') {
              res.data.data.list.splice(index, 1);
            }
          });
          this.doctorList = res.data.data.list;
        }
      });
    },
    onClickReserve(id) {
      this.$router.push(`/booking?sid=${id}`);
    },
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
    },
    onNavClick(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
  .expert-page{
    padding-bottom: 100px;
    .industry-data{
      width: 100%;
      background: #26596E;
      text-align: center;
      color: #ffffff;
      .box{
        .col{
          text-align: center;
          padding: 30px 20px;
          .number{
            font-size: 38px;
          }
        }
      }
    }
    .banner-content-box{
      color: #fff
    }
    .col-avatar{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      .avatar{
        width: 100%;
      }
    }
    .name{
      text-align: left;
      .reserve-btn{
        float: right;
      }
    }
    .detail-qr{
      margin-left: 20px;
      margin-bottom: 5px;
      width: 24px;
      cursor: pointer;
      vertical-align: middle;
    }

  @media (max-width: 767.98px) {
    .box{
      display: block !important;
      .col{
        width: 50% !important;
        display: inline-block;
        vertical-align: top;
        padding: 30px 10px;
        .number{
          font-size: 26px;
        }
        .text{
          font-size: 0.62rem;
        }
      }
    }
    .expert-team{
      text-align: center;
      .col-avatar{
        width: 100%;
        height: 100px;
        .avatar{
          width: 100px;
        }
      }
      .name{
        text-align: center;
        .reserve-btn{
          display: block;
          margin: 0 auto;
          float: none;
        }
      }

    }
  }

}
</style>
<style lang="scss" scoped>
  .top-tools{
    // background-color: #EFEFEF;
    display: none;
    position: absolute;
    z-index: 2;
  }
  @media (max-width: 767.98px) {
    .top-tools{
      // background-color: #EFEFEF;
      display: block;
      width: 100%;
      height: 70px;
      text-align: right;
      padding: 10px 20px;
      .icon{
        font-size: 22px;
        margin-left: 10px;
      }
      .lang{
        vertical-align: top;
        margin-top: 4px;
      }

    }
  }
  </style>
