<template>
  <div ref="content-page" class="container-content bg-light">
    <div class="container-md content-padding-left">
      <div class="container" ref="Detail">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('reserve.detail.title') }}
          </div>
          <div>
            <button type="button" class="btn btn-primary me-1 mb-1" v-if="detail.status >= 2"
              @click="onClickDownloadInvoice">
              {{ $t('booking.invoice') }}
            </button>
            <button type="button" class="btn btn-primary" @click="refresh">
              {{ $t('booking.refresh') }}
            </button>
          </div>
        </div>
        <div v-if="detail.status < 2">{{ $t('booking.invoiceTip') }}</div>
        <hr>
        <div class="content">
          <div class="row-box border-bottom pb-3 mb-5">
            <div class="fs-5 col-md-12 text-start row-header mb-3">
              {{ $t('reserve.detail.reservation') }}
            </div>
            <div class="row">
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.veterinarian') }}:
                </div>
                <div class="col-6">
                  {{ detail.doctor_name }}
                </div>
              </div>
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.date') }}:
                </div>
                <div class="col-6">
                  {{ detail.book_date }} {{ detail.book_start_time }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.pet') }}:
                </div>
                <div class="col-6">
                  {{ detail.pet_name }}
                </div>
              </div>
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.appointment') }}:
                </div>
                <div class="col-6">
                  {{ detail.booking_record_code }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.price') }}:
                </div>
                <div class="col-6">
                  {{ detail.currency }}${{ detail.booking_total_price }}
                </div>
              </div>
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.status') }}:
                </div>
                <div class="col-6">
                  <span style="color:red" v-if="!pay">{{ $t('payment.107') }}</span>
                  <span v-else>{{ $t('reserve.' + detail.status) }} </span>
                </div>
              </div>
            </div>

            <!--<div class="row">
              <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.reason') }}:
                </div>
                <div class="col-6">
                  {{ detail.note || 'N/A'}}
                </div>
              </div>
               <div class="col-md-6 row">
                <div class="col-6 text-end">
                  {{ $t('reserve.detail.method') }}:
                </div>
                <div class="col-6">
                  {{ detail.consult_method }}
                </div>
              </div>
            </div>-->
            <div class="text-center my-4" v-if="detail.status > 2">
              <button type="button" class="btn btn-primary" @click="onClickMeeting()">
                {{ $t('reserve.detail.meeting') }}
              </button>
            </div>
            <div class="text-center my-4" v-else-if="detail.status == 2 && takingOrdersTime">
              <div>{{ $t('booking.takingOrders') }}： {{ time }} {{ $t('booking.minute') }}</div>
            </div>
            <div class="text-center my-4" v-else-if="detail.status == 1 && pay">
              <button type="button" class="btn btn-primary" :disabled="!pay" @click="onClickPay">
                {{ $t('booking.paynow') }}
              </button>
            </div>
          </div>
          <div class="row-box border-bottom pb-3 mb-5">
            <div class="row mb-2">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.reason') }}
              </div>
              <div class="btns col-md-9 text-end">
                <button type="button" class="btn btn-primary" :disabled="!pay" @click="saveReason">
                  {{ $t('reserve.detail.save') }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left file-list">
                <textarea style="width:100%;height: 70px;" v-model="detail.reason"></textarea>
              </div>
            </div>
          </div>
          <div class="row-box border-bottom pb-3 mb-5">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.documents') }}
              </div>
              <div class="btns col-md-9 text-end">
                <upload @success="uploadSuccess" />
              </div>
            </div>
            <div class="row">
              <div v-for="(item, index) in report_list" :key="index" class="col-md-9 text-left file-list"
                @click="downloadFile(item.url)">
                <img src="@/assets/pages/meeting/file.png"
                  style="width: 18px;height: 18px;object-fit: cover;cursor: pointer;" />
                <span style="cursor: pointer;margin-left: 6px;">{{
                  decodeURI(item.url.split('/')[item.url.split('/').length -
                    1].slice(14)).split("").reverse().join("").slice(0, 12).split("").reverse().join("")
                }}</span>
                <span @click.stop="deleteReport(index)" style="cursor: pointer;margin-left: 8px;color: red">&times;</span>
              </div>

            </div>
          </div>
          <div class="row-box border-bottom pb-3 mb-5">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.comment1') }}
              </div>
              <div class="col-md-3"></div>
              <div v-if="detail.doctor_advice" class="col-md-9 text-start">
                <pre style="line-height: 1.6;">{{ detail.doctor_advice }}</pre>
              </div>
            </div>
          </div>
          <!-- <div class="row-box border-bottom pb-3 mb-5">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.laboratory_record_list') }}
              </div>
            </div>
            <div class="row">
              <div
                v-if="detail.laboratory_record_list && detail.laboratory_record_list.length"
                class="col-md-9 text-center images"
              >
                <div v-for="(item, index) in detail.laboratory_record_list" :key="index">

                  <span style="margin: 0px 40px 0px 10px">{{ item.supplier_name }} </span>
                  <span>{{ item.test_time }} </span>
                  <div style="margin-top:10px"><img
                      v-for="(img, key) in item.image_urls" :key="key"
                      @click="downloadFile(img)"
                      :src="`${img}?imageMogr2/crop/70x70`"
                      style="width: 70px;height: 70px;object-fit: cover;cursor: pointer;border:1px solid #efefef"
                  ></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row-box border-bottom pb-3 mb-5">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.diagnostic_record_list') }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div v-if="detail.diagnostic_record_list && detail.diagnostic_record_list.length"
                class="col-md-9 text-center images" style="overflow-x: auto;">
                <table class="list-value" border="0" cellspacing="0" style="margin-top:20px;width: 100%;"
                  width="100%;TABLE-LAYOUT: fixed">
                  <tr class="tab-tr th">
                    <td class="tab-td">{{ $t('reserve.detail.time') }}</td>
                    <td class="tab-td">{{ $t('reserve.detail.category') }}</td>
                    <td class="tab-td">{{ $t('reserve.detail.description') }}</td>
                    <td class="tab-td">{{ $t('reserve.detail.file') }}</td>
                  </tr>
                  <tr class="tab-tr" v-for="(item, key) in detail.diagnostic_record_list || []" :key="key">
                    <td class="tab-td">{{ item.test_time || '-' }}</td>
                    <td class="tab-td">{{ item.diagnostic_type_id || '-' }}</td>
                    <td class="tab-td">{{ item.supplier_name || '-' }}</td>
                    <td class="tab-td">
                      <div v-for="(img, index) in item.image_urls" :key="index" @click="downloadFile(img)"
                        style="margin-right:6px;display: inline-block">
                        <img src="@/assets/pages/meeting/file.png" style="width: 16px">
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="row-box border-bottom pb-3 mb-5" v-if="!1">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.prescription_medicine_applies') }}
              </div>
              <div class="btns col-md-9 text-end"
                v-if="detail.medicine_product_list && detail.medicine_product_list.length">
                <button type="button" class="btn btn-primary" @click="onClickCart"> {{ $t('reserve.detail.buynow') }}
                </button>
              </div>
            </div>
            <div class="row ">
              <div class="col-md-3"></div>
              <div v-if="detail.medicine_product_list && detail.medicine_product_list.length"
                class="col-md-9 text-start images">
                <div class="medicine-list" v-for="(item, index) in product_list" :key="index">
                  <div class="medicine-image image">
                    <img :src="item.image_urls" alt="">
                  </div>
                  <div>
                    <div class="medicine-title">{{ i18n(item, 'title') }}</div>
                    <div class="medicine-spec">{{ $t('reserve.detail.quantity') }}：{{ item.quantity }}</div>
                    <div class="medicine-price">${{ item.discount_price }}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row-box border-bottom pb-3 mb-5" v-if="!1">
            <div class="row">
              <div class="fs-5 col-md-3 text-end row-header mb-3">
                {{ $t('reserve.detail.order_list') }}
              </div>
            </div>
            <div class="row " v-for="(order, key) in orderList" :key="key">
              <div class="col-md-3"></div>
              <div class="col-md-9 text-start">
                <div>{{ $t('reserve.detail.order_code') }}: {{ order.order_code }}</div>
                <div>{{ $t('reserve.detail.status') }}:
                  <span v-if="order.status == '-2'">{{ $t('reserve.detail.ERROR') }}</span>
                  <span v-if="order.status == '-1'">{{ $t('reserve.detail.CANCELLED') }}</span>
                  <span v-if="order.status == '1'">{{ $t('reserve.detail.SUBMIT') }}</span>
                  <span v-if="order.status == '2'">{{ $t('reserve.detail.PAID') }}</span>
                  <span v-if="order.status == '3'">{{ $t('reserve.detail.SHIPPING') }}</span>
                  <span v-if="order.status == '4'">{{ $t('reserve.detail.DONE') }}</span>
                </div>
                <div>{{ $t('reserve.detail.order_time') }}：{{ order.created_at }}</div>
                <div>{{ $t('reserve.detail.shipping_name') }}：{{ order.shipping_name }}</div>
                <div>{{ $t('reserve.detail.shipping_phone') }}：{{ order.shipping_phone }}</div>
                <div>{{ $t('reserve.detail.shipping_email') }}：{{ order.shipping_email }}</div>
                <div>{{ $t('reserve.detail.shipping_address') }}：{{ order.shipping_address }}</div>
                <div>{{ $t('reserve.detail.order_remark') }}：{{ order.order_remark }}</div>
                <div>{{ $t('reserve.detail.voucher_code') }}：{{ order.voucher_code }}</div>
                <div>{{ $t('reserve.detail.ucher_amount') }}：{{ order.ucher_amount }}</div>
                <div>{{ $t('reserve.cart.total') }}：{{ order.total_price }}</div>
                <div>{{ $t('reserve.detail.order_goods') }}:</div>
                <div class="medicine-list" v-for="(item, index) in order.product_list" :key="index">
                  <div class="medicine-image image">
                    <img :src="item.image_urls" alt="">
                  </div>
                  <div>
                    <div class="medicine-title">{{ item.description }}</div>
                    <div class="medicine-spec">{{ $t('reserve.detail.quantity') }}：{{ item.quantity }}</div>
                    <div class="medicine-price">${{ item.discount_price }}</div>
                  </div>
                </div>

                <div style="text-align: right;">
                  <button type="button" v-if="order.status >= '2'" class="btn btn-primary"
                    @click="onClickDownloadSaleInvoice(order.id)"> {{ $t('booking.invoice') }} </button>
                </div>
                <hr />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <model ref="payModel">
      <div class="py-5 p-md-5">
        <pay-btns @selected="paySelect" />
      </div>
      <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
        <div class="col-md-12">
          <button type="button" class="btn btn-secondary btn-sm me-2 px-5" @click="cancelPay">
            {{ $t('booking.cancel') }}
          </button>
          <button type="button" class="btn btn-success btn-sm px-5" @click="confirmPay">
            {{ $t('booking.paynow') }}
          </button>
        </div>
      </div>
    </model>
  </div>
</template>
<script>
import upload from '@/components/upload';
import model from '@/components/model';
import payBtns from '@/components/pay-btns';
import loading from '@/components/loading';

function loadEftScript() {
  const src = 'https://library-checkout.spiralplatform.com/js/v2/spiralpg.min.js';
  const id = 'eft-sdk';
  const exist = document.getElementById(id);
  if (exist) {
    return Promise.resolve(true);
  }
  const { head } = document;
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = function () {
      resolve(true);
    };
  });
}
export default {
  // eslint-disable-next-line
  name: 'member-reserve-detail',
  components: {
    upload,
    model,
    payBtns,
  },
  data() {
    return {
      booking_record_id: this.$route.query.booking_record_id,
      detail: {},
      report_list: [],
      medicine_list: [],
      consultRecord: {},
      medicineProductList: [],
      product_list: [],
      medicine: '',
      payType: 'paypal',
      takingOrdersTime: '',
      payStatus: 0,
      doctor_advice: '',
      Interval: '',
      orderList: [],
    };
  },
  async created() {
    await loadEftScript();
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768 ? 1 : 0;
    },
    time() {
      return this.getTime();
    },
    pay() {
      if (this.detail.status == 1 && this.payStatus == 1) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getDetail();
    this.getSaleOrderList();
    this.payStatus = localStorage.getItem(`payStatus-${this.booking_record_id}`) || 0;
    if (this.$refs.Detail) {
      let n = 0;
      this.Interval = setInterval(() => {
        if (this.detail.status == 1 && this.$refs.Detail) {
          this.getDetail(true);
          n++;
          if (n > 60001) {
            this.payStatus = 0;
            localStorage.setItem(`payStatus-${this.booking_record_id}`, 0);
            clearInterval(this.Interval);
          }
        } else {
          clearInterval(this.Interval);
        }
      }, 5000);
    }
    setTimeout(() => {
      this.takingOrdersTime = this.getTime();
    }, 1000);
  },
  activated() {
    // this.getDetail();
  },
  methods: {
    getDetail(p = false) {
      // console.log(11);
      this.$api.booking.getBookingDetail({ id: this.booking_record_id }, { hide_loading: p }).then((res) => {
        if (res.data.code == 200) {
          if (typeof res.data.data.laboratory_record_list == 'string') {
            res.data.data.laboratory_record_list = JSON.parse(this.detail.laboratory_record_list || []);
          }
          if (res.data.data.laboratory_record_list.length) {
            res.data.data.laboratory_record_list.forEach((element) => {
              if (typeof element.image_urls == 'string') {
                element.image_urls = JSON.parse(element.image_urls) || [];
              }
            });
          }
          if (typeof res.data.data.diagnostic_record_list == 'string') {
            res.data.data.diagnostic_record_list = JSON.parse(res.data.data.diagnostic_record_list || []);
          }
          if (res.data.data.diagnostic_record_list.length) {
            res.data.data.diagnostic_record_list.forEach((element) => {
              if (typeof element.image_urls == 'string') {
                element.image_urls = JSON.parse(element.image_urls) || [];
              }
            });
          }

          if (typeof res.data.data.surgery_report_url == 'string') {
            res.data.data.surgery_report_url = JSON.parse(res.data.data.surgery_report_url || []);
          } else {
            res.data.data.surgery_report_url = res.data.data.surgery_report_url || [];
          }

          if (this.detail.reason) {
            res.data.data.reason = this.detail.reason;
          }

          this.detail = res.data.data;
          // console.log(res.data.data);
          if (typeof res.data.data.previous_medical_report_list == 'string') {
            this.report_list = JSON.parse(this.detail.previous_medical_report_list || []);
          } else {
            this.report_list = this.detail.previous_medical_report_list || [];
          }
          // if (typeof res.data.data.previous_prescription_medicine == 'string') {
          //   this.medicine = JSON.parse(this.detail.previous_prescription_medicine || []);
          // } else {
          this.medicine = this.detail.previous_prescription_medicine || '';
          // }
          let product_list = [];
          if (typeof res.data.data.medicine_product_list == 'string') {
            product_list = JSON.parse(this.detail.medicine_product_list || []);
          } else {
            product_list = res.data.data.medicine_product_list || [];
          }
          product_list.forEach((item) => {
            if (item.image_urls == null) {
              item.image_urls = require('@/assets/pages/drug.png');
            } else if (typeof item.image_urls == 'object') {
              item.image_urls = item.image_urls[0] || require('@/assets/pages/drug.png');
            }
          });
          this.product_list = product_list;
          // this.detail.consult_record_id && this.$api.booking.getMedicineDetail({ consult_record_id: this.detail.consult_record_id, pet_id: this.detail.pet_id }).then((bak) => {
          //   if (bak.data.code == 00) {
          //     this.consultRecord = bak.data;
          //     this.medicineProductList = bak.data.medicine_product_list;
          //   }
          // });

          if (this.detail.status != 1) {
            localStorage.setItem(`payStatus-${this.booking_record_id}`, 0);
          }
        }
      });
    },
    getSaleOrderList() {
      this.$api.booking.getSaleOrderList({ booking_record_id: this.booking_record_id }).then((res) => {
        if (res.data.code == 200) {
          res.data.data.list.forEach((order) => {
            order.product_list.forEach((item) => {
              if (item.image_urls == null) {
                item.image_urls = require('@/assets/pages/drug.png');
              } else if (typeof item.image_urls == 'object') {
                item.image_urls = item.image_urls[0] || require('@/assets/pages/drug.png');
              }
            });
          });
          this.orderList = res.data.data.list;
        }
      });
    },
    xhrRequest(url) {
      let xhr = new XMLHttpRequest(); let
        that = this;
      // 也可以使用POST方式，根据接口
      xhr.open('GET', url, true);
      // xhr.setRequestHeader('Authorization', 'testAuthorization');
      // 返回类型blob
      xhr.responseType = 'blob';
      // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
      xhr.onload = function () {
        // 请求完成
        if (this.status === 200) { // 返回200
          // console.log(1, this.response);
          let blob = this.response;
          let reader = new FileReader();
          reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
          reader.onload = function (e) {
            // console.log(2, e);
            // 转换完成，创建一个a标签用于下载
            if (typeof plus == 'object') {
              // eslint-disable-next-line
              plus.runtime.openURL(url, (res) => {
                // console.log(res);
              });
            } else {
              let a = document.createElement('a');
              let nameFile = `${that.booking_record_id}.pdf`;
              a.download = nameFile;
              a.href = e.target.result;
              a.id = that.booking_record_id;
              document.body.appendChild(a);
              a.click();
              // a.remove();
            }
          };
        }
      };
      xhr.send();
    },
    downloadFile(url) {
      this.$toast.show({
        title: this.$t('tips'),
        content: this.$t('meeting.downloadFile'),
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then(() => {
        if (typeof plus == 'object') {
          // eslint-disable-next-line
          plus.runtime.openURL(url, (res) => {
            // console.log(res);
          });
        } else {
          window.open(url, '_blank');
        }
      });
    },
    onClickDownloadSaleInvoice(id) {
      let url = `${process.env.VUE_APP_BASE_API}/api/public/download_sale_invoice?id=${id}&token=${this.$store.state.token}`;
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    onClickDownloadInvoice() {
      let url = `${process.env.VUE_APP_BASE_API}/api/public/download_invoice?id=${this.booking_record_id}&token=${this.$store.state.token}`;
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        plus.runtime.openURL(url, (res) => {
          console.log(res);
        });
      } else {
        window.open(url, '_blank');
      }
    },
    getTime() {
      let created = this.detail.created_at || '';
      let timestamp = +new Date(created.replace(/-/g, '/') || new Date());
      timestamp += 60 * 5;
      if (new Date() >= timestamp) {
        return '';
      }
      // console.log(1111, timestamp);
      let a = timestamp - new Date();
      let b = Math.floor(a % 60);
      let c = a % 60;
      return `${b}:${c}`;
    },
    deleteReport(index) {
      this.$toast.show({
        title: this.$t('tips'),
        content: this.$t('reserve.cart.confirmDel'),
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then(() => {
        this.report_list.splice(index, 1);
        this.updatePreviousInfo();
      });
    },
    saveReason() {
      this.updatePreviousInfo();
    },
    updatePreviousInfo(file) {
      let data = {
        id: this.booking_record_id,
        consult_record_id: this.detail.consult_record_id,
        booking_record_id: this.booking_record_id,
        previous_medical_report_list: this.report_list,
        previous_prescription_medicine: this.medicine,
        reason: this.detail.reason,
      };
      file && data.previous_medical_report_list.push(file);
      this.$api.booking.updatePreviousInfo(data).then(() => {
        this.getDetail();
        this.$toast.show({ title: this.$t('tips'), content: this.$t('reserve.detail.uploadSuccess') });
      });
    },
    uploadSuccess(file) {
      // console.log(file);
      // this.report_list.push(file);
      this.updatePreviousInfo(file);
    },
    onClickMeeting() {
      let url = `/meeting?userid=${this.$store.state.user.id}&roomid=${this.booking_record_id}`;
      let width = 1200; let height = 820; let left = 0; let
        top = 0;
      if (window.screen.width < width) {
        width = window.screen.width;
      } else {
        left = (window.screen.width - width) / 2;
        top = (window.screen.height - height) / 2;
      }
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        uni.webView.navigateTo({ url: `/pages/meeting/index?userid=${this.$store.state.user.id}&roomid=${this.booking_record_id}` });
      } else {
        window.open(url, '_blank', `width=${width},height=${height},status=no,titlebar=no,toolbar=no,menubar=no,location=no,left=${left},top=${top}`);
      }
    },
    onClickCart() {
      this.$router.push(`/member/reserve/confirm?booking_record_id=${this.booking_record_id}&consult_record_id=${this.detail.consult_record_id}`);
    },
    onClickPay() {
      this.$refs.payModel.show();
    },
    paySelect(type) {
      this.payType = type;
    },
    confirmPay() {
      localStorage.setItem(`payStatus-${this.booking_record_id}`, '1');
      if (this.payType == 'paypal') {
        this.onClickPaypal();
      } else if (
        this.payType == 'alipay_hk'
        || this.payType == 'alipay_cn'
        || this.payType == 'vm'
        || this.payType == 'unionpay'
      ) {
        this.onEftClick(this.payType);
      } else if (this.payType == 'mppay') {
        this.onMppayClick();
      }
    },
    cancelPay() {
      this.$refs.payModel.hide();
    },

    async onClickPaypal() {
      if (this.booking_record_id) {
        let res = await this.$api.payment.createBookingPaypal({ booking_record_id: this.booking_record_id }).catch(() => null);
        if (res && res.data && res.data.data) {
          this.getDetail();
          this.$refs.payModel.hide();
          if (typeof plus == 'object') {
            // eslint-disable-next-line
            uni.webView.navigateTo({ url: `/pages/webview/index?url=${res.data.data.url}` });
          } else {
            window.location.href = res.data.data.url;
          }
        }
      }
    },
    async onEftClick(payType) {
      if (this.booking_record_id) {
        let type = 3;
        if (payType == 'alipay_cn') {
          type = 3;
        } else if (payType == 'alipay_hk') {
          type = 4;
        } else if (payType == 'mppay') {
          type = 5;
        } else if (payType == 'vm') {
          type = 6;
        } else if (payType == 'unionpay') {
          type = 7;
        }
        let res = await this.$api.payment.createBookingEft({
          id: this.booking_record_id, in_app: this.isMobile, order_payment: 1, pay_type: type,
        });
        if (res && res.data) {
          try {
            this.$showLoading();
            // eslint-disable-next-line
            SpiralPG.init(res.data);
          } catch (err) {
            null;
          }
          this.eftPay(1);
        }
      }
    },
    eftPay(times) {
      if (times > 15) {
        loading.loading.hide();
        return this.$toast.show(this.$t('payment.105'));
      }
      let globalThis = this;
      try {
        loading.loading.show();
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 1500);
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 3000);
        // globalThis.$toast.show(globalThis.$t('payment.104'));
        setTimeout(() => {
          // eft有时会很久才跳，其实已经成功了的
          loading.loading.hide();
        }, 5000);
      } catch (err) {
        setTimeout(() => {
          times++;
          globalThis.eftPay(times);
        }, 1500);
      }
    },
    refresh() {
      this.getDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content {
  height: calc(100vh - 5rem);
  color: var(--bs-gray-600);
  overflow-y: auto;
  padding-bottom: 100px;

  .file-list {
    padding-left: 30%;
    margin-bottom: 10px;
  }

  @media (min-width: 767.98px) {
    .content-padding-left {
      padding-left: 180px;
    }
  }

  @media (max-width: 767.98px) {
    .file-list {
      padding-left: 20px;
    }

    .row-header {
      text-align: left !important;
    }

    .medicine-list {
      position: relative;
      padding-left: 100px !important;

      .medicine-image {
        background-color: #efefef;
        width: 70px;
        height: 70px;
        position: absolute;
        left: 0px !important;
      }
    }
  }

  .content-padding-left {
    height: 100%;
    padding-top: 18px;

    .container {
      background-color: #ffffff;
      min-height: calc(100% - 2px);
      border-radius: 4px;
      padding: 30px;

      hr {
        background-color: var(--bs-gray-600);
      }

      .tab-tr {
        .tab-td {
          border-right: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
          padding: 10px;
          height: 37px;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .tab-td:first-child {
          border-left: 1px solid #efefef;
        }
      }

      .tab-tr:first-child {
        border-top: 1px solid #efefef;
      }

      .th .tab-td {
        background-color: #efefef;
      }

      .content {
        padding: 20px 10px;

        .images {
          img {
            width: 100%;
          }

        }

        .medicine-list {
          position: relative;
          padding-left: 200px;
          margin-bottom: 40px;

          .medicine-image {
            background-color: #efefef;
            width: 70px;
            height: 70px;
            position: absolute;
            left: 100px;
          }

          .medicine-title {
            font-size: 18px;
          }
        }

      }
    }
  }
}
</style>
