<template>
  <div class="payment-method-list">
    <div class="form-check form-check-inline">
      <input
        id="payRadio1"
        v-model="select"
        @change="onChenag"
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        value="paypal"
        checked
      >
      <label
        class="form-check-label"
        for="payRadio1"
      >
        <div
          class="payment-method-button paypal"
        />
      </label>
    </div>
    <div class="form-check form-check-inline" >
      <input
        id="payRadio2"
        v-model="select"
        @change="onChenag"
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        value="alipay_hk"
      >
      <label
        class="form-check-label"
        for="payRadio2"
      >
        <div
          class="payment-method-button alipay-hk"
        />
      </label>
    </div>
    <div class="form-check form-check-inline">
                <input
                  v-model="select"
                  @change="onChenag"
                  id="payRadio3"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  value="alipay_cn"
                >
                <label
                  class="form-check-label"
                  for="payRadio3"
                >
                  <div
                    class="payment-method-button alipay-cn"
                  />
                </label>
              </div>
    <div class="form-check form-check-inline align-top" >
      <input
        id="payRadio4"
        v-model="select"
        @change="onChenag"
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        value="vm"
      >
      <label
        class="form-check-label"
        for="payRadio4"
      >
        <div

          class="payment-method-button vm-eft"
        >
          <div
            class="vm-eft-grid"
            style=""
          >
            <img
              class="vm-eft-item"
              src="@/assets/form/visa.svg"
            >
            <img
              class="vm-eft-item"
              src="@/assets/form/mastercard.svg"
            >
          </div>
        </div>
      </label>
    </div>

    <div class="form-check form-check-inline" >
      <input
        id="payRadio5"
        v-model="select"
        @change="onChenag"
        class="form-check-input"
        type="radio"
        name="exampleRadios"
        value="unionpay"
      >
      <label
        class="form-check-label"
        for="payRadio5"
      >
        <div

          class="payment-method-button unionpay-eft"
        />
      </label>
    </div>
    <div class="form-check form-check-inline">
                <input
                  v-model="select"
                  @change="onChenag"
                  id="payRadio6"
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  value="mppay"
                >
                <label
                  class="form-check-label"
                  for="payRadio6"
                >
                  <div
                    class="payment-method-button mppay"
                  />
                </label>
              </div>
  </div>
</template>
<script>
// 页脚
export default {
  // eslint-disable-next-line
  name: 'PayBtnsTpl',
  data() {
    return {
      select: 'paypal',
    };
  },
  mounted() {
    this.onChenag();
  },
  methods: {
    onChenag() {
      this.$emit('selected', this.select);
    },
  },
};
</script>
<style lang="scss" scoped>
  .payment-method-list{
    margin-top: 10px;
      .payment-method-button{
        position: relative;
        top: -8px;
        // margin-top: 20px;
        display: block;
        height: 36px;
        width: 70px;
        box-shadow: 0px 0px 1px 1px #00000010;
        cursor: pointer;
        border-radius: 4px;
      }
      .vm-eft-grid {
          display: inline-flex;
          justify-content: center;
      }
      .vm-eft-item {
          width: 40%;
          margin-left: 3%;
          margin-top: 6px;
      }
      .paypal {
        background: url(@/assets/form/paypal.svg) 100% 100%/100% 100% #ccdeff00;
        background-size: 350%;
        background-position: center;
      }
      .apple {
        background: url(@/assets/form/apple-pay.svg) 100% 100%/100% 100% #000000;;
      }
      .alipay-cn {
        background: url(@/assets/form/alipay-cn.svg) 100% 100%/100% 100% #ffffff no-repeat;
        background-size: 90%;
        background-position: center;
      }
      .alipay-hk {
        background: url(@/assets/form/alipay-hk.png) 100% 100%/100% 100% #ffffff no-repeat;
        background-size: 90%;
        background-position: center;
      }
      .mppay {
        background: url(@/assets/form/wechatpaycn.svg) 100% 100%/100% 100% no-repeat;
        background-size: 90%;
        background-position: center;
      }
      .wechat-eft {
        background: url(@/assets/form/wechatpaycn.svg) 100% 100%/100% 100% #ffffff no-repeat;
        background-size: 90%;
        background-position: center;
      }
      .unionpay-eft {
        background: url(@/assets/form/unionpay.svg) 100% 100%/100% 100% #ffffff no-repeat;
        background-size: 90%;
        background-position: center;
      }
    }
</style>
