import { DatetimeFormat } from 'vue-i18n';
import Request from '@/utils/request';

const Api = {
  oss: {
    getCosToken() {
      return Request({
        url: '/api/cos/key',
        method: 'get',
      });
    },
  },
  user: {
    subscribeMail(data) {
      return Request({
        url: '/api/public/subscribe_mail',
        method: 'post',
        data,
      });
    },
    login(data) {
      return Request({
        url: '/api/passport/login',
        method: 'post',
        data,
      });
    },
    register(data) {
      return Request({
        url: '/api/passport/register',
        method: 'post',
        data,
      });
    },
    sendRegisterCode(params) {
      return Request({
        url: '/api/passport/register_code',
        method: 'post',
        params,
      });
    },
    setRegisterPassword(data) {
      return Request({
        url: '/api/passport/update_password',
        method: 'post',
        data,
      });
    },
    sendPasswordCode(params) {
      return Request({
        url: '/api/passport/register_code',
        method: 'post',
        params,
      });
    },
    resetPassword(data) {
      return Request({
        url: '/api/passport/check_code',
        method: 'post',
        data,
      });
    },
    updateUserinfo(data) {
      return Request({
        url: '/api/customer/store',
        method: 'post',
        data,
      });
    },
    getUserInfo(data) {
      return Request({
        url: '/api/customer/get_info',
        method: 'post',
        data,
      });
    },
    getNotificationList(data) {
      return Request({
        url: '/api/notification/list',
        method: 'post',
        data,
      });
    },
  },
  booking: {
    getSaleOrderList(data) {
      return Request({
        url: '/api/sale_order/list',
        method: 'post',
        data,
      });
    },
    getBookingList(data, config) {
      return Request({
        url: '/api/booking/list',
        method: 'post',
        data,
        ...config,
      });
    },
    getBookingDetail(data, config) {
      return Request({
        url: '/api/booking/info',
        method: 'post',
        data,
        ...config,
      });
    },
    getMedicineDetail(params) {
      return Request({
        url: '/api/consult_record',
        method: 'get',
        params,
      });
    },
    updatePreviousInfo(data) {
      return Request({
        url: '/api/booking/update_previous_info',
        method: 'post',
        data,
      });
    },

    addBooking(data) {
      return Request({
        url: '/api/booking/store',
        method: 'post',
        data,
      });
    },

    addInstantConnect(data) {
      return Request({
        url: '/api/booking/instant_connect',
        method: 'post',
        data,
      });
    },
    getInstantConnectPrice(data) {
      return Request({
        url: '/api/booking/calculate_voucher',
        method: 'post',
        data,
      });
    },
    useBookingVoucher(data) {
      return Request({
        url: '/api/booking/use_voucher',
        method: 'post',
        data,
      });
    },
  },
  pet: {
    getPetKind() {
      return Request({
        url: '/api/pet_kind/list',
        method: 'post',
      });
    },
    getPetList(data) {
      return Request({
        url: '/api/pet/list',
        method: 'post',
        data,
      });
    },
    addPet(data) {
      return Request({
        url: '/api/pet/add',
        method: 'post',
        data,
      });
    },

    updatePet(data) {
      return Request({
        url: '/api/pet/store',
        method: 'post',
        data,
      });
    },

    deletePet(data) {
      return Request({
        url: '/api/pet/delete',
        method: 'post',
        data,
      });
    },

    getPetInfo(data) {
      return Request({
        url: '/api/pet/get_info',
        method: 'post',
        data,
      });
    },
  },
  payment: {
    createBookingPaypal(data) {
      return Request({
        url: '/api/paypal/create',
        method: 'post',
        data,
      });
    },
    createBookingApplePay(data) {
      return Request({
        url: '/api/booking/apple_pay/create',
        method: 'post',
        data,
      });
    },
    createBookingEft(data) {
      return Request({
        url: '/api/eft/create',
        method: 'post',
        data,
      });
    },
    createShoppingPaypal(data) {
      return Request({
        url: '/api/sale_order/paypal/create',
        method: 'post',
        data,
      });
    },
    createShoppingApplePay(data) {
      return Request({
        url: '/api/sale_order/apple_pay/create',
        method: 'post',
        data,
      });
    },
  },
  doctor: {
    getDoctorSpecialist() {
      return Request({
        url: '/api/specialist',
        method: 'get',
      }, {
        useCache: true,
        setCache: true,
        expire: 60 * 60,
      });
    },

    getDoctorLanguageList() {
      return Request({
        url: '/api/language',
        method: 'get',
      }, {
        useCache: true,
        setCache: true,
        expire: 60 * 60,
      });
    },

    getDoctorRegionList() {
      return Request({
        url: '/api/region',
        method: 'get',
      }, {
        useCache: true,
        setCache: true,
        expire: 60 * 60,
      });
    },

    getDoctorList(data) {
      return Request({
        url: '/api/doctor/list',
        method: 'post',
        data,
      });
    },

    getDoctorInfo(data) {
      return Request({
        url: '/api/doctor/get_info',
        method: 'post',
        data,
      });
    },
    getDoctorSchedule(data) {
      return Request({
        url: '/api/doctor/schedule',
        method: 'post',
        data,
      });
    },
  },
  meeting: {
    getUserSig(data) {
      return Request({
        url: '/api/trtc/genSig',
        method: 'post',
        data,
      });
    },
    getMsgList(data) {
      return Request({
        url: '/api/msg/list',
        method: 'post',
        data,
      });
    },
    sendMsg(data) {
      return Request({
        url: '/api/msg/send',
        method: 'post',
        data,
      });
    },
  },
  cart: {
    addressStore(data) {
      return Request({
        url: '/api/address/store',
        method: 'post',
        data,
      });
    },
    addressDelete(data) {
      return Request({
        url: '/api/address/delete',
        method: 'post',
        data,
      });
    },
    addressList(data) {
      return Request({
        url: '/api/address/list',
        method: 'post',
        data,
      });
    },
    getSaleOrderPrice(data) {
      return Request({
        url: '/api/sale_order/calculate_voucher',
        method: 'post',
        data,
      });
    },
    saleOrderAdd(data) {
      return Request({
        url: '/api/sale_order/add',
        method: 'post',
        data,
      });
    },
    createOrderPaypal(data) {
      return Request({
        url: '/api/paypal/create_medicine',
        method: 'post',
        data,
      });
    },
  },
  site: {
    getSite(data) {
      return Request({
        url: '/api/site/get_site',
        method: 'post',
        data,
      });
    },
    message(data) {
      return Request({
        url: '/api/public/message',
        method: 'post',
        data,
      });
    },
  },
};

Api.install = (app) => {
  app.config.globalProperties.$api = Api;
};
export default Api;
