<template>
  <div class="container-content bg-light">
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('member.title') }}
          </div>
          <div class="btns" />
        </div>
        <hr>
        <div class="content text-center">
          <div class="row my-3">
            <div class="col-4 text-end">
              {{ $t('member.header') }}：
            </div>
            <div class="col-6 text-start">
              <div
                v-if="form.avatar"
                class="rounded-circle bg-light d-inline-block overflow-hidden me-5"
                style="width: 120px;height: 120px"
              >
                <img
                  :src="form.avatar"
                  style="width: 120px;height: 120px;object-fit: cover;"
                >
              </div>
              <upload @success="uploadSuccess" />
            </div>
          </div>
          <div class="row my-3">
            <div class="col-4 text-end">
              {{ $t('login.email') }}：
            </div>
            <div class="col-6 text-start">
              <input
                type="email"
                :class="['form-control', 'form-control-sm', emailDanger]"
                placeholder=""
                :value="$store.state.user.email"
                disabled="true"
              >
            </div>
          </div>
          <div class="row my-3">
            <div class="col-4 text-end">
              {{ $t('login.username') }}：
            </div>
            <div class="col-6 text-start">
              <input
                v-model="form.user_name"
                type="name"
                :class="['form-control', 'form-control-sm', nameDanger]"
                placeholder=""
              >
            </div>
          </div>
          <div class="row my-3">
            <div class="col-4 text-end">
              {{ $t('login.phone') }}：
            </div>
            <div class="col-6 text-start">
              <input
                v-model="form.phone"
                type="phone"
                :class="['form-control', 'form-control-sm', phoneDanger]"
                placeholder=""
              >
            </div>
          </div>
          <hr class="mt-5 mb-3">
          <div>
            <button
              type="button"
              class="btn btn-secondary btn-sm me-2 px-5"
              @click="cancel"
            >
              {{ $t('member.cancel') }}
            </button>
            <button
              type="button"
              class="btn btn-success btn-sm px-5"
              @click="confirm"
            >
              {{ $t('member.confirm') }}
            </button>
          </div>
          <!-- <div class="text-center" style="margin-top:100px; color:#ccc">
            <span @click="deleteAccount">{{$t('deleteAccount')}}</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import upload from '@/components/upload2';

export default {
  // eslint-disable-next-line
  name: 'member-info-edit',
  components: {
    upload,
  },
  data() {
    return {
      form: {
        id: this.$store.state.user.id,
        user_name: this.$store.state.user.username,
        phone: this.$store.state.user.mobile,
        avatar: this.$store.state.user.avatar,
      },
      nameDanger: '',
      emailDanger: '',
      phoneDanger: '',
    };
  },
  methods: {
    deleteAccount() {
      this.$toast.show({
        title: this.$t('tips'),
        content: `${this.$t('deleteAccountTip')}`,
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then((_) => {
        this.$store.dispatch('setUserInfo', {});
        this.$router.push('/user/login');
      });
    },
    uploadSuccess(file) {
      this.form.avatar = file.url;
    },
    confirm() {
      this.$api.user.updateUserinfo(this.form).then((_) => {
        this.$api.user.getUserInfo({ id: this.$store.state.user.id }).then((res) => {
          if (res.data.code == 200) {
            this.$store.state.user.avatar = res.data.data.info.avatar;
            this.$store.state.user.email = res.data.data.info.email;
            this.$store.state.user.phone = res.data.data.info.phone;
            this.$store.state.user.mobile = res.data.data.info.phone;
            this.$store.state.user.username = res.data.data.info.user_name;
            localStorage.setItem('userInfo', JSON.stringify(this.$store.state.user));
            this.$toast.show({ title: this.$t('tips'), content: this.$t('member.saveSuccess') });
            this.$router.push('/member/info');
          }
        });
      }).catch((res) => {
        this.$toast.show({ title: this.$t('tips'), content: res.data.msg });
      });
    },
    cancel() {
      this.$router.push('/member/info');
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }

    }
  }
}
</style>
