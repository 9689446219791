<template>
  <div class="service-page">
    <div class="top-tools">
      <span class="lang-btn" v-if="$i18n.locale == 'en'" @click="onChangeLang()">中文</span>
      <span class="lang-btn" v-else @click="onChangeLang()">ENG</span>
      <!-- <img
        :src="require('@/assets/navbar/lang-light.png')"
        alt=""
        width="18"
        height="18"
        class="d-inline-block align-text-center lang"
        @click="onChangeLang()"
      > -->
      <!-- <i class="icon icon-info" @click="onNavClick('/about')"></i> -->
      <i class="icon icon-notice" @click="onNavClick('/member/notice')"></i>
      <!-- <i class="icon icon-my" @click="onNavClick('/member')"></i> -->
    </div>
    <banner-tpl
      :bg="require(smallScreen ? '@/assets/banner/4-m.png' : '@/assets/banner/4.png')"
      :title="$t('service.banner.title')"
      :content="$t('service.banner.content')"
      :btn-text="$t('service.banner.btn1')"
      :bgLight="isMobile ? true : false"
    /> <!-- banner -->
    <div class="container-md py-5 text-left">
      <h1 class="text-left pt-5 pb-3">
        {{ $t('service.title') }}
      </h1>
      <div class="py-3">
        <div
          class="fs-5 service-content"
          v-html="$t('service.content')"
        />
      </div>
    </div>

    <!-- <div class="bg-light">
      <div class="container-md py-5 text-left">
        <button
          type="button"
          class="btn btn-choose float-end mt-4"
          @click="onClick"
        >
          {{ $t('home.why_choose.btn') }}
        </button>
        <h1 class="text-left py-3">
          {{ $t('service.easy') }}
        </h1>
      </div>
      <div class="container-md px-4 content-cart py-2">
        <div class="row px-md-5">
          <div class="col-md py-5">
            <div class="cart">
              <div>
                <span class="number me-2">01</span>
                <span class="title fs-5">{{ $t('service.cart.0.title') }}</span>
              </div>
              <div>
                {{ $t('service.cart.0.content') }}
              </div>
            </div>
          </div>
          <div class="col-md py-5">
            <div class="cart">
              <div>
                <span class="number me-2">02</span>
                <span class="title fs-5">{{ $t('service.cart.1.title') }}</span>
              </div>
              <div>
                {{ $t('service.cart.1.content') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row px-md-5">
          <div class="col-md py-5">
            <div class="cart">
              <div>
                <span class="number me-2">03</span>
                <span class="title fs-5">{{ $t('service.cart.2.title') }}</span>
              </div>
              <div>
                {{ $t('service.cart.2.content') }}
              </div>
            </div>
          </div>
          <div class="col-md py-5">
            <div class="cart">
              <div>
                <span class="number me-2">04</span>
                <span class="title fs-5">{{ $t('service.cart.3.title') }}</span>
              </div>
              <div>
                {{ $t('service.cart.3.content') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <when-tpl /> <!-- 什么时候需要 -->

    <reserve-tpl /> <!-- 预约 -->

    <div class="container-md py-5 text-center">
      <h1 class="text-center py-3">
        {{ $t('service.want_to_know.title') }}
      </h1>
      <div class="text-start px-md-5">
        <div
          v-for="(item, index) in qaList"
          :key="index"
          class="px-md-5 d-flex py-3"
        >
          <div class="fs-2 px-3 lh-base">
            &ordm;
          </div>
          <div>
            <div class="fs-4">
              {{ $t('service.want_to_know.list.'+index+'.title') }}
            </div>
            <div v-html="$t('service.want_to_know.list.'+index+'.content')" />
          </div>
        </div>
      </div>
    </div>

    <subscribe-tpl /> <!-- 订阅 -->
    <footer-tpl /> <!-- 页脚 -->
  </div>
</template>
<script>
import bannerTpl from '@/components/banner';
import dividerTpl from '@/components/divider';
import reserveTpl from '@/components/reserve';
import subscribeTpl from '@/components/subscribe';
import footerTpl from '@/components/footer';
import whenTpl from '@/components/when';

export default {
  // eslint-disable-next-line
  name: 'service-index',
  components: {
    bannerTpl,
    dividerTpl,
    whenTpl,
    reserveTpl,
    subscribeTpl,
    footerTpl,
  },
  data() {
    return {
    };
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768;
    },
    qaList() {
      let list = [];
      for (let index = 1; index < 7; index++) {
        list.push({});
      }
      return list;
    },
  },
  methods: {
    onClick() {
      this.$router.push('/reserve');
    },
    onChangeLang() {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale == 'zh_TW') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh_TW';
      }
    },
    onNavClick(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.service-content{
    font-family: PingFang SC;
    color: #666666;

  ul{
    list-style-type: circle;
    li{
      font-family: PingFang SC;
      color: #666666;
    }
  }

}
.content-cart{
  .number{
    font-size: 70px;
    font-family: Bangla MN;
    font-weight: bold;
    line-height: 0.75;
    color: #5FA99A;
  }
  .row:last-child{
    .col-md{
      border-top: 1px solid #F7F7F9;
    }
  }
  .row{
    background-color: #ffffff;
    .col-md:first-child{
      border-right: 1px solid #F7F7F9;
    }
    .col-md{
      margin: 1px 1px auto auto;
      padding: 20px;
      .cart{
        .title{
          line-height: 0.8;
          margin-bottom: 10px;
        }
      }
      .cart>div{
        margin-bottom: 10px;
      }
    }
  }
}
.btn-choose{
  border: 1px solid #333333;
  border-radius: 2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  color: #333333;
}
  @media (max-width: 767.98px) {
    .content-cart{
      .row{
        .col-md{
          border-top: 12px solid #F7F7F9;
          .cart {
            .number{
              font-size: 42px;
            }
          }
        }
      }
      .row:last-child{
        .col-md{
          border-top: 10px solid #F7F7F9;
        }
      }
      .row:first-child{
        .col-md:first-child{
          border-top: 0px solid #F7F7F9;
        }
      }
    }
    .btn-choose{
      display: none;
    }
  }
</style>
<style lang="scss" >
.service-page{
  padding-bottom: 100px;
    h1{
      font-weight: 500;
      font-family: PingFang SC;
      font-size: 30px;
      color: #333333;
    }
    .service-content{
      font-family: PingFang SC;
      color: #666666;

    ul{
      list-style-type: circle;
      li{
        font-family: PingFang SC;
        color: #666666;
        margin-bottom: 8px;
        font-weight: 300;
      }
    }

  }
}

</style>
<style lang="scss" scoped>
  .top-tools{
    // background-color: #EFEFEF;
    display: none;
    position: absolute;
    z-index: 2;
  }
  @media (max-width: 767.98px) {
    .top-tools{
      // background-color: #EFEFEF;
      display: block;
      width: 100%;
      height: 70px;
      text-align: right;
      padding: 10px 20px;
      .icon{
        font-size: 22px;
        margin-left: 10px;
      }
      .lang{
        vertical-align: top;
        margin-top: 4px;
      }

    }
  }
  </style>
