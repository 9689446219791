<template>
    <div class="divider text-center">
      <div class="divider-bg"></div>
      <a href="#reserve"><h2 class="text-center p-3 divider-content"><u>{{ $t('home.experience.title') }}</u></h2></a>
    </div>
</template>
<script>
// 分隔条
export default {
  name: 'divider-tpl',
};
</script>
<style lang="scss" scoped>
.divider{
  background-color: #798D62;
  position: relative;
  overflow: hidden;
  height: 64px;
  .divider-bg{
    width: 40vw;
    height: 70vw;
    background-color: #514D48;
    z-index: 0;
    position: absolute;
    left: 50%;
    margin: -25vw auto auto -28vw ;
    transform: rotate(45deg);
  }
  .divider-content{
    z-index: 2;
    position: absolute;
    width: 100%;
    color: #ffffff;
  }
}

</style>
