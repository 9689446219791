<template>
  <div class="meeting container-md" ref="meeting">
    <div class="meeting-header text-center fs-5">
      <div class="close fs-4 float-end me-3" @click="close">×</div>
      <div class="full fs-4 float-end me-3" @click="fullScreenFun">
        <img src="@/assets/pages/96.png">
      </div>
      {{ $t('meeting.title') }}
    </div>
    <div class="meeting-body">
      <div class="meeting-body-left">
        <div class="video-box-1" id="remote-stream"></div>
        <div class="video-box-2" id="local-stream"></div>
      </div>
      <div class="meeting-body-right" v-show="hideMsgContent">
        <div class="meeting-body-right-title text-center">
          {{ $t('meeting.message') }}
        </div>
        <div class="meeting-body-right-message">
          <div
            v-for="(item, index) in msgList"
            :key="index"
            :class="['msg', item.user_id == userid && 'msg-right']"
          >
            <div class="msg-head">
              <img
                :src="
                  isDoctor == 1 && item.user_id == userid && item.doctor_avatar ? (item.doctor_avatar + '?imageMogr2/crop/50x50') :
                  ( isDoctor == 0 && item.user_id != userid && item.doctor_avatar ? (item.doctor_avatar + '?imageMogr2/crop/50x50') :
                  ( isDoctor == 1 && item.user_id != userid && item.pet_avatar ? (item.pet_avatar + '?imageMogr2/crop/50x50') :
                  ( isDoctor == 0 && item.user_id == userid && item.pet_avatar ? (item.pet_avatar + '?imageMogr2/crop/50x50') :
                  request('@/assets/navbar/user-fill.png') )
                  )
                    ) "
                style="width: 100%;height: 100%;object-fit: cover;opacity: 0.7;"
              >
            </div>
            <div class="msg-info" v-if="item.type != 'text'" @click="downloadFile(item.url)">
              <div style="color: #ccc;font-size: 12px">{{item.created_at}}</div>
              <img src="@/assets/pages/meeting/file.png"
                style="width: 16px;height: 16px;object-fit: cover;"
              >
              {{ item.msg }}
            </div>
            <div class="msg-info" v-else>
              <div style="color: #ccc;font-size: 12px">{{item.created_at}}</div>
              <pre style="max-width: 100%;white-space: break-spaces;word-break: break-all;">{{ item.msg }}</pre>
            </div>
          </div>
        </div>
        <div class="meeting-body-right-input">
          <div class="meeting-body-right-input-tools">
            <div>
              <img src="@/assets/pages/meeting/express.png" @click="emojiShowFun">
              <div class="meeting-body-right-input-tools-emoji" v-if="emojiShow">
                <span v-for="item in emojiList" :key='item' @click="emojiSelectFun(item)">{{ item }}</span>
              </div>
            </div>
            <div class="meeting-body-right-input-tools-upload">
              <img src="@/assets/pages/meeting/file.png">
              <input
                id="file"
                type="file"
                @change="onChange"
              >
            </div>
            <div style="float: right;" @click="onChangeMic">
              <img src="@/assets/pages/meeting/02.png" v-if="muteAudio" />
              <img src="@/assets/pages/meeting/01.png" v-else />
            </div>
            <div style="float: right;" @click="onChangeCamera">
              <img src="@/assets/pages/meeting/12.png"  v-if="muteVideo" />
              <img src="@/assets/pages/meeting/11.png" v-else />
            </div>
              <div style="float: right;" @click="onSwitchCamera" >
                <img src="@/assets/pages/meeting/21.png"/>
              </div>
          </div>
          <div class="meeting-body-right-input-text">
            <textarea v-model="inputMsg"></textarea>
          </div>
          <div class="meeting-body-right-input-btns">
            <button
              type="button"
              class="btn btn-success btn-sm w-38 float-end"
              @click="sendMsg('text', '')"
            >
              {{ $t('meeting.btntext') }}
            </button>
          </div>
        </div>
      </div>
      <div class="meeting-hide-msg-btn" @click="hideMsg">
        <i class="icon icon-right"></i>
      </div>
    </div>
  </div>
</template>
<script>
import TRTC from 'trtc-js-sdk';
import axios from 'axios';
import COS from 'cos-js-sdk-v5';

export default {
  // eslint-disable-next-line
  name: 'meeting-index',
  components: {

  },
  data() {
    return {
      emojiShow: false,
      emojiList: ['😁', '😃', '😊', '🥺', '😪', '😂', '😭', '😚', '👌', '👍', '👏', '🙏', '💪', '🌹', '🍵'],
      request: '',
      sdkAppId: 1400712175,
      userid: this.$route.query.userid,
      roomid: this.$route.query.roomid,
      isDoctor: this.$route.query.d || 0,
      userSig: '',
      client: '',
      msgList: [],
      inputMsg: '',
      cos: {},
      localStream: '',
      remoteStream: '',
      hideMsgContent: true,
      fullScreen: false,
      muteAudio: false,
      muteVideo: false,
      camerasLength: 1,
      cameras: '',
      cameraId: '',
    };
  },
  mounted() {
    this.request = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      timeout: 15000,
    });
    // check current environment is supported TRTC or not
    TRTC.checkSystemRequirements().then((checkResult) => {
      if (!checkResult.result) {
        // console.log('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
        this.$toast.show({
          title: this.$t('tips'),
          content: 'Your browser does not supported TRTC!',
          confirm: this.$t('login.confirm'),
          close: this.$t('login.cancel'),
          autohide: false,
        }).then(() => {});
      } else {
        TRTC.getCameras().then((cameras) => {
          this.cameras = cameras;
          this.cameraId = cameras[0].deviceId;
          this.camerasLength = cameras.length || 2;
        });
      }
    });
    let that = this;
    that.getUserSig();
    setInterval(() => {
      that.getMsgList();
    }, 2000);
    this.cos = new COS({
      // getAuthorization 必选参数
      getAuthorization: (options, callback) => {
        that.$api.oss.getCosToken().then((res) => {
          if (res.data.code == 200) {
            callback({
              TmpSecretId: res.data.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.data.credentials.tmpSecretKey,
              SecurityToken: res.data.data.credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: res.data.data.startTime,
              ExpiredTime: res.data.data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          }
        });
      },
    });
    // console.log('url', this.$route.fullPath);
  },
  methods: {
    hideMsg() {
      this.hideMsgContent = !this.hideMsgContent;
    },
    onChange(e) {
      let file = e.target.files[0];
      if (file) {
        let size = file.size / 1024; // b => kb
        if (size > (1024 * 15)) { // 5Mb
          return;
        }
        this.upload(file);
      }
    },
    upload(file) {
      const storeAs = `d86e/upload/file/msg/${+new Date()}.${file.name}`;
      this.cos.putObject(
        {
          Bucket: 'd86e-1300007815',
          Region: 'ap-hongkong',
          Key: storeAs,
          StorageClass: 'STANDARD',
          Body: file,
          onProgress: () => {},
        },
        (err, data) => {
          if (data) {
            this.sendMsg('file', `https://${data.Location}`, file.name);
          }
        },
      );
    },
    onSwitchCamera() {
      TRTC.getCameras().then((cameras) => {
        let device = cameras.find((camera) => camera.deviceId != this.cameraId);
        let cameraId = device.deviceId;
        this.cameraId = device.deviceId;
        this.localStream.switchDevice('video', cameraId).then(() => {
          // console.log('switch camera success');
          if (cameraId == cameras[1].deviceId) {
            this.localStream.play('local-stream', { mirror: false }).catch(() => { });
          } else {
            this.localStream.play('local-stream', { mirror: true }).catch(() => { });
          }
        });
      });
    },
    onChangeCamera() {
      if (this.muteVideo) {
        this.localStream.unmuteVideo();
        this.muteVideo = false;
      } else {
        this.localStream.muteVideo();
        this.muteVideo = true;
      }
    },
    onChangeMic() {
      if (this.muteAudio) {
        this.localStream.unmuteAudio();
        this.muteAudio = false;
      } else {
        this.localStream.muteAudio();
        this.muteAudio = true;
      }
    },
    emojiShowFun() {
      this.emojiShow = !this.emojiShow;
    },
    emojiSelectFun(emoji) {
      this.inputMsg += emoji;
      this.emojiShow = false;
    },
    createClient() {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.sdkAppId,
        userId: this.userid,
        userSig: this.userSig,
        enableAutoPlayDialog: false,
      });
      this.createStream();
    },
    getUserSig() {
      this.$api.meeting.getUserSig({ id: this.userid }).then((res) => {
        if (res.data.code == 200) {
          this.userSig = res.data.data;
          this.createClient();
        }
      });
    },
    n(str) {
      let res = 0;
      for (let i = 0; i < str.length; i++) {
        res += str.charCodeAt(i);
      }
      return res;
    },
    async createStream() {
      this.localStream = TRTC.createStream({ userId: this.userid, audio: true, video: true });
      this.localStream.on('error', (error) => {
        if (error.getCode() === 0x4043) {
          // 自动播放受限导致播放失败，此时引导用户点击页面。
          // 在点击事件的回调函数中，执行 stream.resume();
          setTimeout(() => {
            this.localStream.resume();
          }, 3000);
        }
      });

      // try {
      //   await this.localStream.play('local-stream').catch(() => {}); // 显示本地视频到div
      // } catch (error) {
      //   // console.error(`播放失败 ${error}`);
      //   if (error.getCode() === 0x4043) {
      //     // 自动播放受限导致播放失败，此时引导用户点击页面。
      //     // 在点击事件的回调函数中，执行 stream.resume();
      //   }
      // }

      // 监听远端流订阅成功事件
      this.client.on('stream-subscribed', (event) => {
        // const remoteStream = event.stream;
        // 远端流订阅成功，播放远端音视频流
        this.remoteStream.play('remote-stream');
      });
      // 远端流更新事件
      this.client.on('stream-updated', (event) => {
        this.remoteStream = event.stream;
        this.client.subscribe(this.remoteStream, { audio: true, video: true }).then(() => {
          this.remoteStream.play('remote-stream');
        });
      });
      // 监听远端流增加事件
      this.client.on('stream-added', (event) => {
        this.remoteStream = event.stream;
        // 订阅远端音频和视频流
        this.client.subscribe(this.remoteStream, { audio: true, video: true }).then(() => {
          // console.error('failed to subscribe remoteStream');
          setTimeout(() => {
            this.remoteStream.play('remote-stream');
          }, 500);
        });
        // 仅订阅音频数据
        // client.subscribe(remoteStream, { audio: true, video: false }).catch(e => {
        //  console.error('failed to subscribe remoteStream');
        // });
      });
      try {
        // console.log('this.roomid', this.n(this.roomid));
        await this.client.join({ roomId: this.n(this.roomid) });
        // console.log('进房成功');
      } catch (error) {
        // console.error(`进房失败 ${error}`);
      }
      try {
        this.localStream.initialize().then(() => {
          this.client.publish(this.localStream).then(() => {
            // console.log('本地流发布成功');
            this.localStream.setVideoProfile('480p_2'); // 视频分辨率
          });
        });
      } catch (error) {
        // console.error(`初始化本地流失败 ${error}`);
      }
      this.startMeeting(); // 弹窗有交互才开始播放，解决ios权限问题
      // 信令通道连接状态变化事件
      this.client.on('connection-state-changed', (event) => {
        // const prevState = event.prevState;
        const curState = event.state;
        // const remoteStream = event.stream;
        if (curState == 'CONNECTED') {
          this.client.publish(this.localStream).then(() => { // 本地流发布
            this.localStream.setVideoProfile('480p_2'); // 视频分辨率
          });
          this.client.subscribe(this.remoteStream, { audio: true, video: true }).then(() => {
            setTimeout(() => {
              this.remoteStream.play('remote-stream');
            }, 500);
          });// 订阅远端
        }
      });
    },
    startMeeting() {
      this.$toast.show({
        title: this.$t('tips'),
        content: this.$t('meeting.start'),
        confirm: this.$t('login.confirm'),
        // close: this.$t('login.cancel'),
        autohide: false,
      }).then(() => {
        this.showLocalStream();
      }).catch(() => {
        this.showLocalStream();
      });
    },
    showLocalStream() {
      try {
        this.localStream.play('local-stream').catch(() => { }); // 显示本地视频到div
        this.localStream.resume();
        this.client.publish(this.localStream).then(() => { }); // 本地流发布
        this.remoteStream.play('remote-stream'); // 播放远端视频
        this.remoteStream.resume();
      } catch (error) {
        // console.error(`播放失败 ${error}`);
        if (error && typeof error.getCode === 'function' && error.getCode() === 0x4043) {
          // 自动播放受限导致播放失败，此时引导用户点击页面。
          // 在点击事件的回调函数中，执行 stream.resume();
        }
      }
    },
    sendMsg(type, url = '', msg = this.inputMsg) {
      this.$api.meeting.sendMsg({
        booking_record_id: this.roomid,
        user_id: this.userid,
        msg,
        type,
        url,
      }).then((res) => {
        if (res.data.code == 200) {
          this.msgList = res.data.data.list.reverse();
          this.inputMsg = '';
        }
      });
    },
    getMsgList() {
      this.request({
        url: '/api/msg/list',
        method: 'post',
        data: { id: this.roomid, userid: this.userid },
      }).then((res) => {
        if (res.data.code == 200) {
          this.msgList = res.data.data.list.reverse();
        }
      });
    },
    downloadFile(url) {
      this.$toast.show({
        title: this.$t('tips'),
        content: this.$t('meeting.downloadFile'),
        confirm: this.$t('login.confirm'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then(() => {
        if (typeof plus == 'object') {
          // eslint-disable-next-line
          // uni.webView.navigateTo({ url: `/pages/webview/index?url=${url}` });

          // eslint-disable-next-line
          plus.runtime.openURL(url, (res) => {
            console.log(res);
          });
        } else {
          window.open(url, '_blank');
        }
      });
    },
    close() {
      this.localStream.stop('local-stream');
      this.localStream.close();
      this.localStream = null;
      this.client = null;
      if (typeof plus == 'object') {
        // eslint-disable-next-line
        uni.webView.navigateBack();
      } else if (this.isDoctor) {
        let time = +new Date();
        window.location.href = `https://www.bnbvet.com/admin?v=${time}`;
      } else {
        this.$router.push('/');
      }
    },
    fullScreenFun() {
      this.fullScreen = !this.fullScreen;
      if (this.fullScreen) {
        this.full(this.$refs.meeting);
      } else {
        this.exitFullscreen();
      }
    },
    full(ele) {
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullScreen) {
        document.exitFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        // eslint-disable-next-line
      } else if (element.msExitFullscreen) {
        // eslint-disable-next-line
        element.msExitFullscreen();
      }
    },
  },
};
</script>
<style>
@media (max-width: 767.98px){
  body {
    padding-bottom: 0px !important;
  }
}
</style>
<style lang="scss" scoped>
.meeting{
  height: 100vh;
  max-height: 800px;
  padding: 0px;
  &-header{
    width: 100%;
    height: 57px;
    line-height: 57px;
    background-color: #D6D6D6;
    .close{
      display: none
    }
    .full{
      display: block;
      width: 22px;
      cursor: pointer;
    }
  }
  &-body{
    height: calc( 100% - 57px );
    border: 1px solid #efefef;
    background-color: #fff;
    position: relative;
    &-left{
      width: 100%;
      height: 100%;
      z-index: 1;
      padding-right: 320px;
      position: relative;
      .video-box-1{
        width: 100%;
        height: 100%;
        background-color:#efefef;
        z-index: 1;
      }
      .video-box-2{
        width: 120px;
        height: 120px;
        background-color: #000;
        position: absolute;
        right:calc( 320px + 10px );
        top: 10px;
        z-index: 2;
        border-radius: 8px;
        overflow: hidden;
      }
    }
    &-right{
      width: 320px;
      height: 100%;
      border-left: 1px solid #efefef;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
      &-title{
        height: 48px;
        line-height: 48px;
        border-bottom:1px solid #efefef;
      }
      &-message{
        width: 100%;
        height: calc(100% - 150px - 48px);
        overflow-y: auto;
        color: #fff;
        display: flex;
        flex-direction: column-reverse;
        padding: 10px;
        .msg{
          padding: 10px;
          display: flex;
          &-head{
            border-radius: 8px;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            display: inline-block;
            background-color: rgba(#000, 0.1);
          }
          &-info{
            border-radius: 8px;
            max-width: calc(100% - 60px);
            padding: 10px;
            display: inline-block;
            text-align: left;
            background-color: #5FA99A;
            position: relative;
            cursor: pointer;
          }
          &-info:before{
            display:inline;
            content:'';
            position: absolute;
            left: -16px;
            border-style: solid;
            border-width: 8px 8px 8px 8px;
            border-color: rgba(#000, 0) #5FA99A rgba(#000, 0) rgba(#000, 0) ;
            width: 0px;
            height: 0px;
          }
        }
        .msg-right{
          text-align: right;
          flex-direction: row-reverse;
          .msg-head{
            margin-left: 10px !important;
            margin-right: 0px !important;
          }
          .msg-info:before{
            content:'';
            left: auto !important;
            right: -16px !important;
            border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, 0) #5FA99A !important;
          }
        }
      }
      &-input{
        width: 100%;
        height: 150px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        border-top: 1px solid #dfdfdf;
        z-index: 2;
        background-color: rgba($color: #fff, $alpha: 0.5);
        &-tools{
          height: 32px;
          line-height: 32px;
          padding: 0 10px;
          div{
            height: 32px;
            width: 32px;
            padding: 6px;
            display: inline-block;
            position: relative;
            img{
              width: 100%;
              height: 100%;
              vertical-align: top;
            }
            #file{
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              display: block;
              opacity: 0;
            }
          }
          &-emoji{
            position: absolute !important;
            top: 20px !important;
            width: 240px !important;
            height: 80px !important;
            border: 1px solid #ccc !important;
            background-color: #fff !important;
            z-index: 2 !important;
            border-radius: 4px !important;
            padding: 10px !important;
            box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 20%);
            span{
              margin: 4px;
              cursor: pointer;
            }
          }
        }
        &-text{
          width: 100%;
          height: calc(100% - 32px - 32px);
          textarea{
            width: 100%;
            height: 100%;
            border: 0px;
            outline: none;
            resize: none;
            padding: 0 10px;
            background-color: rgba($color: #fff, $alpha: 0.1);
            font-size: 16px !important;
          }
        }
        &-btns{
          padding: 0 20px;
          height: 32px;
          background-color: rgba($color: #fff, $alpha: 0.1);
          button{
            position: relative;
            top: -20px;
            background-color: #5FA99A;
            border-color: #5FA99A;
          }
        }
      }
    }

  }
  &-hide-msg-btn{
    position: absolute;
    left: -30px;
    top:50%;
    width: 60px;
    height: 60px;
    background-color: #5FA99A55;
    z-index: 99999;
    color: #fff;
    text-align: right;
    border-radius: 50%;
    line-height: 60px;
    padding: 0 2px;
    display: none;
    .icon-right{
      font-size: 32px;
    }
  }
}
  @media (max-width: 767.98px) {
    .meeting{
      max-height: 100vh;
      &-header{
        width: 100%;
        .close{
          display: block;
        }
        .full{
          display: none;
        }
      }
      &-body{
        background-color: #000;
        &-left{
          background-color: #000;
          padding-right: 0px;
          .video-box-2{
            right: 10px;
          }
        }
        &-right{
          width: 100%;
          &-title{
            display: none;
          }
          &-message{
            color: #fff;
            .msg{
              &-info{
                // background-color: rgba($color: #000000, $alpha: 0.1);
                img{
                  filter: invert(100%);
                }
              }
              &-info:before{
                color: rgba($color: #000000, $alpha: 0.1);
                border-color: rgba(#000, 0) rgba(#000, 0.1) rgba(#000, 0) rgba(#000, 0);
              }
            }
            .msg-right{
              .msg-info:before{
                // border-color: rgba(#000, 0) rgba(#000, 0) rgba(#000, 0) rgba(#000, 0.1) !important;
              }
            }
          }
          &-input{
            &-btns{
              button{
                opacity: 0.8;
              }
            }
          }
        }
      }
      &-hide-msg-btn{
        display: block;
      }
    }

  }
</style>
