<template>
<div class="model-bg position-fixed top-0 start-0" v-if="modelShow">
    <div class="model-model position-absolute top-50 start-50 translate-middle" :style="'--width:'+width">
        <div class="model-header position-absolute top-0 border-bottom">
          <div class="title">{{title}}</div>
          <button type="button" class="btn-close float-end position-absolute top-0 end-0 me-2 mt-2" aria-label="Close" @click="close"></button>
        </div>
        <div class="model-body">
            <div class="model p-2">
                <slot></slot>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'model-tpl',
  data() {
    return {
      modelShow: false,
    };
  },
  props: {
    width: {
      type: String,
      default: '600px',
    },
    title: {
      type: String,
      default: '',
    },
  },
  mounted() {
  },
  methods: {
    show() {
      this.modelShow = true;
    },
    hide() {
      this.modelShow = false;
    },
    close() {
      this.hide();
    },
  },
};
</script>

<style scoped lang="scss">

.model-bg{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .btn-close:focus{
        box-shadow: none;
    }
    @media (max-width: 767.98px) {
      .model-model{
        width: 100% !important;
        margin-top: -60px;
      }
    }
    .model-model{
        width: var( --width );
        max-width: 800px;
        max-height: 90%;
        overflow: hidden;
        z-index: 9;
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-color: #ffffff;
        .model-header{
          width: 100%;
          background-color: #ffffff;
          z-index: 99;
          .title{
            height: 46px;
            line-height: 46px;
            padding-left: 10px;
            font-size: 18px;
          }
        }
        .model-body{
          padding-top: 46px;
          max-height: calc( 100vh - 20vh );
          box-sizing: border-box;
          overflow-x: hidden;
          overflow-y: auto;
        }
    }
}

</style>
