<template>
  <div class="reserve">
    <reserve-tpl /> <!-- 预约 -->
  </div>
</template>
<script>

import reserveTpl from '@/components/reserve';

export default {
  // eslint-disable-next-line
  name: 'reserve-index',
  components: {

    reserveTpl,

  },
  data() {
    return {
    };
  },
  mounted() {
    this.$router.push('/booking');
  },
};
</script>
<style lang="scss" scoped>
  #reserve, .reserve {
    height: calc( 100vh - 60px );
  }
</style>
