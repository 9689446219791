<template>
  <div
    ref="content-page"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('pet.title') }}
          </div>
          <div class="btns">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              @click="onClickAdd"
            >
              <!-- <img
                src="@/assets/form/add.png"
                width="16"
              > -->
              {{ $t('pet.add') }}
            </button>
          </div>
        </div>
        <hr>
        <div class="content">
          <div
            v-for="(item,index) in currentList"
            :key="index"
            class="list border-bottom m-md-3 py-4 row"
          >
            <div class="col-3 text-end pt-2">
              <div
                class="rounded-circle d-inline-block overflow-hidden"
                style="width: 70px;height: 70px;background-color: rgba(0,0,0,0.1);float:right"
              >
                <img
                  style="width: 70px;height: 70px;object-fit: cover;"
                  :src="item.avatar_thumbnail"
                >
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-md">
                  {{ $t('pet.name') }}：{{ item.name }}
                </div>
                <div class="col-md">
                  {{ $t('pet.pettype') }}：{{ i18n(item, 'pet_kind_name') }}
                </div>
              </div>
              <div class="row">
                <div class="col-md">
                  {{ $t('pet.birthday') }}：{{ item.birthday }}
                </div>
                <div class="col-md">
                  {{ $t('pet.neutered') }}：{{ item.is_neutered == 1 ? $t('pet.yes') : ( item.is_neutered == 2 ? $t('pet.no') : $t('pet.unknown') ) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md">
                  {{ $t('pet.gender') }}：{{ item.gender == 1 ? $t('pet.male') : $t('pet.female') }}
                </div>
                <div class="col-md">
                  {{ $t('pet.breed') }}：{{ item.breeds }}
                </div>
              </div>
              <div class="row">
                <div class="col-md">
                  {{ $t('pet.blood') }}：{{ item.blood_type }}
                </div>
                <div class="col-md">
                  {{ $t('pet.weight') }}：{{ item.weight }}
                </div>
              </div>
            </div>
            <div class="col-3">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark mt-1 me-md-2"
                @click="onClickEdit(item.id)"
              >
                <!-- <img
                  src="@/assets/form/edit.png"
                  width="16"
                > -->
                {{ $t('pet.edit') }}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-dark mt-1"
                @click="onClickDel(item)"
              >
                <!-- <img
                  src="@/assets/form/del.png"
                  width="16"
                > -->
                {{ $t('pet.del') }}
              </button>
            </div>
          </div>
          <pages
            :total="total"
            :pagesize="pageSize"
            :page="currentPage"
            @changePage="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pages from '@/components/page';

export default {
  // eslint-disable-next-line
  name: 'member-pet',
  components: {
    pages,
  },
  data() {
    return {
      list: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    currentList() {
      return this.list;
      // return this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    },
  },
  mounted() {
    this.getPetList();
  },
  methods: {
    getPetList() {
      this.$api.pet.getPetList({ page_size: this.pageSize, current_page: this.currentPage }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.list.reverse();
          this.pageSize = res.data.data.pages.page_size;
          this.currentPage = res.data.data.pages.current_page;
          this.total = res.data.data.pages.total;
          this.$api.pet.getPetKind().then((kind) => {
            if (kind.data.code == 200) {
              this.list.forEach((element) => {
                let item = kind.data.data.list.find((k) => k.id === element.pet_kind_id);
                if (item) {
                  if (!element.avatar_thumbnail) {
                    if (item.english_name.indexof('Cat') >= 0 || item.english_name.indexof('cat') >= 0) {
                      element.avatar_thumbnail = require('@/assets/pages/801.jpg');
                    } else if (item.english_name.indexof('Dog') >= 0 || item.english_name.indexof('dog') >= 0) {
                      element.avatar_thumbnail = require('@/assets/pages/802.jpg');
                    } else {
                      element.avatar_thumbnail = require('@/assets/pages/803.jpg');
                    }
                  } else {
                    element.avatar_thumbnail += '?imageMogr2/crop/70x70';
                  }
                  element.pet_kind_name = this.i18n(item, 'name');
                }
              });
            }
          });
        }
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.$refs['content-page'].scrollTop = 0;
    },
    onClickAdd() {
      this.$router.push('/member/pet/edit');
    },
    onClickEdit(id) {
      this.$router.push(`/member/pet/edit?pet_id=${id}`);
    },
    onClickDel(pet) {
      this.$toast.show({
        title: this.$t('tips'),
        content: `${this.$t('pet.confirmDel')}: ${pet.name}?`,
        confirm: this.$t('pet.del'),
        close: this.$t('login.cancel'),
        autohide: false,
      }).then((_) => {
        this.$api.pet.deletePet({ id: pet.id }).then(() => { this.getPetList(); });

        // this.$store.dispatch('setUserInfo', {});
        // this.$router.push('/user/login');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
      .container{
        padding: 30px;
        .list{
          border-bottom: 1px solid #efefef;
          padding: 20px 10px;
          color: var( --bs-gray-600 );
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .content-padding-left{
      padding-left: 0px;
      padding-right: 0px;
      .container{
        padding: 10px 4px;
        .title{
          padding: 10px;
        }
        .btns{
          padding-right: 16px;
          padding-top: 10px;
        }
        .list{
          border-bottom: 1px solid #efefef;
          padding: 20px 0px;
          color: var( --bs-gray-600 );
          margin: 2px;
        }
      }
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
      .btn-outline-dark:hover {
        color: #212529;
        background-color: var( --bs-gray-200 );
      }
    }
  }
}
</style>
