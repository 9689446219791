<template>
  <div class="booking">
    <div class="container-md">
      <div class="container pt-2 p-md-4 text-center">
        <div class="d-flex m-auto mb-5 switch-box" v-if="!specialist_id">
          <div class="title flex-grow-1 fs-6">
            <span class="switch-btn active">{{ $t('booking.title2') }}</span>
          </div>
          <div class="title flex-grow-1 fs-6">
            <span @click="goToEasy" class="switch-btn">{{ $t('booking.title') }}</span>
          </div>
        </div>
        <div class="content">

          <doctor-select @changeSelect="selectDoctor" @recheckSelect="recheckSelect" :topPage="topPage"
            :sid="form.doctor_id" :specialist_id="specialist_id"></doctor-select>
          <hr>
          <div v-show="form.time">
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.pet') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                <div class="d-inline-block me-1 mt-1" v-for="(item, index) in petList" :key="index">
                  <input :id="'radio-id-' + index" v-model="form.pet_id" @change="onPetSelect(item)" type="radio"
                    class="btn-check" name="pet" :value="item.id">
                  <label class="btn btn-outline-secondary btn-sm" :for="'radio-id-' + index">
                    <div style="width: 70px;height: 70px;overflow: hidden;border-radius: 50%;">
                      <img
                        :src="item.avatar ? (item.avatar + '?imageMogr2/crop/70x70') : (item.pet_kind_id == '06d80eb0c50b49a509b49f2424e8c805' ? require('@/assets/pages/802.jpg') : (item.pet_kind_id == 'd077f244def8a70e5ea758bd8352fcd8' ? require('@/assets/pages/801.jpg') : require('@/assets/pages/803.jpg')))" />
                    </div>
                    {{ item.name }}
                  </label>
                </div>
                <button type="button" class="btn btn-sm btn-outline-dark  mt-1" @click="onShowModel"
                  style="width: 88px;height: 101px;vertical-align: top;border: 1px solid #ddd;">
                  <img src="@/assets/form/add.png" width="16">
                  {{ $t('pet.add') }}
                </button>
              </div>
            </div>

            <div class="row g-2 text-start text-md-end mt-2">
              <label class="col-form-label col-md-2">
                {{ $t('booking.voucher') }}：
              </label>
              <div class="col-md-4 pt-md-1 text-start  position-relative">
                <input class="form-control form-control-sm col-md-4" type="text" name="voucher" v-model="voucher"
                  @blur="onApplyVoucher">
                <b style="cursor: pointer;" class="position-absolute end-0 top-0 mt-2 me-3 text-black-50 user-select-none"
                  @click="onApplyVoucher">{{ $t('booking.apply') }}</b>
              </div>
            </div>

            <hr>
            <!-- <div class="title flex-grow-1 fs-5">
              {{ $t('booking.title2') }}
            </div> -->
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.confirmDoctor') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                {{ i18n(doctor, 'name') }}
              </div>
            </div>
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.confirmTime') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                {{ form.date }} {{ form.time }}
              </div>
            </div>
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.confirmPrice') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                HK${{ form.recheck ? doctor.recheck_fee : doctor.registered_fee }}
              </div>
            </div>
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.discountAmount') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                HK${{ discount && discount || 0 }}
              </div>
            </div>
            <!-- <div class="row g-2 text-start text-md-end">
              <label
                class="col-form-label col-md-2">
                {{ $t('booking.confirmServiceFee') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                $0
              </div>
            </div> -->
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.confirmTotalPrice') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                HK${{ price }}
              </div>
            </div>
            <div class="row g-2 text-start text-md-end">
              <label class="col-form-label col-md-2">
                {{ $t('booking.paytype') }}：
              </label>
              <div class="col-md-10 pt-md-1 text-start">
                <pay-btns @selected="paySelect" />
              </div>
            </div>
            <!-- <div class="row g-2 text-start text-md-end">
              <label
                class="col-form-label col-md-2">
                {{ $t('booking.prompt') }}：
              </label>
              <div class="col-md-10 text-start pt-1">
                {{ $t('booking.promptText') }}
              </div>
            </div> -->
            <hr>

            <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
              <div class="col-md-12">
                <!-- <button
                  type="button"
                  class="btn btn-secondary btn-sm me-2 px-5"
                  @click="cancelPay"
                >
                  {{ $t('booking.cancel') }}
                </button> -->
                <button type="button" class="btn btn-success btn-sm px-5" @click="confirmPay">
                  {{ $t('booking.paynow') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <petModel :width="'700px'" ref="petModel" :title="$t('pet.title2')">
      <pet-form @success="successAdd" @cancel="cancelAdd" />
    </petModel>

  </div>
</template>
<script>
import petForm from '@/pages/member/pet/pet-form';
import payBtns from '@/components/pay-btns';
import doctorSelect from '@/components/doctor-select';
import petModel from '@/components/model';
import loading from '@/components/loading';

function loadEftScript() {
  const src = 'https://library-checkout.spiralplatform.com/js/v2/spiralpg.min.js';
  const id = 'eft-sdk';
  const exist = document.getElementById(id);
  if (exist) {
    return Promise.resolve(true);
  }
  const { head } = document;
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = function () {
      resolve(true);
    };
  });
}

export default {
  // eslint-disable-next-line
  name: 'booking-index',
  components: {
    petForm,
    payBtns,
    doctorSelect,
    petModel,
  },
  data() {
    return {
      petList: [],
      record: '',
      price: '0',
      voucher: '',
      discount: '0',
      payType: 'paypal',
      sessionId: '',
      specialist_id: this.$route.query.s || localStorage.getItem('s') || '',
      form: {
        consult_method: 'Website',
        pet_kind_id: '',
        pet_id: null,
        pet_name: '',
        doctor_id: this.$route.query.sid,
        date: '',
        time: '',
        recheck: 1,
      },
      doctor: '',
    };
  },
  props: {
    topPage: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  watch: {
    'form.recheck': {
      handler(newData, oldData) {
        if (this.doctor) {
          this.price = this.form.recheck ? this.doctor.recheck_fee : this.doctor.registered_fee;
        }
      },
    },
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768 ? 1 : 0;
    },
  },
  async created() {
    await loadEftScript();
  },
  mounted() {
    if (this.$store.state.hasLogin == false) {
      // this.$router.push('/user/login');
    } else {
      this.getPetList();
    }
    if (this.$route.query.sid) {
      // console.log(this.$route.query.sid);
      this.form.doctor_id = this.$route.query.sid;
    }
    let s = localStorage.getItem('s');
    if (this.$route.query.s) {
      // console.log(this.$route.query.sid);
      this.specialist_id = this.$route.query.s;
      localStorage.setItem('s', this.specialist_id);
    } else if (s) {
      this.specialist_id = s;
    }
    if (this.topPage == true) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  methods: {
    successAdd() {
      this.$refs.petModel.hide();
      this.getPetList();
    },
    cancelAdd() {
      this.$refs.petModel.hide();
    },
    onShowModel() {
      this.$refs.petModel.show();
    },
    onPetSelect(pet) {
      this.form.pet_kind_id = pet.pet_kind_id;
      this.form.pet_name = pet.name;
    },
    goToEasy() {
      if (this.topPage) {
        this.$router.push('/easy/booking');
      } else {
        this.$emit('changePageEvent', 'easy');
      }
    },
    recheckSelect(index) {
      this.form.recheck = index;
    },
    selectDoctor(obj) {
      this.form.doctor_id = obj.doctor_id;
      this.form.time = obj.time;
      this.form.date = obj.date;
      this.doctor = obj.doctor;
      this.form.recheck = obj.recheck;
      // console.log(this.doctor.recheck_fee, this.doctor.registered_fee);
      // console.log(obj.recheck);
      this.price = this.form.recheck ? this.doctor.recheck_fee : this.doctor.registered_fee;
      // console.log(this.form.recheck, this.doctor.recheck_fee, this.doctor.registered_fee);
      // this.getInstantConnectPrice();
    },
    getPetList() {
      this.$api.pet.getPetList().then((res) => {
        if (res.data.code == 200) {
          this.petList = res.data.data.list.reverse();
          if (this.petList.length) {
            this.form.pet_kind_id = this.petList[0].pet_kind_id;
            this.form.pet_id = this.petList[0].id;
            this.form.pet_name = this.petList[0].name;
          }
        }
      });
    },
    getInstantConnectPrice() {
      if (this.form.doctor_id) {
        this.$api.booking.getInstantConnectPrice({
          instant_connect: 0,
          voucher_code: this.voucher,
          date: this.form.date,
          time: this.form.time,
          recheck: this.form.recheck,
          doctor_id: this.form.doctor_id,
        }).then((res) => {
          if (res.data.code == 200) {
            this.price = (res.data.data.total_price - 0).toFixed(2);
            this.discount = (res.data.data.voucher_amount - 0).toFixed(2);
          } else {
            this.$toast.show(res.data.msg);
          }
        });
      }
    },
    paySelect(type) {
      this.payType = type;
    },
    confirmPay() {
      if (this.payType == 'paypal') {
        this.onClickPaypal();
      } else if (
        this.payType == 'alipay_hk'
        || this.payType == 'alipay_cn'
        || this.payType == 'vm'
        || this.payType == 'unionpay'
        || this.payType == 'mppay'
      ) {
        this.onEftClick(this.payType);
      }
    },
    cancelPay() {
      this.$router.push('/reserve');
    },
    async book() {
      if (!this.$store.state.hasLogin) {
        this.$router.push('/user/login');
        return;
      }

      // 订单已创建过
      if (this.record) { return; }
      if (!this.price) { return; }
      if (!this.form.time) { this.$toast.show(this.$t('booking.select_time')); return; }
      if (!this.form.pet_kind_id) { this.$toast.show(this.$t('booking.select_pet')); return; }
      let pet = this.petList.find((item) => item.pet_id === this.form.pet_kind_id);

      if (!this.form.pet_id) { this.$toast.show(this.$t('booking.select_pet')); return; }
      this.form.voucher_code = this.voucher;
      let res = await this.$api.booking.addBooking(this.form).catch(() => null);
      if (res && res.data.code == 200) {
        this.record = res.data.data;
      }
    },
    onApplyVoucher() {
      // if (!this.voucher) { this.$toast.show(this.$t('booking.input_voucher')); return; }
      this.getInstantConnectPrice();
      // await this.book();
      // if (this.record && this.record.booking_record_id) {
      //   let res = await this.$api.booking.useBookingVoucher({
      //     booking_record_id: this.record.booking_record_id,
      //     voucher_code: this.voucher,
      //   }).catch(() => null);

      //   if (res && res.data && res.data.data) {
      //     // 更新record
      //     this.record = res.data.data;
      //   } else {
      //     this.$toast.show(res.data.msg);
      //   }
      // }
    },
    async onClickPaypal() {
      await this.book();
      if (this.record) {
        localStorage.setItem(`payStatus-${this.record}`, '1');
        let res = await this.$api.payment.createBookingPaypal({ booking_record_id: this.record }).catch(() => null);
        if (res && res.data && res.data.data) {
          if (typeof plus == 'object') {
            // eslint-disable-next-line
            uni.webView.navigateTo({ url: `/pages/webview/index?url=${res.data.data.url}` });
          } else {
            window.location.href = res.data.data.url;
          }
        }
      }
    },
    async onEftClick(payType) {
      await this.book();
      if (this.record) {
        localStorage.setItem(`payStatus-${this.record}`, '1');
        let type = 3;
        if (payType == 'alipay_cn') {
          type = 3;
        } else if (payType == 'alipay_hk') {
          type = 4;
        } else if (payType == 'mppay') {
          type = 5;
        } else if (payType == 'vm') {
          type = 6;
        } else if (payType == 'unionpay') {
          type = 7;
        }
        let res = await this.$api.payment.createBookingEft({
          id: this.record, in_app: this.isMobile, order_payment: 1, pay_type: type,
        });
        // console.log(res.data);
        if (res && res.data && res.data.code == 200) {
          try {
            loading.loading.show();
            // eslint-disable-next-line
            SpiralPG.init(res.data.data);
          } catch (err) {
            null;
          }
          this.eftPay(1);
        } else {
          loading.loading.hide();
          this.$toast.show(this.$t('payment.105'));
        }
      }
    },
    eftPay(times) {
      if (times > 15) {
        loading.loading.hide();
        return this.$toast.show(this.$t('payment.105'));
      }
      let globalThis = this;
      try {
        loading.loading.show();
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
          // globalThis.$toast.show(globalThis.$t('payment.104'));
        }, 1500);
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 3000);
        setTimeout(() => {
          // eft有时会很久才跳，其实已经成功了的
          loading.loading.hide();
        }, 5000);
      } catch (err) {
        times++;
        globalThis.eftPay(times);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.booking {
  padding-bottom: 100px;

  .container {
    background-color: #ffffff;

    .title {
      cursor: pointer;
    }
    .switch-box{
      width: 320px;
      background-color: #eee;
      border-radius: 10px;
      padding: 2px;
    }
    .switch-btn{
      position: relative;
      padding: 4px;
      border-radius: 6px;
      display: block;
      text-align: center;

    }
    .active {
      background-color: #fff;
      color: green;
    }

    .active::before {
      content: '';
      // display: block;
      // width: 100%;
      // height: 4px;
      // background-color: green;
      // position: absolute;
      // bottom: -10px;
      // left: 0px;
    }

    hr {
      background-color: var(--bs-gray-600);
    }

    .btn-outline-dark:hover {
      color: #212529;
      background-color: var(--bs-gray-200);
    }

    .form-control[readonly] {
      background-color: #ffffff;
      cursor: pointer;
    }
  }

  .model {
    width: 80%;
    max-width: 600px;
    max-height: 100%;
    overflow-y: auto;
    z-index: 9;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  @media (max-width: 767.98px) {
    .pt-md-1 {
      margin-top: -4px;
      margin-bottom: 10px;
      padding-left: 80px;
    }

    .model {
      width: 100% !important;
    }
    .switch-box{
      width: 100% !important;
    }
  }

}
</style>
