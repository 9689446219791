<template>
  <div class="banner">
    <img
      :src="bg"
      class="img-fluid"
    >
    <div :class="['banner-content', textColor && 'light']">
      <div class="banner-content-box mb-4">
        <div :class="['banner-content-box-title', 'fw-bold',( $route.path == '/about' || $route.path == '/contactus' ) && 'blend' ]" v-html="title">
        </div>
        <div :class="['banner-content-box-line', $route.path == '/service' ||
          smallScreen && $route.path == '/'  && 'blend' ]" :style="[$route.path == '/contactus' && 'text-shadow: 0px 0px 8px #f00;color: #000;',  $route.path == '/service' && 'width: 220px']">
          {{ content }}
        </div>
      </div>
      <div class="banner-content-btns" v-if="$route.path != '/'">
        <button
          type="button"
          :class="['btn', 'btn-banner', textColor && 'light', bgLight && 'bg-light']"
          @click="onClick"
        >
          {{ btnText }}
          <span class="icon icon-toright fs-4 align-middle"></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
// Banner
export default {
  name: 'BannerTpl',
  props: {
    bg: {
      type: String,
      default() {
        return require('@/assets/banner/1.png');
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    content: {
      type: String,
      default() {
        return '';
      },
    },
    btnText: {
      type: String,
      default() {
        return '';
      },
    },
    textColor: {
      type: String,
      default() {
        return '';
      },
    },
    bgLight: {
      type: String,
      default() {
        return '';
      },
    },
  },
  methods: {
    onClick() {
      this.$router.push('/reserve');
    },
  },
  mounted() {
    // console.log(this.$route.path);
  },
};
</script>
<style lang="scss" scoped>
.banner{
  position: relative;
  min-height: 38vw;
  .img-fluid{
    width: 100%;
  }
  .banner-content{
    position: absolute;
    left: 17%;
    top: 29.5%;
    font-size: 1.2rem;
    // color: hsl(0, 0%, 100%);
    font-weight: 300;
    // line-height: 4.2vw;
    .banner-content-box-line{
      font-weight: 200;
      font-size: 1rem;
    }
    .blend{
      mix-blend-mode: difference;
      color: #fff;
    }
    .btn-danger{
      background-color: #89503D;
      border-color: #89503D;
    }
  }
  .btn-banner{
    border: 1px solid #5FA99A;
    border-radius: 2rem;
    padding: 0 0.72rem;
    color: #5FA99A;
    // background-image: url("@/assets/banner/btn.png");
    // background-repeat: no-repeat;
    // background-size: auto 40%;
    // background-position-x: 90%;
    // background-position-y: 0.7rem;
  }
  .light{
    color: #fff !important;
    border-color:#fff !important;
  }
  .bg-light{
    background-color: #5FA99A !important;
    border-color:#5FA99A !important;
    color: #fff !important;
  }
  @media (max-width: 767.98px) {
    .banner-content{
      left: 10%;
      top: 20%;
      // max-width: 220px;
      .btn-banner{
        font-size:0.6rem
      }
    }
  }
}
.en{
  .banner{
    .banner-content-box-title{
      max-width: 700px;
    }
  }
}
</style>
