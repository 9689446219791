<template>
<div class="bg-light">
    <div class="container-md p-md-5 when">
        <h1 class="text-left py-3">
          {{ $t('home.experience.when') }}
        </h1>
        <div class="row" v-for="(line,key) in whenCart" v-bind:key="key">
          <div class="col-md" v-for="(item,index) in line" v-bind:key="index">
            <div class="item-card">
              <div class="item-card-title"><h4>{{ $t('home.experience.when_cart.'+key+'.'+index+'.title') }}</h4></div>
              <div class="item-card-body">{{ $t('home.experience.when_cart.'+key+'.'+index+'.body') }}</div>
            </div>
          </div>
        </div>

    </div>
</div>
</template>
<script>
// 什么时候需要兽医
export default {
  name: 'when-tpl',
  computed: {
    whenCart() {
      let list = [];
      for (let index = 0; index < 4; index++) {
        list.push([]);
        list[index].push({});
        list[index].push({});
      }
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
.when{
  padding-bottom: 10px;
  .row>*{
    margin: 1px;
    background-color: #fff;
    .item-card{
      background-color: #fff;
      padding: 28px;
    }
  }
  @media (max-width: 767.98px) {
    .row>*{
      margin: 0.2rem 1px;
      background-color: rgba(0,0,0,0);
      .item-card{
        margin-top:4px;
        border-radius: 8px;
      }
    }
  }
}
</style>
