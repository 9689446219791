<template>
  <div class="container-content bg-light">
    <div class="container-md content-padding-left">
      <div class="d-flex">
        <div class="title flex-grow-1 fs-5">
          {{ title }}
        </div>
        <div class="btns" />
      </div>
      <hr>
      <pet-form
        :pet_id="pet_id"
        @success="success"
        @cancel="cancel"
      />
    </div>
  </div>
</template>
<script>
import petForm from './pet-form';

export default {
  // eslint-disable-next-line
  name: 'member-pet-edit',
  components: {
    petForm,
  },
  data() {
    return {
      title: '',
      pet_id: this.$route.query.pet_id,
    };
  },
  mounted() {
    if (this.pet_id) {
      this.title = this.$t('pet.title3'); // 編輯
    } else {
      this.title = this.$t('pet.title2'); // 新增
    }
  },
  methods: {
    success() {
      setTimeout((_) => {
        this.$router.push('/member/pet');
      }, 1000);
    },
    cancel() {
      this.$router.push('/member/pet');
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  padding-bottom: 100px;
  // height: calc( 100vh - 60px );
  // overflow-y: auto;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;

  }
}
</style>
