<template>
<div class="model-bg position-fixed top-0 start-0" v-if="modelShow">
    <div class="calendar-model position-absolute top-50 start-50 translate-middle" >
        <div class="model-body">
            <button type="button" class="btn-close float-end position-absolute mt-2 end-0 me-2 index-9" aria-label="Close" @click="close"></button>
            <div class="calendar">
                <div class="date">
                <div class="head">
                    <div class="icon switch-left fs-5" @click="switch_month_week('prev',true)">&#60;</div>
                    <div class="title">{{nowYear+' '+$t('calendar.year')+' '+nowMonth+' '+$t('calendar.month')}}</div>
                    <div class="icon switch-right fs-5" @click="switch_month_week('next',true)">&#62;</div>
                </div>
                <div class="date_dl" >
                    <div class="dd" v-for="(item,index) in week" :key="index">{{item}}</div>
                </div>
                <div :style="{height:(retract ? 2 * 80 : (week_list.length + 1) * 80 ) + 'rpx'}" :current="current" circular @change="change_date">
                    <!-- <div>

                    <div class="date_dl" v-show="!retract || index == to_prev_week_index" v-for="(item,index) in   week_list_prev_co" :key="index">
                        <div class="dd" @click="item_click(vo,index,key)" v-for="(vo,key) in item" :key="key">
                        <div class="num" :class="[vo.today ? 'today' : '',vo.type == 'month' ? 'month' : (retract ? '' : 'disabled'), vo.dot && (vo.type == 'month' || retract) && 'dot']">{{vo.day}}
                            <div class="num-subscript" v-show="vo.dot && (vo.type == 'month' || retract)">{{vo.quantity}}</div>
                        </div>
                        </div>
                    </div>
                    <div @click="open" class="retract icon"><text class="icon" :class="[retract ? 'icon-unfold' : 'icon-fold']" /></div>
                    </div> -->
                    <div>

                    <div class="date_dl" v-show="!retract || index == to_week_index" v-for="(item,index) in week_list" :key="index">
                        <div class="dd" @click="item_click(vo,index,key)" v-for="(vo,key) in item" :key="key">
                        <div class="num" :class="[vo.today ? 'today' : '',vo.type == 'month' && !vo.disabled ? 'month' : (retract || !vo.disabled ? '' : 'disabled'), vo.dot && (vo.type == 'month' || retract) && 'dot']">{{vo.day}}
                            <div class="num-subscript" v-show="getQuantity(vo.date) && (vo.type == 'month' || retract)">{{getQuantity(vo.date)}}</div>
                        </div>
                        </div>
                    </div>
                    <div @click="open" class="retract icon"><text class="icon" :class="[retract ? 'icon-unfold' : 'icon-fold']" /></div>
                    </div>
                    <!-- <div>

                    <div class="date_dl" v-show="!retract || index == to_next_week_index" v-for="(item,index) in  week_list_next_co" :key="index">
                        <div class="dd" @click="item_click(vo,index,key)" v-for="(vo,key) in item" :key="key">
                        <div class="num" :class="[vo.today ? 'today' : '',vo.type == 'month' ? 'month' : (retract ? '' : 'disabled'), vo.dot && (vo.type == 'month' || retract) && 'dot']">{{vo.day}}
                            <div class="num-subscript" v-show="vo.dot && (vo.type == 'month' || retract)">{{vo.quantity}}</div>
                        </div>
                        </div>
                    </div>
                    <div @click="open" class="retract icon"><text class="icon" :class="[retract ? 'icon-unfold' : 'icon-fold']" /></div>
                    </div> -->
                </div>
                </div>

            </div>
            <div class="select-box">
              <div class="col-md-10 sm-line-height text-start">
                <div v-for="(dateObject, index) in dateObjectList" :key="index">
                  <div v-if="dateObject.book_available == 1">
                    <div class="d-inline-block me-3 mt-1">{{ dateObject.date }}</div>
                    <div class="d-inline-block me-1 mt-1" v-for="(item, index) in dateObject.time_list" :key="index">
                      <input :id="'doctorTime' + index + dateObject.date + item.time" v-model="form.time"
                      type="radio" class="btn-check" name="doctorTime" :value="index + dateObject.date + item.time" @change="onChange(dateObject.date)"
                      :disabled="!item.book_available"  v-if="item.book_available">
                      <label class="btn btn-outline-secondary btn-sm" :for="'doctorTime' + index + dateObject.date + item.time"  v-if="item.book_available" @click="clickDate">{{ item.time }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
  // eslint-disable-next-line
  name: 'calendar',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    formTime: {
      type: [String, Number],
      default: '',
    },
    schedule_list: {
      type: Object,
      default: () => {},
    },
    dateObjectList: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      debug: false,
      week: [this.$t('calendar.sun'), this.$t('calendar.mon'), this.$t('calendar.tues'), this.$t('calendar.wed'), this.$t('calendar.thur'), this.$t('calendar.fri'), this.$t('calendar.sat')],
      week_list: [],
      week_list_prev: [],
      week_list_prev_week: [],
      week_list_next: [],
      week_list_next_week: [],
      now_date: '',
      start_date: '',
      end_date: '',
      prev_date: '',
      next_date: '',
      nowYear: '',
      nowMonth: '',
      nowDay: '',
      retract: false,
      to_week_index: 0,
      to_prev_week_index: 0,
      to_next_week_index: 0,
      nowTime: 0,
      dot_list: [],
      current: 1,
      date: '',
      form: {
        time: '',
      },
      modelShow: false,
    };
  },
  watch: {
    value(value) {
      this.get_date(this.date_parse(value));
    },
    dot_list: {
      immediate: true,
      handler(value) {
        this.set_doc_lists_update();
      },
    },
    formTime(date) {
      this.form.time = date;
    },
    schedule_list: {
      immediate: true,
      handler(value) {
        this.set_doc_lists_update();
      },
    },
  },
  computed: {
    week_list_prev_co() {
      return this.retract ? this.week_list_prev_week : this.week_list_prev;
    },
    week_list_next_co() {
      return this.retract ? this.week_list_next_week : this.week_list_next;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show() {
      this.modelShow = true;
    },
    hide() {
      this.modelShow = false;
    },
    close() {
      this.hide();
    },
    change() {
      let value = {
        fulldate: this.date.replace(/-(\d)(?!\d)/g, '-0$1'),
      };

      this.$emit('change', value);
    },

    init() {
      if (this.value) {
        this.get_date(this.date_parse(this.value));
      } else {
        this.get_date();
      }

      this.doc_list_update();
      this.update_month();
    },
    open() {
      this.retract = !this.retract;
      this.get_date(this.nowTime);
      this.set_to_day('week_list_prev');
      this.set_to_day('week_list_next');

      this.change_week();

      if (this.retract) {
        this.update_swiper_item('week');
      } else {
        this.update_swiper_item('month');
      }
      this.set_doc_lists_update();
    },
    clickDate() {
      setTimeout(() => {
        this.hide();
      }, 200);
    },
    getQuantity(date) {
      return this.schedule_list[date.replace(/-(\d)(?!\d)/g, '-0$1')] ? this.schedule_list[date.replace(/-(\d)(?!\d)/g, '-0$1')].quantity : 0;
    },
    change_week() {
      if (this.to_week_index < this.week_list.length - 1) {
        this.to_next_week_index = this.to_week_index + 1;
        this.week_list_next_week = this.week_list;
      } else {
        this.to_next_week_index = 0;
        this.week_list_next_week = this.week_list_next;
      }

      if (this.to_week_index == 0) {
        this.update_month();

        // if(){
        let next_day = this.week_list_prev[this.week_list_prev.length - 1][6].day;

        // }
        this.to_prev_week_index = this.week_list_prev.length - 1 - Math.ceil(next_day / 7);

        this.week_list_prev_week = JSON.parse(JSON.stringify(this.week_list_prev));
      } else {
        this.to_prev_week_index = this.to_week_index - 1;
        this.week_list_prev_week = this.week_list;
      }
    },
    change_date_week(type) {
      let { week_list } = this;
      let { to_week_index } = this;
      if (type == 'prev') {
        this.to_week_index = this.to_prev_week_index;
        this.to_prev_week_index = this.to_next_week_index;
        this.to_next_week_index = to_week_index;

        this.week_list = this.week_list_prev_week;
        this.week_list_prev_week = this.week_list_next_week;
        this.week_list_next_week = week_list;
      } else if (type == 'next') {
        this.to_week_index = this.to_next_week_index;
        this.to_next_week_index = this.to_prev_week_index;
        this.to_prev_week_index = to_week_index;

        this.week_list = this.week_list_next_week;
        this.week_list_next_week = this.week_list_prev_week;
        this.week_list_prev_week = week_list;
      }

      this.set_to_day_all();
    },
    change_date_month(type) {
      let { week_list } = this;
      if (type == 'prev') {
        this.week_list = this.week_list_prev;
        this.week_list_prev = this.week_list_next;
        this.week_list_next = week_list;
      } else if (type == 'next') {
        this.week_list = this.week_list_next;
        this.week_list_next = this.week_list_prev;
        this.week_list_prev = week_list;
      }
    },
    change_date(e) {
      let primary_current = this.current;
      let { current } = e.detail;

      this.current = current;

      if (primary_current - current == -1 || primary_current - current == 2) {
        if (this.retract) {
          this.switch_month_week('next');
          this.change_week();
          if (primary_current - current == -1 && current != 1) {
            this.change_date_week('prev');
          } else if (primary_current - current == 2) {
            this.change_date_week('next');
          }
        } else {
          this.get_date(this.get_month('next'));
          this.update_month();
          if (primary_current - current == -1 && current != 1) {
            this.change_date_month('prev');
          } else if (primary_current - current == 2) {
            this.change_date_month('next');
          }
        }
      } else if (this.retract) {
        this.switch_month_week('prev');
        this.change_week();
        if (primary_current - current == 1 && current != 1) {
          this.change_date_week('next');
        } else if (primary_current - current == -2) {
          this.change_date_week('prev');
        }
      } else {
        this.get_date(this.get_month('prev'));
        this.update_month();
        if (primary_current - current == 1 && current != 1) {
          this.change_date_month('next');
        } else if (primary_current - current == -2) {
          this.change_date_month('prev');
        }
      }

      this.set_to_day_all();
      this.set_doc_lists_update();
      this.change();
    },
    update_month() {
      this.get_date(this.get_month('prev'), 'prev');
      this.get_date(this.get_month('next'), 'next');
    },
    set_doc_lists_update() {
      this.doc_list_update('week_list');
      this.doc_list_update('week_list_prev');
      this.doc_list_update('week_list_next');
      this.doc_list_update('week_list_prev_week');
      this.doc_list_update('week_list_next_week');
    },
    doc_list_update(week_list = 'week_list') {
      let list = [];

      this[week_list].map((item, index) => {
        list.push(item.map((vo, key) => {
          if (this.dot_list.indexOf(vo.date) > -1 || this.dot_list.indexOf(vo.date.replace(/-(\d)(?!\d)/g, '-0$1')) > -1) {
            vo.dot = true;
          } else {
            vo.dot = false;
          }
          let date = new Date();
          let toDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
          vo.quantity = 0;
          if (`${vo.date}` == `${this.date}`) {
            vo.disabled = false;
          } else if (Date.parse(`${vo.date}`.replace(/-/g, '/')) < Date.parse(`${toDay}`.replace(/-/g, '/'))) {
            vo.disabled = true;
          } else {
            vo.disabled = false;
          }
          return { ...vo };
        }));
        return item;
      });
      this[week_list] = list;
    },
    set_to_day(type) {
      let list = [];

      this[type].map((item, index) => {
        list.push(item.map((vo, key) => {
          if (vo.date == `${this.date}`) {
            vo.today = true;
          } else {
            vo.today = false;
          }
          return { ...vo };
        }));
        return item;
      });
      this[type] = list;
    },
    item_click(item, item_index = -1) {
      if ((!this.retract && item.type !== 'month') || item.disabled) {
        return false;
      }
      this.date = item.date;
      if (item.type == 'month') {
        this.nowDay = item.day;
        if (item_index >= 0) this.to_week_index = item_index;
      } else if (this.retract) {
        this.nowDay = item.day;
      }

      [this.nowYear, this.nowMonth, this.nowDay] = item.date.split('-');
      //   this.nowYear = now_arr[0];
      //   this.nowMonth = now_arr[1];
      //   this.nowDay = now_arr[2];

      this.set_to_day_all(item_index);

      this.nowTime = this.date_parse(`${item.date}`);

      this.change();
      this.set_doc_lists_update();
    },
    set_to_day_all(item_index) {
      this.set_to_day('week_list');
      this.set_to_day('week_list_prev');
      this.set_to_day('week_list_next');
      this.set_to_day('week_list_prev_week');
      this.set_to_day('week_list_next_week');
    },
    get_month(type) {
      let { nowMonth } = this;
      let { nowYear } = this;
      let { nowDay } = this;

      if (type == 'prev') {
        if (nowMonth == 1) {
          nowMonth = 12;
          nowYear -= 1;
        } else {
          nowMonth--;
        }
      } else if (type == 'next') {
        if (nowMonth == 12) {
          nowMonth = 1;
          nowYear += 1;
        } else {
          nowMonth++;
        }
      }

      let days = this.get_month_days(nowMonth, nowYear);
      if (nowDay > days) {
        nowDay = days;
      }

      return this.date_parse(`${nowYear}-${nowMonth}-${nowDay}`);
    },

    date_parse(str) {
      return Date.parse(str.replace(/-(\d)(?!\d)/g, '-0$1'));
    },
    switch_month_week(type = 'next', update_week = false) {
      if (this.retract) {
        if (type == 'prev') {
          this.get_date(this.nowTime - 86400 * 7 * 1000);
        } else if (type == 'next') {
          this.get_date(this.nowTime + 86401 * 7 * 1000);
        }
        if (update_week) {
          this.update_swiper_item('week');
          this.set_doc_lists_update();
        }
      } else {
        this.get_date(this.get_month(type));
        this.update_swiper_item('month');
      }
      this.set_doc_lists_update();

      this.set_to_day_all();

      if (update_week) {
        // this.change();
      }
    },
    update_swiper_item(type = 'month') {
      if (type == 'month') {
        if (this.current == 0) {
          this.change_date_month('next');
        } else if (this.current == 2) {
          this.change_date_month('prev');
        }
      } else if (type == 'week') {
        if (this.current == 0) {
          this.change_date_week('next');
        } else if (this.current == 2) {
          this.change_date_week('prev');
        }
      }
    },
    next() {
      this.get_date(this.next_date);
    },
    get_date(value = '', type = 'same') {
      let date = new Date();
      if (value) {
        date = new Date(value);
      }
      let nowMonth = date.getMonth() + 1;
      let nowYear = date.getFullYear();
      let nowDay = date.getDate();
      let nowTime = date.getTime();
      let nowWeek = date.getDay();

      let days = this.get_month_days(nowMonth, nowYear);
      let start_date = new Date(nowYear, nowMonth - 1, 1);
      let end_date = new Date(nowYear, nowMonth - 1, days);
      let prev_date = new Date(start_date.getTime() - 1);
      let prev_date_days = prev_date.getDate();
      let next_date = new Date(end_date.getTime() + 86401 * 1000);
      let next_date_days = next_date.getDate();
      let start_week = start_date.getDay();
      let date_arrs = [];

      let week_list = [];
      let count_days = 35;

      for (let i = prev_date_days - start_week + 1; i <= prev_date_days; i++) {
        date_arrs.push({
          day: i,
          type: 'prev',
          date: `${prev_date.getFullYear()}-${prev_date.getMonth() + 1}-${i}`,
        });
      }

      for (let i = 1; i <= days; i++) {
        date_arrs.push({
          day: i,
          type: 'month',
          today: i == nowDay,
          date: `${nowYear}-${nowMonth}-${i}`,
        });

        if (i == nowDay && type == 'same') {
          this.date = `${nowYear}-${nowMonth}-${i}`;
        }
      }
      //   if (this.debug) console.log(value, date, this.date, `${next_date.getFullYear()}-${next_date.getMonth() + 1}-${next_date.getDate()}`);
      let date_arrs_length = date_arrs.length;

      // if(date_arrs_length > 35){
      count_days = 42;
      // }
      for (let i = 1; i <= count_days - date_arrs_length; i++) {
        date_arrs.push({
          day: i,
          type: 'next',
          date: `${next_date.getFullYear()}-${next_date.getMonth() + 1}-${i}`,
        });
      }

      for (let i = 0; i < date_arrs.length / 7; i++) {
        let arr = [];
        for (let j = 0; j < 7; j++) {
          if (date_arrs[i * 7 + j].today) {
            if (type == 'same') {
              this.to_week_index = i;
            }
          }
          arr.push(date_arrs[i * 7 + j]);
        }
        week_list.push(arr);
      }

      if (type == 'same') {
        this.week_list = week_list;
        this.nowYear = nowYear;
        this.nowMonth = nowMonth;
        this.nowDay = nowDay;
        this.nowTime = nowTime;
        this.start_date = start_date;
        this.end_date = end_date;
        this.prev_date = prev_date;
        this.next_date = next_date;
      } else if (type == 'prev') {
        this.week_list_prev = week_list;
      } else if (type == 'next') {
        this.week_list_next = week_list;
      }
    },
    get_month_days(nowMonth, nowYear) {
      let month_arr = [1, 3, 5, 7, 8, 10, 12];
      let days = 0;
      if (nowMonth == 2) {
        if (nowYear % 4 == 0) {
          days = 29;
        } else {
          days = 28;
        }
      } else if (month_arr.indexOf(nowMonth) >= 0) {
        days = 31;
      } else {
        days = 30;
      }
      return days;
    },
  },
};
</script>

<style scoped lang="scss">
  @media (max-width: 767.98px) {
    .calendar-model{
      width: 100%;
      .calendar{
        display: block;
        width: 100%;
      }
      .select-box{
        width: 100%;
      }
    }
  }
.model-bg{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .btn-close{
        z-index: 9;
    }
    .btn-close:focus{
        box-shadow: none;
    }
}
.calendar-model{
    max-height: 100%;
    overflow-y: auto;
    z-index: 9;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: #ffffff;
}
.select-box{
  display: inline-block;
  background-color: #ffffff;
  width: 340px;
  height: 100%;
  vertical-align: top;
  padding: 20px;
}
.calendar {
    $color: #627056;
    $color_2: #77845c;
    $color_disabled:#f1f1f1;
    $color_standard:#333;
    $color_border:#f5f5f5;
    $color_border_2: #dddddd;
    background-color: #ffffff;
    vertical-align: top;
    display: inline-block;
    width: 340px;
ß.date{
    width: 100%;
    }
.head{
    display: flex; align-items: center; height: 46px; justify-content: center;
    border-bottom: 1px solid $color_border; color:$color_standard;
    position: relative;
    .switch-left {
      margin-right: 10px;
    }
    .switch-right {
      margin-left: 10px;
    }
    .title{
      font-size: 18px; text-align: center;
    }
    .icon{
        display: block;
        cursor: pointer;
    .next{
        transform: rotate(180deg); display: block;
    }
    }
}
.retract{
    display: flex; justify-content: center; align-items: center; height: 80rpx;;
    .iconfont{
    transform: rotate(270deg);
    &.retract_icon{
        transform: rotate(90deg);
    }
    }
}
.date_dl{
    display: flex; width: 100%;
    .dd{
    flex:1; text-align: center;
    height: 40px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .num{
        width: 30px; height: 30px; border-radius: 50%; line-height: 30px;
        position: relative;
        font-size: 1rem;
        cursor: pointer;
        color: #f1f1f1;
        &-subscript{
            position: absolute;
            z-index: 9999999;
            border-radius: 50%;
            border: 1px solid #627056;
            bottom: -4px;
            right: -8px;
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            font-size: 0.6rem;
        }
        &.disabled{
        color: $color_disabled;
        }
        &.month{
        color: $color_standard;
        }
        &.dot{
        background: #efefef;
        .num-subscript{
            background-color: #627056;
            color: #ffffff;
        }
        }
        &.today{
        background: $color; color:#fff;
        .num-subscript{
            background-color: #ffffff;
            color: #627056;
        }
        }
    }
    }
}
}
</style>
