<template>
  <div
    v-show="visible"
    class="loading toast-container position-fixed top-50 end-50 p-3 rounded-3"
  >
    <div
      class="spinner-border"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: "loading",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.loading{
  z-index: 999999999999999;
  transform: translate(50%, -50%);
  // box-shadow: 0px 0px 8px 2px #00000036;
}
</style>
