<template>
  <div
    ref="content-page"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('reserve.cart.title') }}
          </div>
        </div>
        <hr>
        <div class="content">
            <div class="row ">
              <div class="col-md-3"></div>
              <div
                v-if="product_list && product_list.length"
                class="col-md-9 text-start images"
              >
                <div class="medicine-list" v-for="(item ,index) in product_list" :key="index">
                  <input class="form-check-input check-input" type="checkbox" v-model="item.selected" :value="true" @change="onCheckChange"/>
                  <div class="medicine-image image">
                    <img :src="item.thumbnail_urls" alt="">
                  </div>
                  <div>
                    <div class="medicine-title">{{i18n(item, 'title')}}</div>
                    <div class="medicine-spec">{{$t('reserve.detail.quantity')}}：
                      <div class="input-minus" @click="onClickMinus(index)">-</div>
                      <div class="input-quantity">{{item.quantity}}</div>
                      <div class="input-plus" @click="onClickPlus(index)">+</div>
                    </div>
                    <div class="medicine-price text-success">${{item.discount_price}}</div>
                  </div>
                </div>

              </div>
            </div>
            <hr>
            <div class="row ">
              <div class="col-md-3"></div>
                <div class="col-md-9 text-start ps-4"
                >
                {{$t('reserve.cart.total')}}：
                <span class="text-success">${{total}}</span>
              </div>
            </div>

            <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm me-2  px-5"
                  @click="cancelBuy"
                >
                  {{ $t('booking.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-sm  px-5"
                  @click="confirmBuy"
                >
                  {{ $t('reserve.cart.buynow') }}
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: 'member-reserve-cart',
  components: {
  },
  data() {
    return {
      booking_record_id: this.$route.query.booking_record_id,
      consult_record_id: this.$route.query.consult_record_id,
      product_list: [],
      detail: {},
      total: 0,
    };
  },
  computed: {

  },
  mounted() {
    // this.getDetail();
    this.$router.push(`/member/reserve/confirm?booking_record_id=${this.booking_record_id}&consult_record_id=${this.consult_record_id}`);
  },
  activated() {
    // this.getDetail();
  },
  methods: {
    cancelBuy() {
      this.$router.push(`/member/reserve/detail?booking_record_id=${this.booking_record_id}`);
    },
    confirmBuy() {
      let list = this.product_list.filter((res) => res.selected == true);
      this.$store.state.product_list = list;
      this.$router.push(`/member/reserve/confirm?booking_record_id=${this.booking_record_id}&consult_record_id=${this.consult_record_id}`);
    },
    getDetail() {
      this.$api.booking.getBookingDetail({ id: this.booking_record_id }).then((res) => {
        if (res.data.code == 200) {
          this.detail = res.data.data;
          let product_list = [];
          if (typeof res.data.data.medicine_product_list == 'string') {
            product_list = JSON.parse(this.detail.medicine_product_list || []);
          } else {
            product_list = this.detail.medicine_product_list || [];
          }
          product_list.forEach((element) => {
            element.selected = true;
            element.quantity -= 0;
            element.stock -= 0;
          });
          this.product_list = product_list;
          this.countTotal();
        }
      });
    },
    countTotal() {
      let total = 0;
      this.product_list.forEach((item) => {
        if (item.selected && item.quantity > 0) {
          total += (item.discount_price - 0) * item.quantity;
        }
      });
      this.total = total;
    },
    onCheckChange() {
      this.countTotal();
    },
    onClickMinus(index) {
      if (this.product_list[index].quantity > 0) {
        this.product_list[index].quantity -= 1;
      }
      if (this.product_list[index].quantity == 0) {
        this.product_list[index].selected = false;
      }
      this.countTotal();
    },
    onClickPlus(index) {
      if (this.product_list[index].quantity < this.product_list[index].stock) {
        this.product_list[index].quantity += 1;
      }
      if (this.product_list[index].quantity > 0) {
        this.product_list[index].selected = true;
      }
      this.countTotal();
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  color: var( --bs-gray-600 );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  @media (max-width: 767.98px) {

    .row-header{
      text-align: left !important;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
      .content{
        padding: 20px 10px;
        .images{
          img{
            width: 100%;
          }

        }
        .medicine-list{
          position: relative;
          padding-left: 100px;
          margin-bottom: 40px;
          .check-input{
            position: absolute;
            left: 0px;
            top: 40%;
          }
          .medicine-image{
            background-color: #efefef;
            width: 70px;
            height: 70px;
            position: absolute;
            left: 20px;
          }
          .medicine-title{
            font-size: 18px;
          }
          .input-quantity{
            width:50px;
            height: 26px;
            // border: 1px solid #999;
            font-size: 18px;
            line-height: 1.2;
            display:inline-block;
            text-align: center;
            user-select: none;
          }
          .input-minus, .input-plus{
            display:inline-block;
            width: 30px;
            height: 26px;
            text-align: center;
            border: 1px solid #999;
            vertical-align: top;
            border-radius: 4px;
            font-size: 18px;
            line-height: 1.2;
            cursor: pointer;
            user-select: none;
          }
        }

      }
    }
  }
}
</style>
