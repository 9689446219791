<template>
  <div
    id="reserve"
    class="bg-light"
    v-if="!1"
  >
    <div class="container-md p-md-5 reserve text-center">
      <h2 class="py-5" style="text-align: left;">
        {{ $t('home.reserve.title') }}
      </h2>
      <div class="reserve-cart">
        <div
          class="reserve-cart-item d-inline-block g"
          @click="onClickBooking('/easy/booking')"
        >

          <div class="text-start reserve-cart-icon">
            <img
              src="@/assets/pages/video.png"
              class="d-block align-text-top img-fluid width-100"
            >
          </div>
          <div class="reserve-cart-content">
            <div class="text-start">
              <h6>{{ $t('home.reserve.cart.0.content') }}</h6>
            </div>
            <div class="text-start fs-7">
              {{ $t('home.reserve.cart.0.footer') }}
            </div>
            <div class="reserve-cart-title">
              {{ $t('home.reserve.cart.0.title') }}|{{ $t('home.reserve.cart.0.time') }}
            </div>
          </div>
        </div>
        <div
          class="reserve-cart-item d-inline-block b"
          @click="onClickBooking('/booking')"
        >
          <div class="text-start reserve-cart-icon">
            <img
              src="@/assets/pages/reserve.png"
              class="d-block align-text-top img-fluid width-70"
            >
          </div>
          <div class="reserve-cart-content">
            <div class="text-start">
              <h6>{{ $t('home.reserve.cart.1.content') }}</h6>
            </div>
            <div class="text-start fs-7">
              {{ $t('home.reserve.cart.1.footer') }}
            </div>
            <div class="reserve-cart-title">
              {{ $t('home.reserve.cart.1.title') }}|{{ $t('home.reserve.cart.1.time') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 预约
export default {
  name: 'ReserveTpl',
  methods: {
    onClickBooking(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
  @media (max-width: 767.98px) {
    .reserve{
      padding-top: 70px;
      .reserve-cart-item{
        margin-right: 10px !important;
      }
    }
  }
.reserve{
  padding-bottom: 70px;
  background-color: #F7F7F9;
  &-cart-item{
    width: 260px;
    height: 242px;
    opacity: 1;
    border-radius: 12px;
    margin-right: 30px;
    padding: 20px;
    color: #ffffff;
  }
  .reserve-cart-item{
    position: relative;
    .reserve-cart-title{
      border-radius: 6px;
      padding:2px 8px;
      color: #ffffff;
      border:1px solid  rgba(255, 255, 255, 0.18);
      margin-top: 10px;
      font-size: 0.6rem;
    }
    .reserve-cart-icon{
      margin-top: 10px;
      display: block;
      position: absolute;
      img{
        width: 70%;
      }
    }
    .reserve-cart-content{
      position: absolute;
      bottom: 20px;
      .fs-7{
        font-size: 0.6rem;
      }
    }
  }
  .g{
    background: #399683;
  }
  .b{
    background: #235063;
  }
}
</style>
