<template>
  <div
    ref="reserveList"
    class="container-content bg-light"
  >
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('reserve.title') }}
          </div>
          <div class="btns">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              @click="onClickReserve"
            >
              {{ $t('reserve.online') }}
            </button>
          </div>
        </div>
        <hr>
        <div class="content">
          <div
            v-for="(item,index) in currentList"
            :key="index"
            class="list"
          >
            <div class="time">
              {{ $t('reserve.created_time') }}：{{ item.created_at }}
            </div>
            <div class="time" v-if="item.instant_connect == 0">
              {{ $t('reserve.time') }}：{{ item.book_date ? (item.book_date + ' ' + item.book_start_time) : $t('reserve.no_time')}}
            </div>
            <div class="time">
              {{ $t('reserve.code') }}：{{ item.booking_record_code }}
            </div>
            <!-- <div class="name">
              {{ $t('reserve.name') }}：{{ item.time }}
            </div> -->
            <div class="doctor">
              {{ $t('reserve.doctor') }}：{{ item.doctor_name }}
            </div>
            <!-- <div class="type">
              {{ $t('reserve.type') }}：website
            </div>
            <div class="price">
              {{ $t('reserve.price') }}：{{ item.total_price }}
            </div> -->
            <div class="status">
              {{ $t('reserve.status') }}：
              <span style="color:red" v-if="item.status == 1 &&item.payStatus">{{ $t('payment.107')}}</span>
              <span v-else>{{ $t('reserve.'+item.status) }} </span>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-sm btn-outline-dark"
                @click="onClickDetail(item.id)"
              >
                {{ $t('reserve.detail.title') }}
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-dark ms-1"
                v-if="item.status == 1"
                @click="onClickPay(item.id)"
              >
                {{ $t('booking.paynow') }}
              </button>
            </div>
          </div>

          <pages
            :total="total"
            :pagesize="pageSize"
            :page="currentPage"
            @changePage="changePage"
          />
        </div>
      </div>
    </div>

    <model ref="payModel">
      <div class="py-5 p-md-5">
        <pay-btns @selected="paySelect" />
      </div>
      <div class="row g-2 mb-1 text-center justify-content-md-center mt-3 mb-5">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-secondary btn-sm me-2 px-5"
            @click="cancelPay"
          >
            {{ $t('booking.cancel') }}
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm px-5"
            @click="confirmPay"
          >
            {{ $t('booking.paynow') }}
          </button>
        </div>
      </div>
    </model>

  </div>
</template>
<script>
import pages from '@/components/page';
import model from '@/components/model';
import payBtns from '@/components/pay-btns';
import loading from '@/components/loading';

function loadEftScript() {
  const src = 'https://library-checkout.spiralplatform.com/js/v2/spiralpg.min.js';
  const id = 'eft-sdk';
  const exist = document.getElementById(id);
  if (exist) {
    return Promise.resolve(true);
  }
  const { head } = document;
  const script = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  head.appendChild(script);
  return new Promise((resolve) => {
    script.onload = function () {
      resolve(true);
    };
  });
}

export default {
  // eslint-disable-next-line
  name: 'member-reserve',
  components: {
    pages,
    model,
    payBtns,
  },
  data() {
    return {
      list: [],
      pageSize: 20,
      currentPage: 1,
      total: 0,
      interval: '',
      booking_record_id: '',
      payType: 'paypal',
    };
  },
  async created() {
    await loadEftScript();
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 768 ? 1 : 0;
    },
    currentList() {
      return this.list;
      // return this.list.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    },
  },
  mounted() {
    this.getList();
    if (this.$refs.reserveList) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (this.currentPage == 1 && this.$refs.reserveList.scrollTop < 300) {
          this.getList();
        }
      }, 5000);
    }
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    getList() {
      this.$api.booking.getBookingList({ page_size: this.pageSize, current_page: this.currentPage }, { hide_loading: true }).then((res) => {
        if (res.data.code == 200) {
          res.data.data.list.forEach((element, index) => {
            if (element.status != 1) {
              res.data.data.list[index].payStatus = 0;
              localStorage.setItem(`payStatus-${this.booking_record_id}`, 0);
            } else if (element.status == 1) {
              res.data.data.list[index].payStatus = localStorage.getItem(`payStatus-${element.booking_record_id}`) || 0;
            }
          });
          this.list = res.data.data.list; // .reverse();
          this.pageSize = res.data.data.pages.page_size;
          this.currentPage = res.data.data.pages.current_page;
          this.total = res.data.data.pages.total;
          this.$refs.reserveList.scrollTop = 0;
        }
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    onClickReserve() {
      this.$router.push('/reserve');
    },
    onClickDetail(booking_record_id) {
      this.$router.push(`/member/reserve/detail?booking_record_id=${booking_record_id}`);
    },

    onClickPay(id) {
      this.booking_record_id = id;
      this.$refs.payModel.show();
    },
    paySelect(type) {
      this.payType = type;
    },
    confirmPay() {
      localStorage.setItem(`payStatus-${this.booking_record_id}`, '1');
      if (this.payType == 'paypal') {
        this.onClickPaypal();
      } else if (
        this.payType == 'alipay_hk'
       || this.payType == 'alipay_cn'
       || this.payType == 'vm'
       || this.payType == 'unionpay'
       || this.payType == 'mppay'
      ) {
        this.onEftClick(this.payType);
      }
    },
    cancelPay() {
      this.$refs.payModel.hide();
    },

    async onClickPaypal() {
      if (this.booking_record_id) {
        localStorage.setItem(`payStatus-${this.booking_record_id}`, '1');
        let res = await this.$api.payment.createBookingPaypal({ booking_record_id: this.booking_record_id }).catch(() => null);
        if (res && res.data && res.data.data) {
          if (typeof plus == 'object') {
            // eslint-disable-next-line
            uni.webView.navigateTo({ url: `/pages/webview/index?url=${res.data.data.url}` });
          } else {
            window.location.href = res.data.data.url;
          }
        }
      }
    },
    async onEftClick(payType) {
      if (this.booking_record_id) {
        localStorage.setItem(`payStatus-${this.booking_record_id}`, '1');
        let type = 3;
        if (payType == 'alipay_cn') {
          type = 3;
        } else if (payType == 'alipay_hk') {
          type = 4;
        } else if (payType == 'mppay') {
          type = 5;
        } else if (payType == 'vm') {
          type = 6;
        } else if (payType == 'unionpay') {
          type = 7;
        }
        let res = await this.$api.payment.createBookingEft({
          id: this.booking_record_id, in_app: this.isMobile, order_payment: 1, pay_type: type,
        });
        // console.log(res);
        if (res && res.data && res.data.code == 200) {
          try {
            loading.loading.show();
            // eslint-disable-next-line
              SpiralPG.init(res.data.data);
          } catch (err) {
            null;
          }
          this.eftPay(1);
        } else {
          loading.loading.hide();
          this.$toast.show(this.$t('payment.105'));
        }
      }
    },
    eftPay(times) {
      if (times > 15) {
        loading.loading.hide();
        return this.$toast.show(this.$t('payment.105'));
      }
      let globalThis = this;
      try {
        loading.loading.show();
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
        }, 1500);
        setTimeout(() => {
          // eslint-disable-next-line
          SpiralPG.pay();
          // globalThis.$toast.show(globalThis.$t('payment.104'));
        }, 3000);
        setTimeout(() => {
          // eft有时会很久才跳，其实已经成功了的
          loading.loading.hide();
        }, 5000);
      } catch (err) {
        times++;
        globalThis.eftPay(times);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  overflow-y: auto;
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
      .list{
        border-bottom: 1px solid #efefef;
        padding: 20px 0px 20px 100px;
        color: var( --bs-gray-600 );
      }
    }
  }
  @media (max-width: 767.98px) {
    .content-padding-left{
      .list{
        border-bottom: 1px solid #efefef;
        padding: 20px 0px 20px 20px;
        color: var( --bs-gray-600 );
      }
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      min-height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
    }
  }
}
</style>
