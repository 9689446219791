<template>
  <div
    ref="liveToast"
    id="liveToast"
    :class="['toast', 'position-fixed', 'mt-5', !title && 'dark']"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    :style="style"
  >
    <div
      v-if="title"
      class="toast-header bg-light"
    >
      <!-- <img src="..." class="rounded me-2" alt="..."> -->
      <strong class="me-auto">{{ title }}</strong>
      <small>{{ header }}</small>
      <button
        type="button"
        class="btn-close"
        @click="hide"
      />
    </div>
    <div class="toast-body">
      <div>
        {{ content }}
      </div>
      <div
        v-show="confirm || close"
        class="mt-2 pt-2 border-top text-end"
      >
        <button
          v-show="close"
          type="button"
          class="btn btn-secondary btn-sm me-2"
          @click="hide"
        >
          {{ close }}
        </button>
        <button
          v-show="confirm"
          type="button"
          class="btn btn-success btn-sm"
          @click="success"
        >
          {{ confirm }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import bootstrap from 'bootstrap/dist/js/bootstrap';

export default {
  // eslint-disable-next-line
  name: "toast",
  data() {
    return {
      title: '',
      header: '',
      content: '',
      close: '',
      confirm: '',
      delay: 1000,
      autohide: true,
      resolve: null,
      reject: null,
      toast: null,
      style: '',
    };
  },
  methods: {
    show(data = {}) {
      this.autohide = true;
      this.resolve = null;
      data.title != undefined ? (this.title = data.title) : (this.title = '');
      data.header != undefined ? (this.header = data.header) : (this.header = '');
      data.content != undefined ? (this.content = data.content) : (this.content = '');
      data.close != undefined ? (this.close = data.close) : (this.close = '');
      data.confirm != undefined ? (this.confirm = data.confirm) : (this.confirm = '');
      data.autohide != undefined ? (this.autohide = data.autohide) : (this.autohide = true);
      data.delay != undefined ? (this.delay = data.delay) : (this.delay = 1000);
      data.style != undefined ? (this.style = data.style) : (this.style = '');
      if (typeof data == 'string') {
        this.content = data;
      }
      setTimeout(() => {
        this.toast = new bootstrap.Toast(this.$refs ? this.$refs.liveToast : document.body.querySelector('#liveToast'), { autohide: this.autohide, delay: this.delay });
        this.toast.show();
      }, 200);
      return new Promise((resolve, reject) => { this.resolve = resolve; this.reject = reject; });
    },
    hide() {
      this.toast.hide();
      this.toast = null;
      typeof this.reject == 'function' && this.reject();
    },
    success() {
      this.toast.hide();
      this.toast = null;
      typeof this.resolve == 'function' && this.resolve();
    },
  },
};
</script>
<style lang="scss" scoped>
  .toast{
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
  }
  .dark{
    background-color: rgba(0,0,0,0.7);
    color: #ffffff;
    width: auto !important;
  }
</style>
