import { createI18n } from 'vue-i18n/index.mjs'; // 引入vue-i18n组件

// 注册i8n实例并引入语言文件
// eslint-disable-next-line
const i18n = new createI18n({
  locale: localStorage.getItem('client-language') || 'en',
  messages: {
    zh_TW: require('./zh_TW'),
    // zh_CN: require('./zh_CN'),
    en: require('./en'),
  },
});

export default i18n; // 将i18n暴露出去，在main.js中引入挂载
