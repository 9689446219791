<template>
  <div class="container-content bg-light">
    <div class="container-md content-padding-left">
      <div class="container">
        <div class="d-flex">
          <div class="title flex-grow-1 fs-5">
            {{ $t('member.title') }}
          </div>
          <div class="btns">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              @click="onClickEdit"
            >
              <!-- <img
                src="@/assets/form/edit.png"
                width="14"
              > -->
              {{ $t('member.edit') }}
            </button>
          </div>
        </div>
        <hr>
        <div class="content text-center">
          <div class="header">
            <div
              class="rounded-circle bg-light d-inline-block overflow-hidden"
              style="width: 120px;height: 120px"
            >
              <img
                :src="avatar"
                style="width: 120px;height: 120px;object-fit: cover;"
              >
            </div>
          </div>
          <div class="row my-3">
            <div class="col text-end">
              {{ $t('login.username') }}
            </div>
            <div class="col text-start">
              {{ $store.state.user.username }}
            </div>
          </div>
          <div class="row my-3">
            <div class="col text-end">
              {{ $t('login.email') }}
            </div>
            <div class="col text-start">
              {{ $store.state.user.email }}
            </div>
          </div>
          <div class="row my-3">
            <div class="col text-end">
              {{ $t('login.phone') }}
            </div>
            <div class="col text-start">
              {{ $store.state.user.mobile }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';

export default {
  // eslint-disable-next-line
  name: 'member-info',
  components: {

  },
  data() {
    return {
      avatar: this.$store.state.user.avatar || require('@/assets/navbar/user-fill.png'),
    };
  },
  methods: {
    onClickEdit() {
      this.$router.push('/member/info/edit');
    },
  },
};
</script>
<style lang="scss" scoped>
.container-content{
  height: calc( 100vh - 5rem );
  padding-bottom: 100px;
  @media (min-width: 767.98px) {
    .content-padding-left{
      padding-left: 180px;
    }
  }
  .content-padding-left{
    height: 100%;
    padding-top: 18px;
    .container{
      background-color: #ffffff;
      height: calc( 100% - 2px);
      border-radius: 4px;
      padding: 30px;
      hr{
        background-color: var( --bs-gray-600 ) ;
      }
      .btns{
        .btn-outline-dark:hover {
          color: #212529;
          background-color: var( --bs-gray-200 );
          // border-color: var( --bs-gray-200 );
        }
      }
    }
  }
}
</style>
