import { createApp } from 'vue';
import Toast from './toast.vue';

export default {
  toast: null,
  install(app) {
    if (this.toast) {
      // 防止多次载入
      app.config.globalProperties.$toast = this.toast;
      return;
    }
    let instance = createApp(Toast);
    let parent = document.createElement('div');
    let bodyDom = document.body;
    // 这里需要注意，大概率app还没有mount，导致获取不到app节点，所以想挂载到app上，需要保证app已经创建。
    bodyDom.appendChild(parent);
    this.toast = instance.mount(parent);
    app.config.globalProperties.$toast = this.toast;
  },
  show: Toast.methods.show,
  hide: Toast.methods.hide,
};
