<template>
  <div :class="['lang', lang]"  id="app">
    <nav-bar />
    <router-view v-slot="{ Component }">
      <transition name="slide"  mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import navBar from './components/navBar';

export default {
  name: 'App',
  components: {
    navBar,
  },
  computed: {
    lang() { return this.$i18n.locale; },
  },
  mounted() {
    // this.$router.push('/');
    // console.log('go to /');
  },
};
</script>

<style>
@import './assets/iconfont.css';

*{
  box-sizing: border-box;
}
#app {
  color: #2c3e50;
}
input, textarea {
  font-size: 16px !important;
}
body {
  font: 14px PingFang SC;
  color: #514D48;
}
.bg-light{
  background-color: #F7F7F9 !important;
}
.bg-info{
  background-color: #5FA99A !important;
}
.btn-success{
  background-color: #225164;
  border-color: #5FA99A;
    /* border: 0; */
}
.btn-outline-secondary{
  border-color:  #e1e1df;
}
.btn-check:checked+.btn-outline-secondary{
    background-color:  #E9EFE3;
    border-color:  #798D62;
    color: #798D62;
}
.btn-primary{
    background-color: #4D5679;
    border-color: #4D5679;
}
.form-link{
  color: #5FA99A;
}
.form-check-input:checked {
    background-color: #5FA99A;
    border-color: #5FA99A;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5FA99A;
    border-color: #5FA99A;
}
img{
    max-width: 100%;
}
.lang-btn{
  background-color: green;
  padding: 0 4px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.col-form-label{
  padding-top: 0px;
}
@media (max-width: 767.98px) {
  body{
    padding-bottom: 70px;
  }
  .lang-btn{
      position: relative;
      top: -2px;
  }
}
/*
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease;
  z-index: -1;
} */

/* .slide-enter-to {
  transform: translate(-100%, 0);
  transition-timing-function: ease-in;
} */

/* .slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: scale(0.9);
} */

/* .slide-leave-to {
  transform: translate(100%, 0);
  transition-timing-function: ease-in;
}

.slide-leave-from {
  transform: translate(-100%, 0);
  transition-timing-function: ease-in;
} */

</style>
