<template>
    <div class="industry-data">
      <div class="container-md container-data">
        <div class="row box">
          <div class="col">
            <div class="number">
              2800+
            </div>
            <div class="text">{{ $t('home.industry.service') }}</div>
          </div>
          <div class="col">
            <div class="number">
              30+
            </div>
            <div class="text">{{ $t('home.industry.veterinarian') }}</div>
          </div>
          <div class="col">
            <div class="number">
              100%
            </div>
            <div class="text">{{ $t('home.industry.education') }}</div>
          </div>
          <div class="col">
            <div class="number">
              98%
            </div>
            <div class="text">{{ $t('home.industry.satisfaction') }}</div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
// 行业数据
export default {
  name: 'industry-data-tpl',
};
</script>
<style lang="scss" scoped>
.industry-data{
  width: 100%;
  // background: #6A7162;
  text-align: center;
  // color: #ffffff;
  .container-data{
    // height: 50px;
    padding: 0px 11px;
  }
  .box{
    border: 1px solid #EFEFEF;
    border-radius: 0.5rem;
    position: relative;
    // top: -5.8rem;
    background: #fff;
    box-shadow: 0px 2px 8px 0px #ddd;
    .col{
      text-align: center;
      padding: 30px 20px;
      .number{
        font-size: 38px;
        line-height: 1.2;
        color: #000;
        font-family: Bangla MN;
      }
      .text{
        font-family: PingFang SC;
        color: #999
      }
    }
    .col:not(:first-child){
      border-left: 1px solid #EFEFEF;
    }
  }
  @media (max-width: 767.98px) {
    .container-data{
        // height: 130px;
        padding: 0px 20px;
    }
    .box{
      display: block !important;
      .col{
        width: 50% !important;
        display: inline-block;
        vertical-align: top;
        padding: 20px 10px;
        .number{
          font-size: 26px;
        }
        .text{
          font-size: 0.62rem;
        }
      }
      .col:not(:first-child){
        border-left: 0px solid #EFEFEF;
      }
    }
  }
}
</style>
