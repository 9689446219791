<template>
  <div class="c-upload-file-2">
    <input
      id="file"
      type="file"
      @change="onChange"
    >
  </div>
</template>
<script>
import COS from 'cos-js-sdk-v5';

const max_size = 1024 * 5; // 5m

export default {
  // eslint-disable-next-line
  name: "upload",
  props: {
    file: Object,
    buss: {
      type: String,
      default: 'pet', // 只支持 pet，custom
    },
  },
  data() {
    return {
      status: {
        loaded: false,
      },
      oss_client: null,
      oss_token: {
        expiration: '',
        access_key_id: '',
        access_key_secret: '',
        security_token: '',
      },
    };
  },
  mounted() {
    let that = this;
    this.cos = new COS({
      // getAuthorization 必选参数
      getAuthorization: (options, callback) => {
        that.$api.oss.getCosToken().then((res) => {
          if (res.data.code == 200) {
            callback({
              TmpSecretId: res.data.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.data.credentials.tmpSecretKey,
              SecurityToken: res.data.data.credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: res.data.data.startTime,
              ExpiredTime: res.data.data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          }
        });
      },
    });
  },
  methods: {
    onChange(e) {
      let file = e.target.files[0];
      if (file) {
        let size = file.size / 1024; // b => kb
        if (size > max_size) {
          return;
        }
        this.upload(file);
      }
    },
    upload(file) {
      const storeAs = `d86e/upload/avatar/img/${+new Date()}.${file.name}`;
      this.cos.putObject(
        {
          Bucket: 'd86e-1300007815',
          Region: 'ap-hongkong',
          Key: storeAs,
          StorageClass: 'STANDARD',
          Body: file,
          onProgress: () => {},
        },
        (err, data) => {
          if (data) {
            let json = {
              name: file.name,
              size: file.size,
              type: file.type,
              url: `https://${data.Location}`,
              url_tiny: `https://${data.Location}`,
            };
            this.$emit('success', json);
          }
        },
      );
    },
  },
};
</script>
<style lang="scss">
.c-upload-file-2 {
  display: inline-block;
  height: 110px;
  width: 110px;
  position: relative;
  z-index: 5;
  background-image: url(../assets/form/upload.png);
  background-size: 100% 100%;
  input {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: transparent;
  }
}
</style>
