<template>
    <div class="container-md py-md-3 process">
        <h1 class="text-left py-md-3">
          {{ $t('home.process.title') }}
        </h1>
        <div class="row px-md-5">
          <div class="col-md p-2 text-left col-item" v-for="(item,key) in process" v-bind:key="key">
            <div class="process-title">{{ $t('home.process.list.'+key+'.title') }}</div>
            <div>
              <div class="process-head">{{ $t('home.process.list.'+key+'.head') }}</div>
              <div class="process-body">{{ $t('home.process.list.'+key+'.body') }}</div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
// 流程
export default {
  name: 'process-tpl',
  computed: {
    process() {
      let list = [];
      for (let index = 0; index < 3; index++) {
        list.push({});
      }
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
.process{
  .process-title{
    font-size: 72px;
    color: #5FA99A;
    font-family: Bangla MN;
    line-height: 1.2;
  }
  .process-head{
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .process{
    padding-top: 30px;
      .col-item{
      display: flex;
      .process-title{
            font-size: 1.7rem;
            margin-right: 10px;
            padding-left: 20px;
      }
    }
  }

}
</style>
