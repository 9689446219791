import axios from 'axios';
import store from '../store';
import loading from '../components/loading';
import Router from '@/router';
import VueI18n from '../language';
import Toast from '../components/toast';

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
  changeOrigin: true,
});

request.interceptors.request.use(
  (config) => {
    // 发送请求前会做的事情
    !config.hide_loading && loading.loading.show();
    config.headers.Authorization = `Bearer ${store.state.token}`;
    config.headers['device-id'] = localStorage.getItem('client-identity') || '';
    config.headers.domain = process.env.NODE_ENV == 'production' ? process.env.VUE_APP_BASE_API.slice(8) : 'www.bnbvet.com';
    // eslint-disable-next-line
    config.headers['language-type'] = VueI18n.global.locale;
    return config;
  },
  (error) => {
    // 请求错误要做的事情
    Promise.reject(error);
  },

);

request.interceptors.response.use(
  (response) => {
    // 2xx的状态码会激活的函数
    loading.loading.hide();
    // console.log(111, Router);
    if (response.data.code === 1001 || response.data.code === 1002 || response.data.code === 1003 || response.data.code === 1004 || response.data.code === 1005 || response.data.code === 1006 || response.data.code === 2001) {
      // Toast.show({ content: response.data.msg });
      Router.push('/user/login');
    } else if (response.data.code == 3001) {
      Toast.show(response.data.msg);
    }

    return response;
  },
  (error) => {
    // 非2xx状态码会激活的函数
    loading.loading.hide();
    return Promise.reject(error);
  },
);

request.install = (app) => {
  app.config.globalProperties.$axios = request;
};

export default request;
